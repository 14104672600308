import React from "react";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Tooltip from "../../components/Tooltip/Tooltip";

export const tableConstants = (permissions) => {
  let formatedData = [
    {
      title: 'Exam Header',
      render: rowData => {
        return <span>{rowData.name.name}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>
          {rowData.term === "UT-1" ? "UT-I" : null}
          {rowData.term === "UT-2" ? "UT-II" : null}
          {rowData.term === "UT-3" ? "UT-III" : null}
          {rowData.term === "PT-1" ? "PT-I" : null}
          {rowData.term === "PT-2" ? "PT-II" : null}
          {rowData.term === "PT-3" ? "PT-III" : null}
          {rowData.term === "1" ? "Term-I" : null}
          {rowData.term === "3" ? "Common Pre-Board" : null}
          {rowData.term === "4" ? "Pre-Board" : null}
          {rowData.term === "2" ? "Term-II" : null}
        </span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.program.name}</span>;
      },
    },

    {
      title: 'Subject',
      render: rowData => {
        return <span>{rowData.subject.name}</span>;
      },
    },

    {
      title: 'Evaluation',
      render: rowData => {
        return <span>{rowData.evaluation_type.name}</span>;
      },
    },
    {
      title: 'Student Graded',
      render: rowData => {
        return <span>{rowData.total_mark_participant}/
          {rowData.total_participant}</span>;
      },
    },
    {
      title: 'Max Score',
      render: rowData => {
        return <span>{rowData.max_score}</span>;
      },
    },
    {
      title: 'Status',
      render: rowData => {
        return <React.Fragment>
          {rowData.total_mark_participant == 0 ? (
            <span style={{ color: "red" }}> Not Graded</span>
          ) : (rowData.total_mark_participant > 0 && rowData.total_mark_participant != rowData.total_participant) ? (
            <span style={{ color: 'orange' }} >In Progress</span>
          ) : (
            <span style={{ color: 'green' }}>Graded</span>
          )}
        </React.Fragment>;
      },
    },
  ];

  if (permissions.includes("marking.approval.allowed")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("marking.approval.allowed") &&
              <Link to={"/marking/approval/manage/" + rowData.id}>
                <Tooltip tooltipText={"Marking Approval"}>
                  <FontAwesomeIcon icon={faEye} color="blue" />
                </Tooltip>
              </Link>}
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
