import React, { useState, useEffect } from "react";
import { FaRegBell } from "react-icons/fa";
import { tableConstants } from "./NotificationTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import { deleteNotification, getNotifications } from "../../../services/AppManagement/NotificationService";

const NotificationList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  async function fetchNotification({ selected = 0 }) {
    try {
      setCurrentPage(selected);
      const page = selected + 1;
      setIsLoader(true);
      const response = await getNotifications(page);
      if (response.status === 200) {
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchNotification({ selected: 0 });
  }, []);

  const notificationDelete = async (id) => {
    try {
      const response = await deleteNotification(id);
      if (response.status === 200) {
        enqueueSnackbar("Notification Deleted Successfully", { variant: "success" });
        fetchNotification({ selected: 0 });
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  }

  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="Student Notification List"
          icon={<FaRegBell />}
          button={permissions.includes('notification.create') &&
            <Link to="/notification/create" className="btn btn-primary">Add Notifiaction</Link>}
        />
        <div className="card-body">
          {isLoader ? <Loader /> :
            <div className="row mt-3">
              {data && (
                <React.Fragment>
                  <Table
                    cols={tableConstants(permissions, notificationDelete)}
                    data={data}
                    hover={true}
                  />
                  <Pagination
                    totalPage={lastPage}
                    currentPage={currentPage}
                    handlePageChange={(i) => fetchNotification(i)}
                  ></Pagination>
                </React.Fragment>
              )}
            </div>}
        </div>
      </div>
    </div>
  );
};
export default NotificationList;
