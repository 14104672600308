import React from "react";
import "./Switch.css";

const Switch = ({ label, name, id, onChange, ...rest }) => {

  return (
    <div className="switch-container">
      <input
        type="checkbox"
        className='switch-input cursor-pointer'
        id={id}
        onChange={(event) => onChange(event)}
        {...rest}
      />
      <label className="switch-slider cursor-pointer" htmlFor={id}>
      <div className="switch-thumb"></div>
      </label>
      <label className="switch-label cursor-pointer" htmlFor={id}>{label}</label>
    </div>
  );
};

export default Switch;

