import React, { memo } from 'react';
import { Form } from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import { Link } from 'react-router-dom';
import Input from '../../../../components/Input/Input';
import Button from '../../../../components/Button/Button';

const UploadTimeTableForm = ({ submitHandler, classAddHandler, classRemoveHandler, timetable_file, programData, fileUploadHandler, program_ids, id }) => {
  return (
    <Form onSubmit={submitHandler}>
      <div className="col-md-12">
        <Form.Group>
          <Form.Label>Class</Form.Label>
          <Multiselect
            name={`program_ids`}
            options={programData}
            id={program_ids}
            onRemove={(e) => classRemoveHandler(e)}
            displayValue="name"
            showCheckbox={true}
            selectedValues={program_ids}
            onSelect={(e) => classAddHandler(e)}
          />
        </Form.Group>
      </div>
      <div className="col-md-12">
        <Input
          label="Timetable File"
          type="file"
          name="timetable_file"
          onChangeHandler={(e) => fileUploadHandler(e)}
        />
        {id && timetable_file && <Link to={timetable_file} target="_blank">View File</Link>}
      </div>
      <div className="row d-flex justify-content-end mt-3">
        <div className="col-md-1">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default memo(UploadTimeTableForm);
