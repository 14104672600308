import React, { useState, useEffect } from 'react';
import { Button, Table, Form } from 'react-bootstrap';
import AssessmentInfo from './AssessmentInfo';
import Loader from '../../components/Loader/Loader';
import TraitMarking from './TraitMarking';
import { FaEdit, FaEye } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import MarkingExcelBulkUpload from './MarkingExcelBulkUpload';
import MarkingResultView from './MarkingResultView';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { getAssessmentParticipants, createAssessmentMarking, updateAssessmentMarking } from '../../services/AssessmentMarkingService';
import { viewReportCard, downloadReportCard } from '../../services/ReportCardService';

const AssessmentParticipantsMarking = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [eventInfo, setEventInfo] = useState([]);
  const [gradeSchemeId, setGradeSchemeId] = useState('');
  const [event_marking, setEventMarking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [is_traits, setIsTraits] = useState(0);
  const [gradeSchemeName, setGradeScheme] = useState('');
  const [traits, setTraits] = useState([]);
  const [trait_marking, setTraitMarking] = useState([]);
  const [eventPaticipants, setEventParticipants] = useState([]);
  const [editableStudentId, setEditableStudentId] = useState(null);
  const [isBulkUpload, setBulkUpload] = useState(false);
  const [show, setShow] = useState(false);
  const [resultData, setResultData] = useState([{}]);
  const [pdfUrlData, setPdfUrlData] = useState(null);
  const { id } = useParams();
  const remarkMap = {
    'Student has scored above 50% in all subjects': 'Congratulations! Passed and Promoted to Class _____',
    'Student has scored above 33% and below 50% in all subjects': 'Passed and Promoted to Class _____',
    'Students promoted after giving grace marks': 'Promoted to Class _____',
    'Student has scored above 25% and less than 33% in 2 subjects': 'Promotion granted after clearing the Retest in _____ and _____',
    'Student has scored less than 25% in 2 or more than 2 subjects': 'Promotion not granted',
    'Student has scored below 33%': 'Promotion granted under NDP'
  }

  const fetchData = async (flag) => {
    try {
      const response = await getAssessmentParticipants(params.id);
      const eventData = response.data.data;
      let gradeSchemename = eventData.grade_scheme.name.split('-');
      gradeSchemename = gradeSchemename[0] + ' ' + gradeSchemename[1];
      setGradeScheme(gradeSchemename);
      // Destructure eventData for better readability
      const { grade_scheme: gradeScheme, traits, event_participants } = eventData;
      // Set state values
      setTraits(traits);
      setEventInfo(eventData);
      setEventParticipants(eventData.event_participants);
      setGradeSchemeId(gradeScheme.id);
      setIsTraits(eventData.is_traits);

      if (flag) {
        // Flatten traitMarking and event_marking arrays using flatMap
        const traitMarking = event_participants.flatMap(student =>
          traits.map((traitMark) => {

            const marksData = student.marks_data && student.marks_data.find(element =>
              element.trait_id === traitMark.subject_trait.id
            );
            return {
              event_marking_id: marksData ? marksData.id : '',
              trait_id: traitMark.subject_trait ? traitMark.subject_trait.id : '',
              grade_scheme_id: traitMark.grade_scheme.id,
              student_id: student.id,
              option_id: marksData ? marksData.option_id : '',
              option_value: marksData ? marksData.option_value : '',
              remark: marksData ? marksData.remark : ''
            };
          })
        );
        const eventMarking = event_participants.map(student => ({
          student_id: student.id,
          event_marking_id: student.marks_data[0] ? student.marks_data[0].id : '',
          option_id: student.marks_data[0] ? student.marks_data[0].option_id : '',
          option_value: student.marks_data[0] ? student.marks_data[0].option_value : '',
          grade_scheme_id: student.marks_data[0] ? student.marks_data[0].grade_scheme_id : '',
          remark: student.marks_data[0] ? student.marks_data[0].remark : '',
        }));
        setTraitMarking(traitMarking);
        setEventMarking(eventMarking);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const updatedfetchData = async (flag) => {
    try {
      const response = await getAssessmentParticipants(params.id);
      const eventData = response.data.data;

      let gradeSchemename = eventData.grade_scheme.name.split('-');
      gradeSchemename = gradeSchemename[0] + ' ' + gradeSchemename[1];
      setGradeScheme(gradeSchemename);
      // Destructure eventData for better readability
      const { grade_scheme: gradeScheme, traits, event_participants } = eventData;

      // Set state values
      setTraits(traits);
      setEventInfo(eventData);
      setGradeSchemeId(gradeScheme.id);
      setIsTraits(eventData.is_traits);

      // Flatten traitMarking and event_marking arrays using flatMap
      const traitMarking = event_participants.flatMap(student =>
        traits.map((traitMark, idx) => {
          const marksData = student.marks_data && student.marks_data.find(element =>
            element.trait_id === traitMark.subject_trait.id
          );

          return {
            event_marking_id: marksData ? marksData.id : '',
            trait_id: traitMark.subject_trait ? traitMark.subject_trait.id : '',
            grade_scheme_id: traitMark.grade_scheme.id,
            student_id: student.id,
            option_id: marksData ? marksData.option_id : '',
            option_value: marksData ? marksData.option_value : '',
            remark: marksData ? marksData.remark : ''
          };
        })
      );

      const eventMarking = event_participants.map(student => ({
        student_id: student.id,
        event_marking_id: student.marks_data[0] ? student.marks_data[0].id : '',
        option_id: student.marks_data[0] ? student.marks_data[0].option_id : '',
        option_value: student.marks_data[0] ? student.marks_data[0].option_value : '',
        grade_scheme_id: student.marks_data[0] ? student.marks_data[0].grade_scheme_id : '',
        remark: student.marks_data[0] ? student.marks_data[0].remark : '',
      }));

      setTraitMarking(traitMarking);
      setEventMarking(eventMarking);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const tableContainer = document.querySelector('.table-container');
      const scrollLeft = tableContainer.scrollLeft;
      var scrollRight = scrollLeft;

      document.querySelectorAll('th:first-child, td:first-child, th:nth-child(2), td:nth-child(2), th:nth-child(3), td:nth-child(3)').forEach((cell) => {
        cell.style.transform = `translateX(${scrollLeft}px)`;
      });

      document.querySelectorAll('th:last-child, td:last-child').forEach((cell) => {
        // Assuming scrollRight is defined somewhere in your code or calculated
        cell.style.marginRight = `${scrollRight}px`;
      });
    };

    const tableContainer = document.querySelector('.table-container');
    // Ensure the table container is available
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
      // Clean up the event listener when the component is unmounted
      return () => {
        tableContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    fetchData(1);
  }, [params.id]);

  const handleChange = (event, student, index, schemeType) => {
    const { name, value } = event.target;
    const evaluationType = eventInfo.evaluation_type.name;

    if (name === 'remark' && value.length > 250) return;

    const updatedEventMarking = event_marking.map((mark, idx) => {
      if (idx !== index) return mark;
      let updatedMark = {
        ...mark,
        student_id: student.id,
        grade_scheme_id: gradeSchemeId,
      };

      if (schemeType === 'letter') {
        const selectedOption = eventInfo.grade_scheme.ScoreOptions.find(option => Number(option.id) === Number(value));
        if (selectedOption) {
          updatedMark = {
            ...updatedMark,
            option_id: selectedOption.id,
            option_value: selectedOption.option_name,
            remark: (evaluationType === 'GENERAL REMARKS' || gradeSchemeName === 'SUBJECT REMARK') ?
              selectedOption.option_name : (evaluationType === 'REMARKS' ?
                remarkMap[selectedOption.option_name] : ""),
          };
        }
      } else if (name === 'option_value') {
        updatedMark = {
          ...updatedMark,
          option_id: '',
          option_value: value.replace(/[^0-9.]/g, ''),
        };
      } else {
        updatedMark = {
          ...updatedMark,
          [name]: value,
        };
      }
      return updatedMark;
    });
    setEventMarking(updatedEventMarking);
  };

  const getTraitIndex = (studentID, traitID) => {
    let index = -1;
    trait_marking.forEach((mark, idx) => {
      if (Number(mark.student_id) === Number(studentID) && Number(mark.trait_id) === Number(traitID)) {
        index = idx;
      }
    })
    return index;
  }

  const traitChangeHandler = (event, studentID, traitID, studentIndex) => {
    const { value } = event.target;
    if (Number(value) === 0)
      return;

    const updatedMark = eventInfo.grade_scheme.ScoreOptions.find(option => Number(option.id) === Number(value));
    const traitIndex = getTraitIndex(studentID, traitID);

    if (updatedMark === undefined) {
      return;
    }
    if (traitIndex !== -1) {
      const updatedTrait = {
        ...trait_marking[traitIndex],
        option_id: updatedMark.id !== undefined ? updatedMark.id : 0,
        option_value: updatedMark.option_name,
        remark: updatedMark.remark ? updatedMark.remark : '',
      };
      setTraitMarking(prevTraitMarking => {
        const updatedTraitMarking = [...prevTraitMarking];
        updatedTraitMarking[traitIndex] = updatedTrait;
        return updatedTraitMarking;
      });

      let studentList = eventPaticipants[studentIndex];
      const updatedTraitMarking = [...trait_marking];
      updatedTraitMarking[traitIndex] = updatedTrait;

      let markIndex = -1;
      studentList.marks_data.forEach((markData, idx) => {
        if (Number(markData.trait_id) === Number(traitID)) {
          markIndex = idx;
        }
      });
      let updatedMarksData = [...studentList.marks_data];
      updatedMarksData[markIndex] = {
        ...updatedMarksData[markIndex],
        option_id: updatedMark.id,
        option_value: updatedMark.option_name,
        remark: ''
      };
      setEventParticipants(prevEventParticipants => {
        const updatedEventParticipants = [...prevEventParticipants];
        updatedEventParticipants[studentIndex] = {
          ...updatedEventParticipants[studentIndex],
          marks_data: updatedMarksData,
        };
        return updatedEventParticipants;
      });
    }
  };

  const handleSubmit = async (event, student_id, index) => {
    event.preventDefault();
    let final_traits = [];
    final_traits = trait_marking.filter((marks) => (Number(marks.student_id) === Number(student_id)));
    final_traits.forEach((mark) => {
      if (mark.event_marking_id === '') {
        delete mark['event_marking_id'];
      }
      if (mark.option_value === '') {
        mark.option_value = 'options';
        mark.option_id = '';

      }
    })

    let Object = localStorage.getItem('user');
    let user = JSON.parse(Object);
    const user_id = user.id;
    const event_id = id;

    const data = {
      user_id,
      event_id,
      student_id: student_id,
      is_traits,
      traits: final_traits,
      event_marking: is_traits ? [] : [event_marking[index]],
    };
    try {
      if (editableStudentId) {
        const response = await updateAssessmentMarking({ data });
        if (response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
          if (eventInfo.evaluation_type.name !== 'CO-SCHOLASTIC ASSESSMENT') {
            updatedfetchData(1);
          }
          fetchData(1);
        }
      }
      else {
        const response = await createAssessmentMarking({ data });
        if (response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
          fetchData(1);
        }
      }
      setEditableStudentId(null);
    } catch (error) {
      console.error('Error submitting data:', error);
    }

  }

  const editStudentMarksHandler = (student, idx) => {
    setEditableStudentId(student.id);
    if (eventInfo.evaluation_type.name === 'SCHOLASTIC ASSESSMENT')
      fetchData(1);
    else
      updatedfetchData();
  };

  const downloadExcel = () => {
    let final_data = [];
    eventPaticipants.forEach(student => {
      let formatedData = {};
      formatedData.event_name = eventInfo.name.name;
      formatedData.student_name = `${student.first_name} ${student.last_name}`;
      formatedData.student_roll_no = student.roll_no;
      formatedData.student_admission_no = student.id_number;
      formatedData.student_class = eventInfo.program.name;

      if (is_traits) {
        eventInfo.traits.forEach((trait) => {
          formatedData[`${trait.subject_trait.name}`] = '';
        })
      }
      else {
        formatedData.option_value = '';
        formatedData.remark = '';
      }
      final_data.push(formatedData);
    })
    const ws = XLSX.utils.json_to_sheet(final_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${eventInfo.program.name}-${eventInfo.subject.name}-${eventInfo.name.name}.xlsx`);
  }

  const allSubmitHandler = async (event) => {
    event.preventDefault();
    let traits_clone = JSON.parse(JSON.stringify(trait_marking));
    let marking = event_marking.filter((student) => (student && student.option_value !== '' && student.event_marking_id === ''));

    let create_trait = traits_clone.filter((student) => (student.event_marking_id === '' && student && student.option_value !== ''));
    let student_ids = [];
    create_trait.forEach((mark) => {
      if (!student_ids.includes(mark.student_id))
        student_ids.push(mark.student_id);
    })
    let traits_res = traits_clone.filter((student) => (student_ids.includes(student.student_id)));
    let traits = traits_res.map((student) => { if (student.option_value === '') { student.option_value = 'options' } return student; });

    let Object = localStorage.getItem('user');
    let user = JSON.parse(Object);
    const user_id = user.id;
    const event_id = id;

    if (is_traits) {
      if (traits.length === 0) {
        enqueueSnackbar('Please select trait', { variant: 'error' });
        return;
      }
    }
    else {
      if (marking.length === 0) {
        enqueueSnackbar('Please select marking', { variant: 'error' });
        return;
      }
    }

    const data = {
      user_id,
      event_id,
      is_traits,
      traits: traits,
      event_marking: is_traits ? [] : marking,
    };

    try {
      const response = await createAssessmentMarking({ data });
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        setTraitMarking([]);
        setEventMarking([]);
        fetchData(1);
      }
    }
    catch (error) {
      console.error('Error submitting data:', error);
      return error;
    }
  }

  const excelDataReader = (e) => {
    setEventMarking([]);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    let traitMap = [];
    traits.forEach((trait) => {
      traitMap[trait.subject_trait.name] = trait.subject_trait.id;
    })

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);

      let final_data = [];
      let trait_data = [];
      let id_number_student_id_map = [];

      eventPaticipants.forEach((student) => {
        id_number_student_id_map[student.id_number] = student.id;
      })

      data.forEach((student) => {
        if (is_traits) {
          for (let key in student) {
            if (traitMap[key]) {
              let formatedData = {};
              formatedData.student_id = id_number_student_id_map[student.student_admission_no];
              formatedData.remark = '';
              formatedData.grade_scheme_id = gradeSchemeId;
              formatedData.trait_id = traitMap[key];
              formatedData.option_value = student[key].toUpperCase();
              let option = eventInfo.grade_scheme.ScoreOptions.find(option => option.option_name.toUpperCase() === student[key].toUpperCase());
              if (option)
                formatedData.option_id = option.id;
              trait_data.push(formatedData);
            }
          }
        }
        else {
          let formatedData = {};
          formatedData.student_id = id_number_student_id_map[student.student_admission_no];
          formatedData.option_id = student.option_id;
          formatedData.option_value = student.option_value;
          formatedData.remark = student.remark;
          formatedData.grade_scheme_id = gradeSchemeId;
          final_data.push(formatedData);
        }
      })

      setEventMarking(final_data);
      setTraitMarking(trait_data);
    }
  }

  const excelDataSubmitHandler = async (event) => {

    event.preventDefault();
    let traits_clone = JSON.parse(JSON.stringify(trait_marking));
    let marking = event_marking.filter((student) => (student && ((student.option_value == '0' || student.option_value))));

    let create_trait = traits_clone.filter((student) => ((student.event_marking_id === '' && student && student.option_value !== '') || student.remark !== ''));
    let student_ids = [];
    create_trait.forEach((mark) => {
      if (!student_ids.includes(mark.student_id))
        student_ids.push(mark.student_id);
    })

    let traits_res = traits_clone.filter((student) => (student_ids.includes(student.student_id)));
    let traits = traits_res.map((student) => { if (student.option_value === '') { student.option_value = 'options' } return student; });

    let Object = localStorage.getItem('user');
    let user = JSON.parse(Object);
    const user_id = user.id;
    const event_id = id;

    let trait_marking_data = trait_marking.filter((student) =>
      (student && ((student.option_value !== '' && student.option_value !== undefined) || (student.option_value == '0'))));

    let traits_marking = trait_marking_data.map((student) => {
      student.option_value = student.option_value.toUpperCase();
      return student;
    });
    if (is_traits) {
      if (trait_marking_data.length === 0) {
        enqueueSnackbar('Please select trait', { variant: 'error' });
        return;
      }
    }
    else {
      if (marking.length === 0) {
        enqueueSnackbar('Please select marking', { variant: 'error' });
        return;
      }
    }

    const data = {
      user_id,
      event_id,
      is_traits,
      traits: traits_marking,
      event_marking: is_traits ? [] : marking,
    };
    try {
      const response = await createAssessmentMarking({ data });
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        setTraitMarking([]);
        setEventMarking([]);
        fetchData(1);
      }
    }
    catch (error) {
      console.error('Error submitting data:', error);
      return error;
    }
  }

  const ResultViewHandler = async (student, index) => {
    const term = eventInfo.term;
    const student_id = student.id;
    const session_id = localStorage.getItem('session_id');
    setShow(true);
    setPdfUrlData(null);

    if (eventInfo.evaluation_type.name === 'REMARKS') {
      fetchStudentPdf(student_id, term);
    }

    try {
      const response = await viewReportCard(student_id, session_id, term);
      if (response.status === 200) {
        const resultData = response.data.data;
        setResultData(resultData);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const fetchStudentPdf = async (student_id, term) => {
    try {
      const session_id = localStorage.getItem('session_id');
      const response = downloadReportCard(student_id, session_id, term, { responseType: 'arraybuffer' });
      const data = response.data;
      let blob = new Blob([data], { type: 'application/pdf' });
      let blobUrl = URL.createObjectURL(blob);
      setPdfUrlData(blobUrl);
    } catch (error) {
      enqueueSnackbar('Report Card not generated yet!', { variant: 'error' });
      console.error('Error fetching PDF:', error);
    }
  };

  return (
    <React.Fragment>
      {loading ? (<Loader />) :
        (<div className="">
          <AssessmentInfo eventInfo={eventInfo}
            back={() => navigate('/assessment/marking/list')} />
          {show &&
            <MarkingResultView
              show={show}
              setShow={setShow}
              resultData={resultData}
              remarkType={eventInfo.evaluation_type.name}
              pdfData={pdfUrlData}
            />}
          <div className="animated mt-2">
            <div className="card">
              <div className="card-header">
                {isBulkUpload ?
                  <MarkingExcelBulkUpload
                    excelDataReader={excelDataReader}
                    excelDataSubmitHandler={excelDataSubmitHandler}
                    downloadExcel={downloadExcel}
                  /> :
                  <div className="">
                    Event Participants
                    <div className="d-flex justify-content-end">
                      <Button type="submit"
                        onClick={e => setBulkUpload(true)}
                        variant="success">
                        Bulk Upload
                      </Button>
                    </div>
                  </div>}
              </div>
              <div className={`card-body`}
                style={{ overflow: 'auto' }} >
                <form>
                  <div className="table-container">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>RollNo</th>
                          <th>Admission Number</th>
                          <th>Name</th>
                          {is_traits ?
                            <React.Fragment>
                              {eventInfo.traits.map((student, traitIdx) =>
                                <React.Fragment key={traitIdx}>
                                  <th style={{ width: '10%' }}>{student.subject_trait.name}</th>
                                </React.Fragment>
                              )}
                            </React.Fragment> :
                            <React.Fragment>
                              <th style={{ width: '30%' }}>Marking</th>
                              <th style={{ width: '30%' }}>Remark</th>
                            </React.Fragment>
                          }
                          <th style={{ textAlign: 'center', width: '10%' }} >Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          eventPaticipants && eventPaticipants.map((student, studentIndex) =>
                            <tr key={studentIndex}>
                              <td>{student.roll_no}</td>
                              <td>{student.id_number}</td>
                              <td>{`${student.first_name} ${student.last_name}`}</td>
                              {is_traits ?
                                <TraitMarking
                                  traits={eventInfo.traits}
                                  student={student}
                                  eventInfo={eventInfo}
                                  traitChangeHandler={traitChangeHandler}
                                  editableStudentId={editableStudentId}
                                  studentIndex={studentIndex}
                                />
                                :
                                <React.Fragment>
                                  {student.marks_data[0] !== undefined ?
                                    <React.Fragment>
                                      <td style={{ width: '30%' }}>
                                        {editableStudentId === student.id ? (
                                          <React.Fragment>
                                            {(eventInfo.grade_scheme.type === 'Letters')
                                              || (eventInfo.grade_scheme.type === 'Text') ?
                                              <select
                                                onChange={(event) => handleChange(event, student, studentIndex, "letter")}
                                                name="option_id"
                                                className="form-select form-control cursor-pointer"
                                                data-placeholder="Select organization"
                                                value={event_marking[studentIndex].option_id || ''}>
                                                <option value=''>Options</option>
                                                {eventInfo.grade_scheme
                                                  .ScoreOptions.map((Option, opIdx) =>
                                                    <option value={Option.id} key={opIdx}
                                                    >{Option.option_name}</option>
                                                  )}
                                              </select> :
                                              <Form.Control
                                                type="text"
                                                name="option_value"
                                                onChange={(event) => {
                                                  handleChange(event, student, studentIndex);
                                                }}
                                                value={event_marking[studentIndex] ? event_marking[studentIndex].option_value : ""} />}
                                          </React.Fragment>
                                        ) : (
                                          student.marks_data[0].option_value
                                        )}
                                      </td>
                                      <td style={{ width: '30%' }}>{editableStudentId === student.id ? (
                                        <React.Fragment>
                                          {(eventInfo.evaluation_type.name === 'SCHOLASTIC ASSESSMENT') &&
                                            gradeSchemeName !== 'SUBJECT REMARK' ?
                                            <select
                                              className='form-control select2'
                                              value={event_marking[studentIndex] ? event_marking[studentIndex].remark : ''}
                                              onChange={(event) => handleChange(event, student, studentIndex)}
                                              name="remark">
                                              <option value=''>Select</option>
                                              <option value='AB'>AB</option>
                                              <option value='ML'>ML</option>
                                              <option value='LL'>LL</option>
                                              <option value='-'>-</option>
                                            </select> :
                                            <React.Fragment>
                                              <Form.Control
                                                name="remark"
                                                as="textarea" rows={3}
                                                onChange={(event) => handleChange(event, student, studentIndex)}
                                                value={event_marking[studentIndex].remark || ''}
                                              />
                                              {event_marking[studentIndex] && event_marking[studentIndex].remark && event_marking[studentIndex].remark.length > 0 &&
                                                <span style={{ marginTop: '5px', color: event_marking[studentIndex] && event_marking[studentIndex].remark.length >= 250 ? 'red' : 'black' }}>
                                                  {event_marking[studentIndex] && event_marking[studentIndex].remark && event_marking[studentIndex].remark.length}/{250}
                                                </span>
                                              }
                                            </React.Fragment>
                                          }
                                        </React.Fragment>
                                      ) :
                                        (student.marks_data[0].remark)}
                                      </td>
                                    </React.Fragment> :
                                    <React.Fragment>
                                      <td style={{ width: '30%' }}>
                                        {eventInfo.grade_scheme.type === 'Letters'
                                          || eventInfo.grade_scheme.type === 'Text' ?
                                          <select onChange={event => handleChange(event, student, studentIndex, 'letter')}
                                            name="option_id"
                                            className="form-select form-control cursor-pointer"
                                            data-placeholder="Select organization">
                                            <option value=''>Options</option>
                                            {eventInfo.grade_scheme
                                              .ScoreOptions.map((Option, opIdx) =>
                                                <option value={Option.id} key={opIdx}
                                                >{Option.option_name}</option>
                                              )}
                                          </select>
                                          :
                                          <Form.Control type="text" name="option_value"
                                            onChange={event => {
                                              handleChange(event, student, studentIndex);
                                            }} />
                                        }
                                      </td>
                                      <td style={{ width: '30%' }}>
                                        {eventInfo.evaluation_type.name === 'SCHOLASTIC ASSESSMENT'
                                          && gradeSchemeName !== 'SUBJECT REMARK' ?
                                          <select
                                            className="form-control select2"
                                            onChange={(event) => handleChange(event, student, studentIndex)}
                                            value={(event_marking[studentIndex] && event_marking[studentIndex].remark) || ''}
                                            name="remark">
                                            <option value=''>Select</option>
                                            <option value='AB'>AB</option>
                                            <option value='ML'>ML</option>
                                            <option value='LL'>LL</option>
                                            <option value='-'>-</option>
                                          </select> :
                                          <React.Fragment>
                                            <Form.Control name="remark"
                                              as="textarea" rows={3}
                                              value={event_marking[studentIndex] && event_marking[studentIndex].remark}
                                              onChange={event => handleChange(event, student, studentIndex)} />
                                            {event_marking[studentIndex] && event_marking[studentIndex].remark && event_marking[studentIndex].remark.length > 0 &&
                                              <span style={{
                                                marginTop: '5px',
                                                color: event_marking[studentIndex] && event_marking[studentIndex].remark && event_marking[studentIndex].remark.length >= 250 ? 'red' : 'black'
                                              }}>
                                                {event_marking[studentIndex].remark.length}/{250}
                                              </span>
                                            }
                                          </React.Fragment>
                                        }
                                      </td>
                                    </React.Fragment>
                                  }
                                </React.Fragment>
                              }
                              <td style={{ width: '10%' }}>
                                {
                                  (eventInfo.evaluation_type.name === 'GENERAL REMARKS')
                                    || (eventInfo.evaluation_type.name === 'REMARKS')
                                    ?
                                    <FaEye
                                      size="20px" cursor="pointer"
                                      color='blue'
                                      style={{ margin: '10px' }}
                                      onClick={() => ResultViewHandler(student, studentIndex)}
                                    /> : ''
                                }
                                {
                                  student.marks_data[0] ?
                                    <React.Fragment>
                                      {(editableStudentId === student.id) ? (
                                        <Button
                                          type="submit"
                                          variant="success"
                                          onClick={(event) =>
                                            handleSubmit(event, student.id, studentIndex)}>
                                          Update</Button>) :
                                        (<React.Fragment>
                                          {(student.marks_data && student.marks_data[0].is_approved === 0) &&
                                            <FaEdit
                                              size="20px" cursor="pointer"
                                              color='blue'
                                              onClick={() =>
                                                editStudentMarksHandler(student, studentIndex)}
                                            />}
                                        </React.Fragment>
                                        )}
                                    </React.Fragment> :
                                    <Button type="submit" variant="success"
                                      onClick={event => handleSubmit(event, student.id, studentIndex)}>
                                      Save</Button>
                                }
                              </td>
                            </tr>)
                        }
                      </tbody>
                    </Table>
                  </div>
                  <div className='d-flex justify-content-end mt-1'>
                    <Button type="submit" variant="success"
                      onClick={event => allSubmitHandler(event)}>
                      Save all</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        )}
    </React.Fragment>
  );
};

export default AssessmentParticipantsMarking;
