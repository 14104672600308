import React from 'react';

const TraitApproval = ({ traits, student}) => {
  return (
    <React.Fragment>
      {traits.map((studentTrait, traitIndex) =>
        <React.Fragment key={traitIndex}>
          {(student.marks_data[traitIndex]) ?
            <React.Fragment>
              <td>{student.marks_data[traitIndex].option_value}</td>
              <td>{student.marks_data[traitIndex].remark}</td>
            </React.Fragment> :
            <React.Fragment>
              <td>-</td>
              <td>-</td>
            </React.Fragment>
          }
        </React.Fragment>)}
    </React.Fragment>
  );
};

export default TraitApproval;
;
