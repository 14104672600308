import axios from "axios";
import { ENDPOINTS } from "./endpoints";

export const getStudentCount = async (session_id) => {
  try {
    const response = await axios.get(ENDPOINTS.DASHBOARD.GET_STUDENT_COUNT, { headers: { "session-id": session_id } });
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
}

export const getTeacherCount = async () => {
  try {
    const response = await axios.get(ENDPOINTS.DASHBOARD.GET_TEACHER_COUNT);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getUserCount = async () => {
  try {
    const response = await axios.get(ENDPOINTS.DASHBOARD.GET_USER_COUNT);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getClassWiseStudentCount = async (session_id) => {
  try {
    const response = await axios.get(ENDPOINTS.DASHBOARD.GET_CLASS_WISE_STUDENT_COUNT, { headers: { "session-id": session_id } });
    return response;
  } catch (error) {
    throw error;
  }
}
