import React, { useEffect, useState } from 'react';
import StudentForm from './StudentForm';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchPrograms } from '../../redux/program/programActions';
import * as XLSX from 'xlsx';
import { Button } from 'react-bootstrap';
import { enqueueSnackbar } from 'notistack';
import ExcelDataSample from './StudentExcelSample.json';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import CardHeader from '../../components/Card/CardHeader';
import { createBulkStudents, createStudent, getStudentById, updateBulkStudents, updateStudent } from '../../services/StudentService';

const StudentManage = () => {
  const initialState = {
    first_name: '', last_name: '', id_number: '',
    roll_no: '', user_name: '', email: '', gender: '',
    dob: '', phone1: '', phone2: '', category: '', aadhar_number: '',
    father_name: '', mother_name: '', house_name: '', suspended: 0,
    program_id: ''
  };
  const [state, setState] = useState(initialState);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const [preview, setPreview] = useState(null);
  const [isBulk, setIsBulk] = useState(false);
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data ?? []);


  const changeHandler = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  }

  const getUniqueStudent = async (id) => {
    const session_id = localStorage.getItem('session_id');
    try {
      setLoader(true);
      const response = await getStudentById(id, session_id);
      setLoader(false);
      if (response.status === 200) {
        const data = response.data.data;
        data.program_id = data.programs && data.programs.id;
        setPreview(data.profile_image);
        setState({
          ...data, gender: data.gender.toLowerCase(),
          dob: moment.unix(data.dob).format('YYYY-MM-DD')
        });
        delete data.programs;
        delete data.created_at;
        delete data.organization_id;
      }
    } catch (error) {
      setLoader(false);
      return error;
    }
  }

  useEffect(() => {
    if (id)
      getUniqueStudent(id);
    dispatch(fetchPrograms());
  }, []);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!image) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(image)
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [image])


  const excelFileReader = (e) => {
    const session_id = localStorage.getItem('session_id');
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const result = {};
      workbook.SheetNames.forEach(sheet => {
        const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        result[sheet] = json_data;
      });

      let final_data = result['Sheet1'];
      let classNameIdMap = [];
      programData.forEach((item) => {
        let key = item.name.toLowerCase().replace(/\s/g, '');
        classNameIdMap[key] = item.id
      })

      // Now 'result' contains JSON data for each sheet
      let finalData = final_data && final_data.map((item, index) => {
        let className = item.class && item.class.toLowerCase().replace(/\s/g, '');
        let program_id = classNameIdMap[className];
        let phone1 = item.phone1;

        if (!program_id) {
          enqueueSnackbar(`Program not found for class ${item.class} at line no ${index + 1}`, { variant: "error" });
        }

        if (!phone1) { enqueueSnackbar(`Phone1 is required at line no ${index + 1}`, { variant: "error" }); }

        let { first_name, last_name, roll_no, user_name,
          email, id_number, gender, dob, phone2, category,
          aadhar_number, father_name, mother_name, house_name,
          suspended, profile_image
        } = item;

        return {
          uuid: id_number, first_name, last_name,
          roll_no, user_name, email, id_number, gender,
          dob, phone1, phone2, category, aadhar_number,
          father_name, mother_name, house_name, suspended,
          program_id, session_id: session_id, profile_image
        };
      });
      setFile(finalData);
    };
    reader.readAsBinaryString(e.target.files[0]);
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(ExcelDataSample);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `student-bulk-upload-sample.xlsx`);
  };

  // Note: Use readAsBinaryString instead of readAsArrayBuffer to handle binary data for XLSX.
  const studentBulkUpload = async () => {
    if (file === null || file === undefined) {
      enqueueSnackbar("Please select a file", { variant: "error" });
      return;
    }
    else {
      let data = { file };
      try {
        setLoader(true);
        let response = updateFlag ? await updateBulkStudents(data) : await createBulkStudents(data);
        setLoader(false);
        if (response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: "success" });
          navigate('/student/list');
        }
        else {
          console.log(response.data.message.error, "error");
          enqueueSnackbar(response.data.message.error, { variant: "error" });
        }
      } catch (error) {
        setLoader(false);
        if (error.response && error.response.status === 422) {
          let errorObject = error.response.data.message.errors
          setErrors(errorObject);
          // Handle 422 error
          if (errorObject.hasOwnProperty('file')) {
            for (let key in errorObject) {
              if (errorObject.hasOwnProperty(key)) {
                // Access the array elements for each property
                errorObject[key].forEach(error => {
                  enqueueSnackbar(error, { variant: "error" });
                  console.log(error);
                });
              }
            }
          } else {
            // Handle other errors
            console.error('Request failed:', error.message);
          }
          console.log(error.response.data.message.errors, "error");
        }
      }
    }
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');

    const { id_number, first_name, last_name, roll_no, user_name, email,
      gender, dob, phone1, phone2, category, aadhar_number, father_name,
      mother_name, house_name, suspended, program_id,
    } = state;
    if (!id_number || !first_name || !roll_no || !user_name)
      return enqueueSnackbar("Please fill all the required fields", { variant: "error" });

    const data = new FormData();
    data.append('image', image);
    data.append('id_number', id_number);
    data.append('uuid', id_number);
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    data.append('roll_no', roll_no);
    data.append('user_name', user_name);
    data.append('email', email);
    data.append('gender', gender);
    data.append('dob', dob);
    data.append('phone1', phone1);
    data.append('phone2', phone2);
    data.append('aadhar_number', aadhar_number);
    data.append('category', category);
    data.append('father_name', father_name);
    data.append('mother_name', mother_name);
    data.append('house_name', house_name);
    data.append('suspended', suspended);
    data.append('program_id', program_id);
    data.append('session_id', session_id);

    setLoader(true);
    if (id) {
      data.append('id', id);
      try {
        const response = await updateStudent(id, data);
        console.log(response, "response");
        setLoader(false);
        if (response.status === 200) {
          setLoader(false);
          enqueueSnackbar(response.data.message.success, { variant: "success" });
          navigate('/student/list');
          setState(initialState);
        }
      } catch (error) {
        setLoader(false);
        return error;
      }
      return;
    }

    try {
      const response = await createStudent(data);
      setLoader(false);
      if (response.status === 200) {
        setLoader(false);
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        navigate('/student/list');
        setState(initialState);
      }
    } catch (error) {
      setLoader(false);
      enqueueSnackbar(error.response.data.message, { variant: "error" });
      return error;
    }
  }

  const back = () => {
    navigate('/student/list');
  }

  return (
    <div className="card">
      <CardHeader
        title={id ? 'Update Student' : 'Add Student'}
        icon={''}
        button={<div className="">
          {permissions.includes('bulk.upload.student') ?
            <React.Fragment>
              {id ? "" :
                <Button variant="primary" className="mx-1" onClick={() => setIsBulk(!isBulk)}>
                  {isBulk ? "Manual Create" : "Bulk Upload"}
                </Button>
              }
            </React.Fragment> : ""
          }
          <Button variant="secondary" className="mx-1" onClick={back}>
            Back
          </Button>
        </div>
        }
      />
      <div className="card-body">
        <StudentForm
          changeHandler={changeHandler}
          submitHandler={submitHandler}
          loader={loader}
          programData={programData}
          data={state}
          image={image}
          setImage={setImage}
          preview={preview}
          id={id}
          updateFlag={updateFlag}
          setUpdateFlag={setUpdateFlag}
          isBulk={isBulk}
          bulkUploadFile={studentBulkUpload}
          excelFileReader={excelFileReader}
          downloadExcel={downloadExcel}
        />
        {errors && Object.keys(errors).length > 0 &&
          <div className="alert alert-danger">
            <ul>
              {Object.keys(errors).map((key, index) => {
                // Check if the error message is an object and convert it to a string if it is
                const errorMessage = typeof errors[key] === 'object' ? JSON.stringify(errors[key]) : errors[key];
                return <li key={index}>{errorMessage}</li>;
              })}
            </ul>
          </div>
        }
      </div>
    </div>
  );
};
export default StudentManage;
