import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getNotificationById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.NOTIFICATION.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getNotifications = async (page) => {
  try {
    const response = await axios.get(ENDPOINTS.NOTIFICATION.GET, {
      params: {
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createNotification = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.NOTIFICATION.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateNotification = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.NOTIFICATION.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteNotification = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.NOTIFICATION.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}


