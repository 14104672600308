import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchTeachers } from "../../../redux/teacher/teacherActions";
import { fetchSubjects } from "../../../redux/subject/subjectActions";
import { fetchPrograms } from '../../../redux/program/programActions';
import Loader from "../../../components/Loader/Loader";
import { FaChalkboardTeacher } from "react-icons/fa";
import { tableConstants } from "./AttendanceMapTableConst";
import Table from "../../../components/Table/Table";
import Pagination from "../../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import Modal from "../../../components/Modal/Modal";
import AttendanceTeacherMapForm from './AttendanceTeacherMapForm';
import { getMappedPrograms, createAttendanceTeacherMapping } from '../../../services/AppManagement/AttendanceService';
import Input from "../../../components/Input/Input";

const AttendanceTeacherMap = () => {
  const [state, setState] = useState({
    teacherData: [],
    new_password: "",
    resetPasswordUserId: null,
    permissions: JSON.parse(localStorage.getItem("permissions")),
    name: "",
    isBulk: false,
    file: null
  });

  const dispatch = useDispatch();
  const { programData, teacherData, lastPage, loader } = useSelector(state => ({
    programData: state.program.programs.data ?? [],
    teacherData: state.teacher.teachers.data ?? [],
    subjectData: state.subject.subjects.data ?? [],
    lastPage: state.teacher.teachers.last_page,
    loader: state.teacher.loading,
  }));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [program_ids, setProgramIds] = useState([]);
  const [leave_approval_program_ids, setLeaveApprovalProgramIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const addProgram = (e) => {
    setProgramIds([...e]);
  };
  const removeProgram = (e) => {
    setProgramIds([...e]);
  };

  const addApproverProgram = (e) => {
    setLeaveApprovalProgramIds([...e]);
  };
  const removeApproverProgram = (e) => {
    setLeaveApprovalProgramIds([...e]);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let programIds = program_ids.map((item) => item.id);
    let approverProgramIds = leave_approval_program_ids.map((item) => item.id);
    const data = {
      program_ids: programIds,
      session_id: localStorage.getItem('session_id'),
      teacher_id: id,
      leave_approval_program_ids: approverProgramIds
    };
    try {
      const response = await createAttendanceTeacherMapping(data);
      if (response.status === 200 || response.status === 201) {
        setProgramIds([]);
        setId(null);
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        closeModal();
        dispatch(fetchTeachers(state.name, { selected: currentPage }));
      }
    } catch (error) {
      console.log("API Error:", error.response);
      enqueueSnackbar("Something went wrong", { variant: 'error' });
    }
  };

  const fetchMappedPrograms = async (id) => {
    try {
      setProgramIds([]);
      setLeaveApprovalProgramIds([]);
      setLoading(true);
      const response = await getMappedPrograms(id);
      if (response.status === 200) {
        let programs = response.data.data.attendances.map((item) => {
          return {
            id: item.program.id,
            name: item.program.name
          };
        });
        setProgramIds(programs);
        let approverPrograms = response.data.data.leave_approver.map((item) => {
          return {
            id: item.program.id,
            name: item.program.name
          };
        });
        setLeaveApprovalProgramIds(approverPrograms);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setLoading(false);
    }
  };

  const showModel = (rowData) => {
    setId(rowData.id);
    fetchMappedPrograms(rowData.id);
    openModal(true);
  }

  useEffect(() => {
    if (!localStorage.getItem("organization_id")) {
      enqueueSnackbar("Select any organization!", { variant: "error" });
    }
    dispatch(fetchTeachers(state.name, { selected: 0 }));
    dispatch(fetchPrograms({ selected: 0 }));
    dispatch(fetchSubjects({ selected: 0 }));
  }, [dispatch, state.name]);

  const getTeacherList = (name, { selected }) => {
    let page = selected + 1;
    setCurrentPage(page - 1);
    dispatch(fetchTeachers(name, { selected: page - 1 }));
  };

  const { permissions, name } = state;

  return (
    <React.Fragment>
      {loader ?
        <Loader /> : (
          <div className="card">
            <div className="card-header">
              <div className="">
                <div className="main-title-page page-title d-flex justify-content-space-between">
                  <div className="">
                    <FaChalkboardTeacher />
                    <span className="mx-2">Attendance Teacher Mapping</span>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Search by name..."
                      onChangeHandler={(e) => {
                        setState(prevState => ({ ...prevState, name: e.target.value }));
                        dispatch(fetchTeachers(e.target.value, 1));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body evaluating-table">
              <Modal isOpen={isModalOpen} onClose={closeModal}>
                {console.log(program_ids, "program_ids main")}
                <AttendanceTeacherMapForm
                  addProgram={addProgram}
                  removeProgram={removeProgram}
                  submitHandler={submitHandler}
                  programData={programData}
                  program_ids={program_ids}
                  loader={loading}
                  addApproverProgram={addApproverProgram}
                  removeApproverProgram={removeApproverProgram}
                  leave_approval_program_ids={leave_approval_program_ids}
                />
              </Modal>
              {teacherData && teacherData.length ? (
                <div>
                  <Table
                    cols={tableConstants(permissions, showModel)}
                    data={teacherData}
                    hoverable={true}
                    striped={true}
                  />
                  <Pagination
                    totalPage={lastPage}
                    currentPage={currentPage}
                    handlePageChange={(i) => getTeacherList(name, i)}
                  ></Pagination>
                </div>
              ) : null}
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

export default AttendanceTeacherMap;
