import {
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ACCESS_ORGANIZATIONS_FAILURE,
  FETCH_ACCESS_ORGANIZATIONS_REQUEST,
  FETCH_ACCESS_ORGANIZATIONS_SUCCESS
} from "./organizationTypes";
import axios from 'axios';
import { ENDPOINTS } from '../../services/endpoints';

export const fetchOrganizationsRequest = () => {
  return {
    type: FETCH_ORGANIZATIONS_REQUEST
  }
}
export const fetchOrganizationsSuccess = organizations => {
  return {
    type: FETCH_ORGANIZATIONS_SUCCESS,
    payload: organizations
  }
}
export const fetchOrganizationsFailure = error => {
  return {
    type: FETCH_ORGANIZATIONS_FAILURE,
    payload: error
  }
}
export const fetchOrganizations = (page = 1) => {
  return (dispatch) => {
    dispatch(fetchOrganizationsRequest)
    axios.get(`/${ENDPOINTS.ORGANIZATION.GET}?page=${page}`)
      .then(response => {
        const organizations = response.data;
        dispatch(fetchOrganizationsSuccess(organizations));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchOrganizationsFailure(errorMsg))
      })
  }
}

export const fetchAccessOrganizationsRequest = () => {
  return {
    type: FETCH_ACCESS_ORGANIZATIONS_REQUEST
  }
}
export const fetchAccessOrganizationsSuccess = organizations => {
  return {
    type: FETCH_ACCESS_ORGANIZATIONS_SUCCESS,
    payload: organizations
  }
}
export const fetchAccessOrganizationsFailure = error => {
  return {
    type: FETCH_ACCESS_ORGANIZATIONS_FAILURE,
    payload: error
  }
}

export const fetchAccessOrganizations = (page = 1) => {
  return (dispatch) => {
    dispatch(fetchOrganizationsRequest)
    axios.get(`${ENDPOINTS.ORGANIZATION.USER_ORGANIZATION}?page=${page}`)
      .then(response => {
        const organizations = response.data;
        dispatch(fetchOrganizationsSuccess(organizations));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchOrganizationsFailure(errorMsg))
      })
  }
}
