export const termOptions = [
  { id: "PT-1", name: "PT-I" },
  { id: "PT-2", name: "PT-II" },
  { id: "PT-3", name: "PT-III" },
  { id: "UT-1", name: "UT-I" },
  { id: "UT-2", name: "UT-II" },
  { id: "UT-3", name: "UT-III" },
  { id: "3", name: "Common Pre-Board" },
  { id: "4", name: "Pre-Board" },
  { id: "1", name: "Term-I" },
  { id: "2", name: "Term-II" }
]

