import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import { tableConstants } from "./AttendanceReportTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import CardHeader from "../../../components/Card/CardHeader";
import AttendanceReportFilter from "./AttendanceReportFilter";
import { getMappedPrograms, getAttendanceReport } from "../../../services/AppManagement/AttendanceService";
import * as XLSX from 'xlsx';
import { enqueueSnackbar } from "notistack";

const AttendanceReport = () => {
  const [data, setData] = useState([]);
  const [program_id, setProgramId] = useState('');
  const [start_date, setStartDate] = useState('');
  const [end_date, setAttendenceDate] = useState('');
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [programData, setProgramData] = useState([]);
  const [report_type, setReportType] = useState('');

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramId(value);
    }
    if (name === 'start_date') {
      setStartDate(value);
    }
    if (name === 'end_date') {
      const selectedDate = new Date(e.target.value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ignore time part
      if ((selectedDate < today) || (selectedDate.toDateString() === today.toDateString())) {
        setErrorMessage('');
        setAttendenceDate(value);
      } else {
        setErrorMessage('The Attendance date cannot be in the future.');
        setAttendenceDate('');
      }
    }
  }

  async function fetchAttendance() {
    const session_id = localStorage.getItem('session_id');
    if (!program_id) {
      enqueueSnackbar('Please select a program.', { variant: 'error' });
      return;
    }
    if (!start_date) {
      enqueueSnackbar('Please select a start date.', { variant: 'error' });
      return;
    }
    if (!end_date) {
      enqueueSnackbar('Please select an end date.', { variant: 'error' });
      return;
    }
    if (start_date > end_date) {
      enqueueSnackbar('Start date cannot be greater than end date.', { variant: 'error' });
      return;
    }
    const data = {
      program_id: program_id,
      session_id: session_id,
      start_date: start_date,
      end_date: end_date,
    };
    try {
      setIsLoader(true);
      const response = await getAttendanceReport(data);
      setIsLoader(false);
      if (response.status === 200) {
        setData(response.data.data);
        return response.data.data;
      }
    }
    catch (error) {
      setIsLoader(false);
      console.error("API Error:", error);
    }
  }

  const generateDateHeaders = (attendanceData) => {
    const dates = new Set();
    attendanceData.forEach(student => {
      if (student.attendance) {
        Object.keys(student.attendance).forEach(date => dates.add(date));
      }
    });
    return Array.from(dates).sort(); // Sort dates for consistent column order
  };

  const generateJsonObject = (attendanceData) => {
    let count = 1;
    const dateHeaders = generateDateHeaders(attendanceData);
    return attendanceData.map(studentData => {
      const studentObject = {
        SNo: count++,
        IDNumber: studentData.student.id_number,
        RollNo: studentData.student.roll_no,
        Name: `${studentData.student.first_name} ${studentData.student.last_name}`,
      };

      dateHeaders.forEach(date => {
        const attendance = studentData.attendance ? studentData.attendance[date] : null;
        let label = "N/A";
        if (attendance && attendance.attendance_label) {
          label = attendance.attendance_label.label_name;
        }
        studentObject[date] = label;
      });
      return studentObject;
    });
  };

  const downloadExcel = async (e) => {
    e.preventDefault();
    try {
      let data = await fetchAttendance();
      if (!data && data.length === 0) {
        enqueueSnackbar("No data found for the selected date range.", { variant: 'error' });
        return;
      }
      else {
        const result = generateJsonObject(data);
        const ws = XLSX.utils.json_to_sheet(result);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `attendance-report.xlsx`);
      }
    }
    catch (error) {
      console.error("API Error:", error);
    }
    if (!data && data.length === 0) {
      enqueueSnackbar("No data found for the selected date range.", { variant: 'error' });
      return;
    }
  };

  const fetchMappedPrograms = async (id) => {
    try {
      const response = await getMappedPrograms(id);
      if (response.status === 200) {
        let programs = response.data.data.attendances.map((item) => {
          return {
            id: item.program.id,
            name: item.program.name
          };
        });
        setProgramData(programs);
      }
    }
    catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    //Set the max attribute to today's date
    const dateField = document.getElementById('end_date');
    const today = new Date().toISOString().split('T')[0];
    dateField.setAttribute('max', today);
    setAttendenceDate(today);
    fetchMappedPrograms(user_id);
  }, []);

  return (
    <div className="card">
      <CardHeader
        title="Attendance Report"
        icon={<FaCalendarAlt />}
        button={permissions.includes("attendance.mark") &&
          <Link className="btn btn-primary" to="/attendance/create">
            Attendance Mark
          </Link>
        }
      />
      <div className="card-body">
        <AttendanceReportFilter
          changeHandler={changeHandler}
          fetchAttendance={fetchAttendance}
          attendanceDownload={downloadExcel}
          programData={programData}
          program_id={program_id}
          end_date={end_date}
          errorMessage={errorMessage}
          setReportType={setReportType}
          report_type={report_type}
        />
        {isLoader ? <Loader /> :
          <div className="row mt-3">
            {data && (
              <Table
                cols={tableConstants(data)}
                data={data}
                hover={true}
              />
            )}
          </div>}
      </div>
    </div>
  );
};
export default AttendanceReport;
