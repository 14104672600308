import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader/Loader';

const ProgramForm = props => {
  return (
    <Form onSubmit={props.submitHandler}>
      {!props.data.disableSubmit ? <Loader /> : null}
      <div className="row">
        <div className="col-md-10">
          <Form.Group>
            <Form.Label>Class Name<i className="text-danger">*</i></Form.Label>
            <Form.Control type="text" name="name" id="name"
              onChange={props.changeHandler}
              placeholder="Enter Class and Division Eg. IV-A"
              defaultValue={props.data.name} required />
          </Form.Group>
        </div>
        <div className="col-md-2">
          <div className="mt-2 mt-4">
            <Button variant="success"
              type="submit"
            >Submit</Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
export default ProgramForm;
