import React, { useState, useEffect, useCallback } from 'react';
import Card from '../../../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import UploadTimeTableForm from './UploadTimeTableForm';
import { createUploadTimeTable, getUploadTimeTableById, updateUploadTimeTable } from '../../../../services/AppManagement/TimeTableService';
import { getPrograms } from '../../../../services/ProgramServices';

const UploadTimeTableManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [programData, setProgramData] = useState([]);
  const [program_ids, setProgramIds] = useState([]);
  const [timetable_file, setTimetableFile] = useState(null);
  const id = params.id;

  const getUniqueTimeTable = useCallback(async () => {
    try {
      const response = await getUploadTimeTableById(id);
      if (response.status === 200) {
        const data = response.data.data;
        setProgramIds([data.programs]);
        setTimetableFile(data.timetable_docs);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getUniqueTimeTable();
    }
  }, [id, getUniqueTimeTable]);

  const classAddHandler = useCallback((e) => {
    setProgramIds([...e]);
  }, []);

  const classRemoveHandler = useCallback((e) => {
    setProgramIds([...e]);
  }, []);

  const fileUploadHandler = (e) => {
    const file = e.target.files[0];
    setTimetableFile(file);
  };

  useEffect(() => {
    async function fetchPrograms() {
      try {
        const response = await getPrograms();
        if (response.success) {
          setProgramData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPrograms();
    if (id) {
      getUniqueTimeTable();
    }
  }, [getUniqueTimeTable, id]);

  const submitHandler = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');
    try {
      const formData = new FormData();
      formData.append('timetable_file', timetable_file);
      formData.append('session_id', session_id);
      program_ids.forEach((program, idx) => {
        formData.append(`program_ids[${idx}]`, program.id);
      });

      const response = id ? await updateUploadTimeTable(id, formData)
        : await createUploadTimeTable(formData);

      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        navigate('/upload/timetable/list');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const back = useCallback(() => {
    navigate('/upload/timetable/list');
  }, [navigate]);

  return (
    <Card componentName={id ? 'Update Upload Time Table' : 'Add Upload Time Table'} back={back}>
      <UploadTimeTableForm
        submitHandler={submitHandler}
        classAddHandler={classAddHandler}
        classRemoveHandler={classRemoveHandler}
        id={id}
        programData={programData}
        program_ids={program_ids}
        timetable_file={timetable_file}
        fileUploadHandler={fileUploadHandler}
      />
    </Card>
  );
};
export default UploadTimeTableManage;
