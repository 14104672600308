import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getUsers = async () => {
  try {
    const response = await axios.get(ENDPOINTS.USER.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getUserById = async (id, session_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.USER.GET_USER}/${id}`, { params: { session_id } });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.USER.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.USER.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.USER.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateUserStatus = async (id, status) => {
  try {
    const response = await axios.put(`${ENDPOINTS.USER.STATUS}/${id}`, { status });
    return response;
  } catch (error) {
    throw error;
  }
};

export const usersBulkUpload = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.USER.BULK_UPLOAD, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updatePassword = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.USER.UPDATE_PASSWORD}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
