import React, { useEffect, useState } from 'react';
import { getPrograms } from '../../../services/ProgramServices';
import CardHeader from '../../../components/Card/CardHeader';
import AttendanceChart from './AttendanceChart';
import { getProgramWiseAttendanceCount } from '../../../services/AppManagement/AttendanceService';
import { getActivatedInActivatedParentAppUserCount } from '../../../services/StudentService';
import DashboardCustomCard from '../../dashboard/DashboardCustomCard';

const AttendanceDashboard = () => {
  const session_id = localStorage.getItem('session_id');
  const date = new Date().toISOString().split('T')[0];
  const [parentUsers, setParentAppUsers] = useState(0);
  const [data, setData] = useState([]);

  const fetchPrograms = async () => {
    const response = await getPrograms();
    const program_ids = response.data && response.data.map((program) => program.id);
    getData(program_ids);
  }
  const getData = async (program_ids) => {
    try {
      const response = await getProgramWiseAttendanceCount(program_ids, session_id, date);
      if (response.status === 200) {
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error(error);
    }
  }
  const fetchParentAppUsers = async () => {
    try {
      const response = await getActivatedInActivatedParentAppUserCount(session_id);
      console.log("parent app",response);
      if (response.status === 200) {
        setParentAppUsers(response.data.data);
      }
    }
    catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchPrograms();
    fetchParentAppUsers();
  }, []);

  return (
    <div className='card'>
      <CardHeader
        title='Attendance Dashboard'
        icon={<i className='fa fa-calendar-check-o'></i>}
        button={''}
      />
      <div className='card-body'>
        <div className="row mb-4">
          <DashboardCustomCard
            title="Parent App Users"
            cardColor="bg_success_gradient"
            iconColor="clr3"
            icon="fa-solid fa-user-graduate"
            count={parentUsers.activated} />
          <DashboardCustomCard
            title="Parent App Not Using"
            cardColor="bg_primary_gradient"
            icon="fa-solid fa-user-tie"
            iconColor="clr2"
            count={parentUsers.inactivated} />
        </div>
        <h6>Today's attendance count</h6>
        <AttendanceChart data={data} />
      </div>
    </div>
  );
};

export default AttendanceDashboard;
