import {
  FETCH_SESSIONS_FAILURE,
  FETCH_SESSIONS_REQUEST,
  FETCH_SESSIONS_SUCCESS
} from "./sessionTypes";
import axios from "axios";
import { ENDPOINTS } from "../../services/endpoints";

export const fetchSessionsRequest = () => ({
  type: FETCH_SESSIONS_REQUEST
});

export const fetchSessionsSuccess = (sessions) => ({
  type: FETCH_SESSIONS_SUCCESS,
  payload: sessions
});

export const fetchSessionsFailure = (error) => ({
  type: FETCH_SESSIONS_FAILURE,
  payload: error
});

export const fetchSessions = (page = 1) => async (dispatch) => {
  dispatch(fetchSessionsRequest());
  try {
    const response = await axios.get(`/${ENDPOINTS.SESSION.GET}?page=${page}`);
    const sessions = response.data;
    dispatch(fetchSessionsSuccess(sessions));
  } catch (error) {
    const errorMsg = error.message;
    dispatch(fetchSessionsFailure(errorMsg));
  }
};
