import React from 'react';

const Loader = () => {
  return (
    <div>
      <div className={`d-flex flex-column align-items-center justify-content-center vh-10`}>
        <div className="spinner-border text-primary">
        </div>
      </div>
      <div className='col-md-12 d-flex flex-column align-items-center justify-content-center'>
        <p className="mt-3">Please wait, content is loading...</p>
      </div>
    </div>
  );
};

export default Loader;
