import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCloudSunRain } from "react-icons/fa";
import { tableConstants } from "./SyllabusTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";

const SyllabusList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  async function fetchSyllabus({ selected = 0 }) {
    const session_id = localStorage.getItem('session_id');
    try {
      setIsLoader(true);
      setCurrentPage(selected);
      const page = selected + 1;
      const response = await axios.get(`/syllabus?session_id=${session_id}&page=${page}`);
      setIsLoader(false);
      if (response.status === 200) {
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      setIsLoader(false);
      console.error("API Error:", error.response);
    }
  }

  useEffect(() => {
    fetchSyllabus({ selcted: 0 });
  }, []);

  const syllabusDelete = async (id) => {
    try {
      const response = await axios.delete(`/syllabus/${id}`);
      console.log(response,"response delete syllabus");
      if (response.status === 200) {
        enqueueSnackbar("Syllabus Deleted Successfully", { variant: "success" });
        fetchSyllabus({ selected: 0 });
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  }

  return (
    <div className="card">
      <CardHeader
        title="Syllabus List"
        icon={<FaCloudSunRain />}
        button={<Link to="/syllabus/create"
          className="btn btn-primary">Add Syllabus</Link>}
      />
      <div className="card-body">
        {isLoader ? <Loader /> :
          <div className="row mt-3">
            {data && (
              <React.Fragment>
                <Table
                  cols={tableConstants(permissions, syllabusDelete)}
                  data={data}
                  hover={true}
                />
                <Pagination
                  totalPage={lastPage}
                  currentPage={currentPage}
                  handlePageChange={(i) => fetchSyllabus(i)}
                ></Pagination>
              </React.Fragment>
            )}
          </div>}
      </div>
    </div>
  );
};
export default SyllabusList;
