import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchPrograms } from '../../redux/program/programActions';
import { fetchEvaluationTypes } from '../../redux/evaluationType/evaluationTypeActions';
import { fetchTraits } from '../../redux/trait/traitActions';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Multiselect } from "multiselect-react-dropdown";
import Select from 'react-select';
import axios from 'axios';
import { termOptions } from '../../globalConstants';
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';
import CustomSelect from '../../components/Select/Select';
import Input from '../../components/Input/Input';


const ExamForm = props => {

  const [programList, setProgramList] = useState([]);
  const session_id = localStorage.getItem('session_id');
  const role_name = JSON.parse(localStorage.getItem("roles"))[0].name;
  const { fetchPrograms, fetchEvaluationTypes, fetchTraits } = props;

  const getProgramList = async () => {
    try {
      const response = await axios.get('/class_in_charge/classes', {
        headers: { 'session-id': session_id },
      });
      if (response.status === 200) {
        setProgramList(response.data.data);
      }
    } catch (error) {
      console.error('Error', error);
    }
  }

  useEffect(() => {
    /***            Get Program   *** */
    fetchPrograms();
    getProgramList();
    /***            Get Evalauation Type  *** */
    fetchEvaluationTypes();
    // get trait
    fetchTraits();
  }, []);

  return (
    <Form onSubmit={props.submitHandler} onChange={props.changeHandler}>
      <div className="row">
        <div className="col-md-3">
          <CustomSelect
            label="Class"
            name="program_id"
            value={props.program_id}
            placeHolder={"Select Class"}
            onChangeHandler={props.changeHandler}
            options={(role_name === "SUPER ADMIN" || role_name === "SCHOOL SPOC" || role_name === "ADMIN") ? props.programData : programList}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            label="Assessment Name"
            name="name"
            value={props.data.name}
            placeHolder={"Select Name"}
            onChangeHandler={props.changeHandler}
            options={props.data.examList}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Evaluation Period Start"
            type="date"
            name="start"
            value={props.data.start}
            onChangeHandler={props.changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <Input
            label="Evaluation Period End"
            type="date"
            name="end"
            value={props.data.end}
            onChangeHandler={props.changeHandler}
            required={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          {props.data.id ?
            <CustomSelect
              label="Subject"
              name="subject_id"
              value={props.data.subject_id}
              placeHolder={"Select Subject"}
              onChangeHandler={props.changeHandler}
              options={props.subjectData}
              required={true}
            /> :
            <Form.Group>
              <Form.Label>Subject</Form.Label>
              <Multiselect
                options={props.subjectData} // Options to display in the dropdown
                onSelect={props.onSubjectSelect} // Function will trigger on select event
                selectedValues={props.selectedSubjects}
                onRemove={props.onSubjectRemove} // Function will trigger on remove event
                showCheckbox={true}
                singleSelect={props.studentSubjectWise ? true : false}
                id="program_ids"
                displayValue="name" // Property name to display in the dropdown options
              />
            </Form.Group>}
          <Form.Label htmlFor='is_active'>IsActive:&nbsp;&nbsp; </Form.Label>
          <input type="checkbox"
            onChange={props.changeHandler}
            name="is_active" id="is_active"
            value={props.data.is_active || ''}
            checked={props.data.is_active}
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Label htmlFor='is_traits'>Traits:&nbsp;&nbsp; </Form.Label>
          <input type="checkbox"
            onChange={props.changeHandler}
            name="is_traits" id="is_traits"
            value={props.data.is_traits || ''}
            checked={props.data.is_traits}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            label="Term"
            name="term"
            value={props.data.term}
            placeHolder={"Select Term"}
            onChangeHandler={props.changeHandler}
            options={termOptions}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            label="Evaluation Type"
            name="evaluation_type_id"
            value={props.data.evaluation_type_id}
            placeHolder={"Select Evaluation Type"}
            onChangeHandler={props.changeHandler}
            options={props.evaluationType}
            required={true}
          />
        </div>
        <div className="col-md-3">
          <CustomSelect
            label="Grade Schemes"
            name="gradeSchemeID"
            value={props.gradeSchemeID}
            placeHolder={"Select Grade Schemes"}
            onChangeHandler={props.changeHandler}
            options={props.scoreSchemesData}
            required={true}
          />
        </div>
      </div>
      <div className="row">
        {props.data.is_traits ?
          <div className="col-md-12">
            {
              props.data.traits.map((val, idx) => {
                return (
                  <div className="row mt-2" key={idx}>
                    <div className="col-md-5">
                      <Form.Group>
                        <Select
                          value={val.trait_id === undefined ? { label: 'Choose trait', value: "" } : { label: val.label, value: val.trait_id }}
                          onChange={(option) => props.changeTraitHandler({ target: { value: option.value, name: 'trait_id', label: option.label } }, idx)}
                          options={props.traitData && props.traitData.map((trait, idx) => ({ label: trait.name, value: trait.id }))}
                          placeholder="Choose Trait"
                        />
                      </Form.Group>
                    </div>
                    {/* <div className="col-md-3">
                      <CustomSelect
                        name="grade_scheme_id"
                        value={val.grade_scheme_id === undefined ? "" : val.grade_scheme_id}
                        placeHolder={"Select Grade Scheme"}
                        onChangeHandler={(e) => props.handleChangeTraitsScheme(e, idx)}
                        options={props.scoreSchemesData}
                        required={true}
                      />
                    </div>*/}
                    {props.data.traits[idx].trait_grade_scheme_type === 'Score' ?
                      <div className="col-md-5">
                        <Input
                          name={"max_score"}
                          id={"trait_max_score"}
                          placeHolder={"Max Score"}
                          value={props.data.traits[idx].max_score || ''}
                          type="number"
                          onChange={(e) => props.changeTraitHandler(e, idx)}
                          required={true}
                        />
                      </div> : null}
                    <div className="col-md-2">
                      {
                        idx === 0 ? <button onClick={props.addRow} type="button"
                          className="btn btn-primary text-center">
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </button>
                          : <button className="btn btn-danger"
                            onClick={(e) => props.clickOnDelete(e, val)} >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                      }
                    </div>
                  </div>)
              })}
          </div> : null
        }
      </div>
      <div className="row">
        {props.data.attempts === "YES" ? <React.Fragment>
          <div className="col-md-3">
            <Input
              label="No of Attempts"
              type="number"
              name="total_attempts"
              value={props.data.total_attempts}
              onChangeHandler={props.changeHandler}
              required={true}
            />
          </div>
          <div className="col-md-3">
            <CustomSelect
              label="Choose Grading Method"
              name="grade_method"
              value={props.data.grade_method}
              placeHolder={"Select Graging Method"}
              onChangeHandler={props.changeHandler}
              options={props.data.gradingMethodsData}
              required={true}
            />
          </div></React.Fragment> : null}
        <div className="col-md-3">
          {props.grade_scheme_type === 'Score' ?
            <Input
              label="Max Score"
              type="number"
              name="maxScore"
              value={props.data.maxScore}
              onChangeHandler={props.changeHandler}
              required={true}
            />
            : null}
        </div>
      </div>
      <Form.Group>
        <Form.Label>Description</Form.Label>
        <Form.Control as="textarea" name="description" id="description"
          className="form-control daterange mb-2"
          type="text" placeholder="Enter description"
          value={props.data.description || ''}
          onChange={props.changeHandler} row={3} />
      </Form.Group>
      {/* TODO all records fetch from API and when update checkbox field already selected */}
      <Switch name="studentSubjectWise" id="studentSubjectWise"
        checked={props.studentSubjectWise}
        onChange={props.changeStudentSubjectWise}
        label={'Get student subject wise'} />

      <div className="row mt-2">
        <div className="col-md-12">
          {
            props.data.student_loader ?
              <Loader />
              :
              <Form.Group>
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th>SrNo</th>
                      <th>Roll No</th>
                      <th>Admission No</th>
                      <th>Name</th>
                      <th>
                        <Switch
                          checked={(props.student_ids.length ===
                            (props.data.studentList ? props.data.studentList.length : null))
                            && props.data.studentList.length > 0 ? true : false}
                          name="all"
                          id="select-all"
                          value="1"
                          onChange={e => props.selectAllStudent(e)}
                          label={'Select All'}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="col-md-12">
                    {
                      props.data.studentList && props.data.studentList.map((student, index) =>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{student.roll_no}</td>
                          <td>{student.id_number}</td>
                          <td>{student.first_name + " " + student.last_name}</td>
                          <td>
                            <div className="form-check form-check-inline">
                              {props.student_ids.length &&
                                props.student_ids.indexOf(student.id.toString()) !== -1 ?
                                <Switch
                                  name="student_id"
                                  id={student.id}
                                  checked={props.student_ids.indexOf(student.id.toString()) !== -1 ? true : false}
                                  value={student.id || ''}
                                  onChange={props.inputChangeHandler}
                                /> :
                                <Switch
                                  name="student_id"
                                  id={student.id}
                                  value={student.id || ''}
                                  onChange={props.inputChangeHandler}
                                />
                              }
                            </div>
                          </td>
                        </tr>)}
                    {props.data.studentList.length === 0 ?
                      <tr align="center"><th colSpan="3">No Record Found</th></tr> : null}
                  </tbody>
                </table>
              </Form.Group>}
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end">
        <Button variant="success" className='mt-2'
          type="submit">Submit</Button>
      </div>
    </Form>
  );
};
const mapdataToprops = data => {
  return {
    programData: data.program.programs.data,
    evaluationType: data.evaluationType.evaluationTypes.data,
    traitData: data.trait.traits.data,
  }
}
const mapDispatchToprops = dispatch => {
  return {
    fetchPrograms: () => dispatch(fetchPrograms()),
    fetchEvaluationTypes: () => dispatch(fetchEvaluationTypes()),
    fetchTraits: () => dispatch(fetchTraits()),
  }
}

export default connect(mapdataToprops, mapDispatchToprops)(ExamForm);

