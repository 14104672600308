export const ENDPOINTS = {
  DASHBOARD: {
    GET_STUDENT_COUNT: 'dashboard/student',
    GET_TEACHER_COUNT: 'dashboard/teacher',
    GET_USER_COUNT: 'dashboard/user',
    GET_CLASS_WISE_STUDENT_COUNT: 'class-wise/students',

  },
  EVENT_CALENDAR: {
    GET: 'event-calendar',
    GET_EVENT_CALENDAR: 'event-calendar',
    CREATE: 'event-calendar',
    UPDATE: 'event-calendar',
    DELETE: 'event-calendar',
  },
  ORGANIZATION: {
    GET: 'organizations',
    GET_ORGANIZATION: 'organizations',
    CREATE: 'organizations',
    UPDATE: 'organizations',
    DELETE: 'organizations',
    USER_ORGANIZATION: 'user/organizations',
  },
  SESSION: {
    GET: 'sessions',
    GET_SESSION: 'sessions',
    CREATE: 'sessions',
    UPDATE: 'sessions',
    DELETE: 'sessions',
  },
  PROGRAM: {
    GET: 'programs',
    GET_PROGRAM: 'programs',
    CREATE: 'programs',
    UPDATE: 'programs',
    DELETE: 'programs',
  },
  SUBJECT: {
    GET: 'subjects',
    GET_SUBJECT: 'subjects',
    CREATE: 'subjects',
    UPDATE: 'subjects',
    DELETE: 'subjects',
    GET_CLASS_WISE_SUBJECTS: 'programs-wise/subjects',
  },
  TRAIT: {
    GET: 'subject/traits',
    GET_TRAIT: 'subject/traits',
    CREATE: 'subject/traits',
    UPDATE: 'subject/traits',
    DELETE: 'subject/traits',
  },
  STUDENT: {
    GET: 'student/list',
    GET_STUDENT: 'students',
    GET_FILTERED_STUDENTS: 'filter/student/list',
    GET_CLASS_WISE_STUDENT_LIST: 'class-wise/student/list',
    CREATE: 'students',
    UPDATE: 'student/update',
    DELETE: 'students',
    STATUS: 'student/status-update',
    BULK_CREATE: '/students/bulk-upload',
    BULK_UPDATE: '/students/update/bulk-upload',
    PROMOTE_STUDENTS: '/promote/student',
    MANAGED_PROMOTED_STUDENTS: '/update/promoted/students',
    BULK_PROMOTE: '/students/bulk-permote',
    GENRATE_ACTIVATION_KEY: '/students/generate-activation-key',
    PARENT_APP_ACTIVATED_INACTIVATED_USER_COUNT: '/parent-app/activated-inactivated-user/count',
  },
  STUDENT_SUBJECT_MAPPING: {
    GET: 'student-subject-mapping',
    GET_STUDENT_SUBJECT_MAPPING: 'student-subject-mapping',
    CREATE: 'student-subject-mapping',
    UPDATE: 'student-subject-mapping/update',
    DELETE: 'student-subject-mapping',
  },
  TEACHER: {
    GET: 'teacher/list',
    GET_TEACHER: 'teachers',
    GET_TEACHER_SUBJECT_MAPPING: '/users/subjects',
    CREATE_TEACHER_SUBJECT_MAPPING: '/users/subjects',
    UPDATE_TEACHER_SUBJECT_MAPPING: '/update/user/subjects',
    UPDATE_PASSWORD: '/users/reset-password',
    BULK_CREATE: '/teachers/bulk-upload',
    BULK_UPDATE: '/teachers/update/bulk-upload',
    SUBJECT_MAPPING_CREATE: '/teacher/subject-mapping',
    SUBJECT_MAPPING_UPDATE: '/teacher/subject-mapping/update',
    TEACHER_SUBJECT_MAPPING_BULK_UPLOAD: '/user/class/subject_bulk-upload',
  },
  EXAM: {
    GET: 'events',
    GET_FILTERED: 'events',
    GET_EXAM: 'events',
    CREATE: 'events',
    UPDATE: 'events',
    DELETE: 'events',
    GET_PROGRAM_WISE_SUBJECTS: 'class/subjects',
    GET_PROGRAM_WISE_EXAM: 'program/exams',
    STATUS_UPDATE: 'event/active-in-active',
  },
  ASSESSMENT_MARKING: {
    GET_ASSESSMENT_PARTICIPANTS: 'event/participants',
    CREATE: 'event/marking',
    UPDATE: 'update/event/marking',
  },
  REPORT_CARD: {
    VIEW: 'reportcard',
    DOWNLOAD: 'download-reportcard',
    EXCEL_RESULT: 'export-result',
    PUBLISH_RESULT_LIST: 'publish/result',
    PUBLISH_RESULT: 'publish/result',
    UPDATE_PUBLISH_RESULT: 'publish/result',
    GET_PUBLISHED_RESULT: 'publish/result',
  },
  TEMPLATE: {
    GET: 'templates',
    GET_TEMPLATE: 'templates',
    CREATE: 'templates',
    UPDATE: 'templates',
    DELETE: 'templates',
  },
  ASSESSMENT_LIST: {
    GET: 'exams',
    GET_ASSESSMENT_LIST: 'exams',
    CREATE: 'exams',
    UPDATE: 'exams',
    DELETE: 'exams',
  },
  SCORE_SCHEME: {
    GET: 'score/schemes',
    GET_SCORE_SCHEME: 'score/schemes',
    CREATE: 'score/schemes',
    UPDATE: 'score/schemes',
    DELETE: 'score/schemes',
    GET_BY_EVALUATION_TYPE: 'evaluation-wise/score-schemes',
  },
  EVALUATION_TYPE: {
    GET: 'evaluation_types',
    GET_EVALUATION_TYPE: 'evaluation_types',
    CREATE: 'evaluation_types',
    UPDATE: 'evaluation_types',
    DELETE: 'evaluation_types',
  },
  USER: {
    GET: 'users',
    GET_USER: 'users',
    CREATE: 'users',
    UPDATE: 'users',
    DELETE: 'users',
    STATUS: 'users/status',
    BULK_UPLOAD: 'user/bulk-upload',
    UPDATE_PASSWORD: 'users/update-password',
  },
  ROLE_PERMISSION: {
    GET: 'roles',
    GET_ROLE_PERMISSION: 'role/permissions',
    CREATE: 'role/permission',
    UPDATE: 'update/role/permissions',
    DELETE: 'roles',
    GET_PERMISSION: 'permissions',
    GET_PERMISSION_BY_USER: 'permissions/by-user',
  },
  //for App Management
  ATTENDANCE: {
    GET: 'attendance',
    CREATE: 'attendance',
    UPDATE: 'attendance/update',
    DELETE: 'attendance',
    GET_ATTENDANCE_REPORT: 'get/attendance/date-range',
    GET_MAPPED_PROGRAMS: 'attendance-teacher-mappings',
    CREATE_ATTENDANCE_TEACHER_MAPPING: 'attendance-teacher-mappings',
    GET_ATTENDANCE_LABEL_LIST: 'attendance/label/list',
    BULK_UPLOAD: 'attendance-upload',
    GET_PROGRAM_WISE_ATTENDANCE_COUNT: 'program-wise/attendance-count'
  },
  CIRCULAR: {
    GET: 'circular',
    CREATE: 'circular',
    UPDATE: 'circular/update',
    DELETE: 'circular',
  },
  LEAVE: {
    GET: 'leave',
    GET_LEAVE: 'leave',
    CREATE: 'leave',
    UPDATE: 'leave/update',
    DELETE: 'leave',
    LEAVE_APPROVAL: 'leave-approval',
  },
  EVENT_CALENDAR: {
    GET: 'event-calendar',
    CREATE: 'event-calendar',
    UPDATE: 'event-calendar',
    DELETE: 'event-calendar',
  },
  WORD_OF_THE_DAY: {
    GET: 'word_of_the_day',
    GET_WORD_OF_THE_DAY: 'word_of_the_day',
    CREATE: 'word_of_the_day',
    UPDATE: 'word_of_the_day',
    DELETE: 'word_of_the_day',
    BULK_UPLOAD: 'word_of_the_day/bulk-upload',
  },
  TIME_TABLE: {
    GET: 'time-table',
    GET_TIME_TABLE: 'time-table',
    CREATE: 'time-table',
    UPDATE: 'time-table',
    DELETE: 'time-table',
  },
  UPLOAD_TIME_TABLE: {
    GET: 'upload-timetable',
    GET_UPLOAD_TIME_TABLE: 'upload-timetable',
    CREATE: 'upload-timetable',
    UPDATE: 'upload-timetable/update',
    DELETE: 'upload-timetable',
  },
  SYLLABUS: {
    GET: 'syllabus',
    GET_SYLLABUS: 'syllabus',
    CREATE: 'syllabus',
    UPDATE: 'syllabus/update',
    DELETE: 'syllabus',
  },
  NOTIFICATION: {
    GET: 'notification',
    GET_NOTIFICATION: 'notification',
    CREATE: 'notification',
    UPDATE: 'notification',
    DELETE: 'notification',
  },
  NEWS_LETTER: {
    GET: 'newsletter',
    GET_NEWS_LETTER: 'newsletter',
    CREATE: 'newsletter',
    UPDATE: 'newsletter/update',
    DELETE: 'newsletter',
  },
  FAQ: {
    GET: 'faq',
    GET_FAQ: 'faq',
    CREATE: 'faq',
    UPDATE: 'faq',
    DELETE: 'faq',
  },

}
