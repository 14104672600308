import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { FaFolder } from "react-icons/fa";
import { Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import Loader from "../../components/Loader/Loader";
import { tableConstants } from "./publishResultTableConstant";
import { getPublishResultList } from "../../services/ReportCardService";

function PublishResultList() {
  const [publishResultList, setPublishResultList] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const dispatch = useDispatch();

  const fetchPublishResultList = async () => {
    let session_id = localStorage.getItem("session_id");
    setLoading(true);

    try {
      const response = await getPublishResultList(session_id);
      if (response.status === 200) {
        const publishResultList = response.data.data;
        setPublishResultList(publishResultList);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPublishResultList();
    dispatch(fetchPrograms());
  }, [fetchPrograms]);


  return (
    <div className="program-list">
      <div className="animated">
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title-page page-title d-flex align-items-center">
                <div className="mx-2">
                  <FaFolder />
                </div>
                <span>Published Report Card</span>
              </div>
              <div>
                <Link to="/publish/result/manage"
                  className="btn btn-primary ml-auto">Publish Report Card</Link>
              </div>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) :
            <div className="card-body evaluating-table">
              {publishResultList && publishResultList.length > 0 ?
                <Table
                  cols={tableConstants(permissions)}
                  data={publishResultList}
                  hoverable={true}
                  striped={true}
                /> :
                <div align="center">
                  <span>No Record Found</span>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}
export default PublishResultList;
