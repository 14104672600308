import React, { useState, useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import axios from 'axios';
import { Checkmark } from 'react-checkmark';
import AssessmentInfo from '../AssessmentMarking/AssessmentInfo';
import Loader from '../../components/Loader/Loader';
import { useParams, useNavigate } from "react-router-dom";
import TraitApproval from './TraitApproval';


const MarkingApprovalManage = () => {
  const [eventInfo, setEventInfo] = useState([]);
  const [gradeSchemeId, setGradeSchemeId] = useState('');
  const [event_marking, setEventMarking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [is_traits, setIsTraits] = useState(0);
  const [scoreScheme, setScoreScheme] = useState([]);
  const [trait_marking, setTraitMarking] = useState([]);
  const [id, setId] = useState('');
  const params = useParams();
  const navigate = useNavigate();

  const fetchData = async (flag) => {
    try {
      const response = await axios.get(`/event/${params.id}/participants`);
      setId(params.id);

      if (response.status === 200) {
        const eventData = response.data.data;
        const gradeScheme = eventData.grade_scheme;
        const traits = eventData.traits;

        setEventInfo(eventData);
        setGradeSchemeId(gradeScheme.id);
        setIsTraits(eventData.is_traits);
        setScoreScheme(gradeScheme.ScoreOptions);

        if (flag) {
          eventData.event_participants.forEach((student) => {
            traits.forEach((traitMark, idx) => {
              setTraitMarking((trait_marking) => [...trait_marking, {
                trait_id: traitMark.subject_trait ? traitMark.subject_trait.id : '',
                event_marking_id: student.marks_data[idx] ? student.marks_data[idx].id : '',
                grade_scheme_id: traitMark.grade_scheme.id,
                student_id: student.id,
                is_approved: 0,
              }]);
            });
            setEventMarking((event_marking) => [...event_marking, {
              event_marking_id: student.marks_data[0] ? student.marks_data[0].id : '',
              student_id: student.id,
              is_approved: 0,
            }]);
          })
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [params.id]);

  const handleSubmit = (event, curr_student, index) => {
    event.preventDefault();
    let updated_trait_marking = [];

    if (is_traits) {
      updated_trait_marking = trait_marking.map((mark) => {
        if (mark.student_id == curr_student.id) {
          mark.is_approved = 1;
          return mark
        }
        return mark;
      });
    }
    else {
      event_marking[index].is_approved = 1;
    }

    let idx = 0;
    let traits = [];
    updated_trait_marking.forEach(student => {
      if (student.student_id === curr_student.id) {
        traits[idx++] = student;
        return;
      }
    });

    let Object = localStorage.getItem('user');
    let user = JSON.parse(Object);
    const user_id = user.id;
    const event_id = id;

    const data = {
      user_id,
      event_id,
      is_traits,
      traits,
      event_marking: is_traits ? [] : [event_marking[index]],
    };
    axios.post(`/marking/approved`, { data })
      .then(response => {
        if (response.status === 200) {
          // toast.success(response.data.message.success);
          fetchData();
        }
      }).catch(error => {
        return error;
      })
  }
  const allApproveHandler = (event) => {
    event.preventDefault();
    let updated_trait_marking = [];
    let updated_event_marking = [];

    if (is_traits) {
      updated_trait_marking = trait_marking.map((mark) => {
        mark.is_approved = 1;
        return mark
      });
    }
    else {
      updated_event_marking = event_marking.map((mark) => {
        mark.is_approved = 1;
        return mark
      });
    }

    let idx = 0;
    let traits = [];
    updated_trait_marking.forEach(student => {
      traits[idx++] = student;
    });

    let Object = localStorage.getItem('user');
    let user = JSON.parse(Object);
    const user_id = user.id;
    const event_id = id;

    const data = {
      user_id,
      event_id,
      is_traits,
      traits,
      event_marking: is_traits ? [] : updated_event_marking,
    };
    axios.post(`/marking/approved`, { data })
      .then(response => {
        if (response.status === 200) {
          // toast.success(response.data.message.success);
          fetchData();
        }
      }).catch(error => {
        return error;
      })
  }

  const handleBack = () => {
    navigate('/marking/approval/list');
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <AssessmentInfo eventInfo={eventInfo} back={handleBack} />
          <div className="animated">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="">
                    <strong>Students</strong>
                  </div>
                  <div className=""></div>
                </div>
              </div>
              <div className="card-body" style={{ overflow: "auto" }}>
                <form>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>RollNo</th>
                        <th>Admission Number</th>
                        <th>Name</th>
                        {is_traits ? (
                          <React.Fragment>
                            {eventInfo.traits.map((student, traitIdx) => (
                              <React.Fragment key={traitIdx}>
                                <th>{student.subject_trait.name}</th>
                                <th>Remark</th>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <th>Marking</th>
                            <th>Remark</th>
                          </React.Fragment>
                        )}
                        <th style={{ textAlign: "center" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventInfo.event_participants.map(
                        (student, studentIndex) => (
                          <tr key={studentIndex}>
                            <td> {student.roll_no}</td>
                            <td>{student.id_number}</td>
                            <td>{`${student.first_name} ${student.last_name}`}</td>

                            {is_traits ? (
                              <TraitApproval
                                traits={eventInfo.traits}
                                student={student}
                                eventInfo={eventInfo}
                              />
                            ) : (
                              <React.Fragment>
                                {student.marks_data[0] !== undefined ? (
                                  <React.Fragment>
                                    <td>
                                      {student.marks_data[0].option_value}
                                    </td>
                                    <td>{student.marks_data[0].remark}</td>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>
                                    <td>-</td>
                                    <td>-</td>
                                  </React.Fragment>
                                )}
                              </React.Fragment>
                            )}
                            <td>
                              {student.marks_data[0] && (
                                <React.Fragment>
                                  {student.marks_data[0] &&
                                    student.marks_data[0].is_approved ? (
                                    <Checkmark size="25px" />
                                  ) : (
                                    <Button
                                      type="submit"
                                      style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block",
                                      }}
                                      variant="success"
                                      onClick={(event) =>
                                        handleSubmit(
                                          event,
                                          student,
                                          studentIndex
                                        )
                                      }
                                    >
                                      Approve
                                    </Button>
                                  )}
                                </React.Fragment>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <Button type="submit" variant="success"
                    className='save-button float-right'
                    onClick={event => allApproveHandler(event)}>
                    Approve all</Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MarkingApprovalManage;

