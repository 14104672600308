import React from "react";
import { faEdit} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (permissions) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Role Name',
      render: rowData => {
        return <span>{rowData.name}</span>;
      },
    },
  ];

  if (permissions.includes("role.update")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("role.update") &&
              <Link to={`/role/permission/update/${rowData.id}`}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    color="#321fdb"
                    style={{
                      marginRight: "10px",
                      cursor: "pointer"
                    }}
                  />
              </Link>}
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
