import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTraits } from "../../redux/trait/traitActions";
import { Link } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import ListCard from "../../components/Card/ListCard";
import CardHeader from "../../components/Card/CardHeader";
import { deleteTrait } from "../../services/TraitService";

const TraitList = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"))
  const dispatch = useDispatch();
  const traitData = useSelector((state) => state.trait.traits.data);
  const lastPage = useSelector((state) => state.trait.traits.last_page);

  useEffect(() => {
    dispatch(fetchTraits());
  }, [dispatch]);

  const deleteHandler = async (id) => {
    try {
      const response = await deleteTrait(id);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        dispatch(fetchTraits());
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="card">
      {!traitData ? (
        <Loader />
      ) : (
        <div className="traits-list">
          <CardHeader
            title="Co-Scholastic Subjects"
            icon={<FaClipboardList />}
            permissions={permissions}
            button={
              permissions.includes("traits.create") && (
                <Link className="btn btn-primary" to="/trait/create">
                  Add
                </Link>
              )
            }
          />
          <div className="card-body row">
            {traitData && traitData.map((item, idx) => (
              <ListCard
                key={idx}
                permissions={permissions}
                updatePermission="traits.update"
                deletePermission="traits.delete"
                updateUrl={`/trait/update/${item.id}`}
                icon="fa-solid fa-clipboard-list"
                item={item}
                onDelete={() => deleteHandler(item.id)}
              />
            ))}
          </div>
          <div className="card-body evaluating-table">
            {traitData.length ? (
              <Pagination
                totalPage={lastPage}
                handlePageChange={(i) => dispatch(fetchTraits(i))}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default TraitList;
