import React from 'react';
import { Form, Button } from 'react-bootstrap';

const StudentSubjectFilter = ({ changeHandler, fetchData, programData, program_id, subjectList, subject_id }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        <Form.Group>
          <select onChange={(e) => changeHandler(e)}
            value={program_id}
            name="program_id" id="program_id"
            className="form-select form-control cursor-pointer"
            data-placeholder="Select Program">
            <option value=''>Select Class</option>
            {
              programData && programData.map((program, idx) =>
                <option value={program.id} key={idx}>{program.name}</option>)
            }
          </select>
        </Form.Group>
      </div>
      <div className="col-md-5">
        <Form.Group>
          <select onChange={(e) => changeHandler(e)}
            value={subject_id || ''}
            name="subject_id" id="subject_id"
            className="form-select form-control cursor-pointer"
            data-placeholder="Select Subject">
            <option value=''>Select Subject</option>
            {
              subjectList && subjectList.map((subject, idx) =>
                <option value={subject.id} key={idx}>{subject.name}</option>)
            }
          </select>
        </Form.Group>
      </div>
      <div className="col-md-1">
        <Button
          variant="primary"
          type="button"
          onClick={fetchData}>
          View
        </Button>
      </div>
    </div>
  );
};

export default StudentSubjectFilter;
