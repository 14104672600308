import React from 'react';
import { Form, Button } from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import Loader from '../../../components/Loader/Loader';

const AttendanceTeacherMapForm = ({ addProgram, removeProgram, submitHandler, programData, program_ids,
  leave_approval_program_ids, addApproverProgram, removeApproverProgram, loader
}) => {
  return (
    <React.Fragment>
      {loader && <Loader />}
      <Form onSubmit={submitHandler}>
        <div className="row">
          <div className="col-md-12">
            <Form.Group>
              <Form.Label>Attendance Class</Form.Label>
              <Multiselect
                name={`program_ids`}
                options={programData}
                id={'program_ids'}
                onRemove={(e) => removeProgram(e)}
                displayValue="name"
                showCheckbox={true}
                selectedValues={program_ids}
                onSelect={(e) => addProgram(e)}
              />
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group>
              <Form.Label>Leave Approver Class</Form.Label>
              <Multiselect
                name={`leave_approval_program_ids`}
                options={programData}
                id={'leave_approval_program_ids'}
                onRemove={(e) => removeApproverProgram(e)}
                displayValue="name"
                showCheckbox={true}
                selectedValues={leave_approval_program_ids}
                onSelect={(e) => addApproverProgram(e)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row d-flex justify-content-end mt-3">
          <div className="col-md-2 mx-3">
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default AttendanceTeacherMapForm;
