import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaCalendarAlt } from "react-icons/fa";
import { tableConstants } from "./AttendanceTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import CardHeader from "../../../components/Card/CardHeader";
import AttendanceView from "./AttendanceView";
import { getMappedPrograms, getAttendance } from "../../../services/AppManagement/AttendanceService";

const AttendanceList = () => {
  const [data, setData] = useState([]);
  const [program_id, setProgramId] = useState('');
  const [attendance_date, setAttendenceDate] = useState('');
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [programData, setProgramData] = useState([]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramId(value);
    }
    if (name === 'attendance_date') {
      const selectedDate = new Date(e.target.value);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Ignore time part
      if ((selectedDate < today) || (selectedDate.toDateString() === today.toDateString())) {
        setErrorMessage('');
        setAttendenceDate(value);
      } else {
        setErrorMessage('The Attendance date cannot be in the future.');
        setAttendenceDate('');
      }
    }
  }

  async function fetchAttendance() {
    const session_id = localStorage.getItem('session_id');
    try {
      setIsLoader(true);
      const response = await getAttendance(program_id, session_id, attendance_date);
      if (response.status === 200) {
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error);
    }
    finally {
      setIsLoader(false);
    }
  }

  const fetchMappedPrograms = async (id) => {
    try {
      const response = await getMappedPrograms(id);
      if (response.status === 200) {
        let programs = response.data.data.attendances.map((item) => {
          return {
            id: item.program.id,
            name: item.program.name
          };
        });
        setProgramData(programs);
      }
    }
    catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    //Set the max attribute to today's date
    const dateField = document.getElementById('attendance_date');
    const today = new Date().toISOString().split('T')[0];
    dateField.setAttribute('max', today);
    setAttendenceDate(today);
    fetchMappedPrograms(user_id);
  }, []);

  return (
    <div className="card">
      <CardHeader
        title="Attendance"
        icon={<FaCalendarAlt />}
        button={
          <div>
            {permissions.includes("attendance.report.view") && (
              <Link className="btn btn-primary mx-2" to="/attendance/report">
                Report
              </Link>
            )}
            {permissions.includes("attendance.update") && (
              <Link className="btn btn-primary mx-2" to="/attendance/update">
                Update
              </Link>
            )}
            {permissions.includes("attendance.mark") &&
              <Link className="btn btn-primary" to="/attendance/create">
                Add
              </Link>
            }
          </div>
        }
      />
      <div className="card-body">
        <AttendanceView
          changeHandler={changeHandler}
          fetchAttendance={fetchAttendance}
          programData={programData}
          program_id={program_id}
          attendance_date={attendance_date}
          errorMessage={errorMessage}
        />
        {isLoader ? <Loader /> :
          <div className="row mt-3">
            {data && (
              <Table
                cols={tableConstants(permissions)}
                data={data}
                hover={true}
              />
            )}
          </div>}
      </div>
    </div>
  );
};
export default AttendanceList;
