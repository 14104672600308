import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { fetchOrganizations } from '../../redux/organization/organizationActions';
import Card from '../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { createOrganization, getOrganizationById, updateOrganization } from '../../services/OrganizationService';
import OrganizationForm from './OrganizationForm';

const OrganizationCreate = ({ fetchOrganizations }) => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const getUniqueOrganization = useCallback(async (id) => {
    try {
      const response = await getOrganizationById(id);
      if (response.status === 200) {
        const { id, name } = response.data.data;
        setId(id);
        setName(name);
      }
    }
    catch (error) {
      handleRequestError(error);
    }
  }, []);

  useEffect(() => {
    const { id } = params;
    if (id) {
      getUniqueOrganization(id);
    }
  }, [params, getUniqueOrganization]);

  const changeHandler = (event) => {
    setName(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const apiRequest = id
        ? updateOrganization(id, { name })
        : createOrganization({ name });

      apiRequest
        .then(response => {
          let msg = response.data.message.success;
          enqueueSnackbar(msg, { variant: 'success' });
          fetchOrganizations();
          navigate('/organization/list');
        })
        .catch(error => {
          handleRequestError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      handleRequestError(error);
    }
  }

  const handleRequestError = (error) => {
    enqueueSnackbar('An error occurred while processing your request.' + error, { variant: 'error' });
  };

  const back = () => {
    navigate('/organization/list');
  };

  const cardTitle = id ? 'Update School' : 'Add School';

  return (
    <Card componentName={cardTitle} back={back}>
      <OrganizationForm
        submitHandler={submitHandler}
        changeHandler={changeHandler}
        name={name}
        loading={loading}
      />
    </Card>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    fetchOrganizations: () => dispatch(fetchOrganizations()),
  };
};

export default connect(null, mapDispatchToProps)(OrganizationCreate);
