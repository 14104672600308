import React from 'react';
import Select from '../../../components/Select/Select';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const AttendanceReportFilter = ({ changeHandler, fetchAttendance, programData,
  program_id, start_date, end_date, errorMessage, attendanceDownload }) => {
  return (
    <div className="row">
      <div className="col-md-3 pb-mb-2">
        <Select
          name="program_id"
          placeHolder={'Select Class'}
          value={program_id}
          onChangeHandler={changeHandler}
          options={programData}
        />
      </div>
      <div className="col-md-3 pb-mb-2">
        <Input
          type="date"
          name="start_date"
          value={start_date}
          onChangeHandler={changeHandler}
          required={true}
          error={errorMessage}
        />
      </div>
      <div className="col-md-3 pb-mb-2">
        <Input
          type="date"
          name="end_date"
          value={end_date}
          onChangeHandler={changeHandler}
          required={true}
          error={errorMessage}
        />
      </div>
      <div className="col-md-3">
        <Button className='mx-1'
          variant="primary"
          type="button"
          onClick={fetchAttendance}>
          View
        </Button>
        <Button
          variant="secondary"
          type="button"
          onClick={attendanceDownload}>
          Download
        </Button>
      </div>
    </div>
  );
};

export default AttendanceReportFilter;
