import {
    FETCH_SCORESCHEMESCATEGORY_FAILURE,
    FETCH_SCORESCHEMESCATEGORY_REQUEST,
    FETCH_SCORESCHEMESCATEGORY_SUCCESS
} from "./scoreSchemesCategoryTypes"

const initialState = {
    loading: false,
    scoreSchemesCategory: [],
    error: ''
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SCORESCHEMESCATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_SCORESCHEMESCATEGORY_SUCCESS:
            return {
                loading: false,
                scoreSchemesCategory: action.payload,
                error: '',
            }
        case FETCH_SCORESCHEMESCATEGORY_FAILURE:
            return {
                loading: false,
                scoreSchemesCategory: [],
                error: action.payload
            }
        default: return state;
    }
}
export default reducer;