import React from 'react';
import ExcelBulkUpload from '../ExcelBulkUpload/ExcelBulkUpload';

const BulkUploadTeacherSubjectProgram = ({
  downloadExcel,
  excelFileReader,
  bulkUpload
}) => {
  return (
    <ExcelBulkUpload
      downloadExcel={downloadExcel}
      excelFileReader={excelFileReader}
      bulkUploadFile={bulkUpload}
    />);
};

export default BulkUploadTeacherSubjectProgram;
