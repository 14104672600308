import React from "react";
import { useSelector } from "react-redux";
import "./TheLayout.css";
import TheContent from "./TheContent";
import TheFooter from "./TheFooter";
import TheHeader from "./TheHeader";
import TheSidebar from "./TheSidebar";


const TheLayout = ({ children }) => {
  const org_id = useSelector((state) => state.org_id);

  return (
    <React.Fragment>
       <div className="header-layout">
        <TheHeader org_id={org_id} />
      </div>
      <div className="sidebar-layout" id="sidebar">
        <TheSidebar />
      </div>

      <div className="content-wrapper">
        <div className="content mt-10">
          <main className="content-container">
            {children}
          </main>
          <TheContent org_id={org_id} className="mt-3" />
          </div>
          <TheFooter />
      </div>
    </React.Fragment>
  );
};

export default TheLayout;
