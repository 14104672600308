import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { fetchSubjects } from "../../redux/subject/subjectActions";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button } from "react-bootstrap";
import axios from "axios";
import { fetchEvents } from "../../redux/event/eventActions";
import { FaSearch } from "react-icons/fa";
import AssessmentFilter from "../AssessmentMarking/AssessmentFilter";
import Loader from "../../components/Loader/Loader";
import { tableConstants } from "./MarkingApprovalTableConstant";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";


class MarkingApprovalList extends Component {
  constructor() {
    super();
    this.state = {
      msg: false, loading: true, eventData: [], programData: [],
      subjectData: [], evaluationTypeData: [], program_id: [], subject_id: [],
      evaluation_type_id: [], start: "", end: "", name: "", term: '',
      reset_msg: false, status: "", selectedSubjects: [], selectedProgram: [],
      selctedEvaluationType: [],
      statusData: [{ id: 1, name: "Not Graded" }, { id: 2, name: "In Progress" },
      { id: 3, name: "Graded" },],
      permissions: JSON.parse(localStorage.getItem('permissions')),
      session_id: localStorage.getItem("session_id"),
      current_page: 1, last_page: 1, search: false, advanceSearch: false,
    };

  }
  async fetchPrograms() {
    try {
      const { data, status } = await axios.get('/approver/classes', {
        headers: { 'session-id': this.state.session_id },
      });

      console.log("Response fetchPrograms", data.data);

      if (status === 200) {
        this.setState({ programData: data.data });
      }
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  }

  getSubject = async (programId) => {
    try {
      const res = await axios.get(`/programs/${programId}/subjects`);
      const subjectData = res.data.data;
      this.setState({ subjectData });
    } catch (error) {
      console.log(error);
    }
  };

  getEventList = async (page) => {
    const { session_id } = this.state;
    this.setState({ loading: true });
    try {
      const response = await axios.get(`/marking/approval?session_id=${session_id}&page=${page}`);
      if (response.status === 200) {
        const eventData = response.data.data;
        const current_page = eventData.current_page;
        const last_page = eventData.last_page;
        this.setState({ eventData: eventData.data, current_page, last_page, loading: false });
      }
      this.setState({ disableSubmit: true });

    } catch (error) {
      this.setState({ disableSubmit: true });
      console.error(error);
    }
  }


  async componentDidMount() {
    const org_id = localStorage.getItem("organization_id");
    const session_id = localStorage.getItem("session_id");

    if (!org_id) {
      // toast.error("Please select any school");
      return; // Prevent further API calls if org_id is missing
    }
    if (!session_id) {
      // toast.error("Please select any session");
      return; // Prevent further API calls if org_id is missing
    }

    this.props.fetchEvaluationTypes();
    this.getEventList(this.state.current_page);
    this.fetchPrograms();
  }

  handleChange = (event) => {
    const { value: fieldValue } = event.target;
    this.setState((prevState) => ({ ...prevState, fieldName: fieldValue }));
  };

  handleSearch = (page) => {
    const {
      start, end, name, program_id, subject_id,
      evaluation_type_id, status, term
    } = this.state;
    const session_id = localStorage.getItem("session_id");

    if (!session_id) {
      // toast.error("Session ID is missing.");
      return;
    }
    if (term || start || end || name || program_id || subject_id || evaluation_type_id || status) {
      this.setState({ search: true });
    }
    this.setState({ loading: true, eventData: [] });
    axios
      .get(`/marking/approval?session_id=${session_id}&query=${name}${program_id}
      &subject_id=${subject_id}&evaluation_type_id=${evaluation_type_id}&start=${start}&end=${end}&status=${status}&term=${term}&page=${page}`)
      .then((response) => {
        if (response.status === 200) {
          const eventData = response.data.data;
          let msg;
          if (this.state.reset_msg) msg = false;
          else msg = true;
          this.setState({ eventData: eventData.data, msg, loading: false, reset_msg: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false }); // Hide loading indicator
        console.error("API Request Error:", error);
        // toast.error("Failed to fetch data. Please try again later.");
      });
  };
  onRemove = (event) => {
    console.log("Hello", event);
  };
  handleReset = (event) => {
    event.preventDefault();
    this.setState({
      name: "", program_id: [], subject_id: [], evaluation_type_id: [],
      start: "", end: "", reset_msg: true, status: "", selectedProgram: [],
      selectedSubjects: [], selctedEvaluationType: [], term: '',
      search: false, examList: [],
    }, () => console.log("reset", this.state.program_id));
    this.getEventList(1);
  };

  delete(id) {
    axios
      .delete(`/events/${id}`)
      .then((response) => {
        if (response.status === 200) {
          // toast.success(response.data.message.success);
          this.componentDidMount();
        }
      })
      .catch((error) => {
        this.setState({ disableSubmit: true });
        return error;
      });
  }

  async getExamList(program_id) {
    this.setState({ student_loader: true });
    try {
      const response = await axios.get(`/program/${program_id}/exams`);
      if (response.status === 200) {
        this.setState({ student_loader: false });
        const examList = response.data.data;
        this.setState({ examList });
      }
    } catch (error) {
      this.setState({ disableSubmit: true, student_loader: false });
      console.error(error);
    }
  }


  onProgramSelect = (event) => {
    let subjectData = event[0].subjects;
    this.setState(() => ({
      subjectData, search: true, subject_id: [],
      selectedProgram: event, selectedSubjects: [],
      program_id: [
        "&program_id[]=" + event[event.length - 1].id,
      ],
    }));
    this.getExamList(event[event.length - 1].id);
    this.getSubject(event[event.length - 1].id);
  };
  onSubjectSelect = (event) => {
    this.setState({ search: true });
    this.setState((prevState) => ({
      selectedSubjects: event,
      subject_id: [
        ...prevState.subject_id,
        "&subject_id[]=" + event[event.length - 1].id,
      ],
    }));
  };
  onEvaluationSelect = (event) => {
    this.setState((prevState) => ({
      search: true,
      selctedEvaluationType: event,
      evaluation_type_id: [
        ...prevState.evaluation_type_id,
        "&evaluation_type_id[]=" + event[event.length - 1].id,
      ],
    }));
  };

  onSubjectRemove = (event) => {
    let subject_id = event.map((item) => "&subject_id[]=" + item.id);
    this.setState({ search: true, selectedSubjects: event, subject_id: subject_id });
  }
  onEvaluationRemove = (event) => {
    let evaluation_type_id = event.map((item) => "&evaluation_type_id[]=" + item.id);
    this.setState({ search: true, selctedEvaluationType: event, evaluation_type_id: evaluation_type_id });
  }

  advancedSearchHandler = e => {
    e.preventDefault();
    this.setState({ ...this.state, advanceSearch: !this.state.advanceSearch })
  }

  changeHandler = (e) => {
    this.setState({ search: true });
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { evaluationType } = this.props;
    const { programData, subjectData, loading, eventData, permissions, advanceSearch, last_page } = this.state;
    return (
      <div className="card">
        {loading ?
          <Loader />
          : (
            <div className="event-list">
              <div className="">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <div className="page-title d-flex align-items-center">
                      <Button className="btn-success" onClick={this.advancedSearchHandler}>
                        <FaSearch /> Search</Button>
                    </div>
                  </div>
                </div>
                <div className={`card-body ${advanceSearch ? 'show' : 'hide'}`} id="advanced-search">
                  <div className="filter-event">
                    <AssessmentFilter
                      state={this.state}
                      evaluationType={evaluationType}
                      programData={programData}
                      subjectData={subjectData}
                      changeHandler={this.changeHandler}
                      onProgramSelect={this.onProgramSelect}
                      onSubjectSelect={this.onSubjectSelect}
                      onEvaluationSelect={this.onEvaluationSelect}
                      onSubjectRemove={this.onSubjectRemove}
                      onEvaluationRemove={this.onEvaluationRemove}
                      handleSearch={this.handleSearch}
                      handleReset={this.handleReset}
                    />
                  </div>
                </div>
                <div className="card-body">

                  {/* This is table for display event data on ui */}
                  <div className="evaluating-table event-tablecus">
                    {eventData && eventData.length ? (
                      <Table
                        cols={tableConstants(permissions)}
                        data={eventData}
                        hover={true}
                      />) :
                      <h3 className="text-center">No Record Found</h3>
                    }
                  </div>
                  <div className="card-body evaluating-table">
                    {eventData && eventData.length ? (
                      <Pagination
                        totalPage={last_page}
                        handlePageChange={(i) => this.getEventList(i)}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    programData: state.program.programs.data,
    subjectData: state.subject.subjects.data,
    evaluationType: state.evaluationType.evaluationTypes.data,
    eventData: state.event.events.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchPrograms: () => dispatch(fetchPrograms()),
    fetchSubjects: () => dispatch(fetchSubjects()),
    fetchEvaluationTypes: () => dispatch(fetchEvaluationTypes()),
    fetchEvents: () => dispatch(fetchEvents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MarkingApprovalList);

