import {
  FETCH_TEACHERS_FAILURE,
  FETCH_TEACHERS_REQUEST,
  FETCH_TEACHERS_SUCCESS
} from "./teacherTypes";
import axios from 'axios';
import { ENDPOINTS } from '../../services/endpoints';

export const fetchTeachersRequest = () => {
  return {
    type: FETCH_TEACHERS_REQUEST
  }
}
export const fetchTeachersSuccess = teachers => {
  return {
    type: FETCH_TEACHERS_SUCCESS,
    payload: teachers
  }
}
export const fetchTeachersFailure = error => {
  return {
    type: FETCH_TEACHERS_FAILURE,
    payload: error
  }
}
export const fetchTeachers = (name = '', { selected = 0 }) => {
  let page = selected + 1;
  let session_id = localStorage.getItem('session_id');
  return (dispatch) => {
    dispatch(fetchTeachersRequest)
    axios.get(`${ENDPOINTS.USER.GET}?session_id=${session_id}&name=${name}&page=${page}`)
      .then(response => {
        response.data.data = response.data.data.filter((item) => {
          let rolename = JSON.parse(localStorage.getItem("roles"))[0].name;
          if (item.role.name !== "SUPER ADMIN") {
            return true;
          }
          if (rolename === "SUPER ADMIN") {
            return true;
          }
        });
        const teachers = response.data;
        dispatch(fetchTeachersSuccess(teachers));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchTeachersFailure(errorMsg))
      })
  }
}
