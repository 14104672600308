import React from "react";
import "./main.css";
import "./mobile.css";
import Routing from "./Routing";
import { SnackbarProvider } from 'notistack'

function App() {
  return (
    <SnackbarProvider
      autoHideDuration={3000}
      transitionDuration={500}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      maxSnack={1}
      preventDuplicate
    >
      <Routing />
    </SnackbarProvider>
  );
}

export default App;
