import React from "react";
import { faEdit, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (permissions, statusHandler, studentDelete) => {
  let count = 1;
  let formatedData = [
    {
      title: 'Sr',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'RollNo',
      render: (rowData) => {
        return <span>{rowData.roll_no}</span>;
      },
    },
    {
      title: 'Admission_NO',
      render: rowData => {
        return <span>{rowData.id_number}</span>;
      },
    },
    {
      title: 'First_Name',
      render: rowData => {
        return <span>{`${rowData.first_name}`}</span>;
      },
    },
    {
      title: 'Last_Name',
      render: rowData => {
        return <span>{`${rowData.last_name}`}</span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.programs && rowData.programs.name}</span>;
      },
    },
    {
      title: 'Date_of_Birth',
      render: rowData => {
        return <span>{rowData.dob}</span>;
      },
    },
    {
      title: 'Mother_Name',
      render: rowData => {
        return <span>{`${rowData.mother_name}`}</span>;
      },
    },
    {
      title: 'Father_Name',
      render: rowData => {
        return <span>{`${rowData.father_name}`}</span>;
      },
    },
    {
      title: 'Contact_No',
      render: rowData => {
        return <span>{rowData.phone1}</span>;
      },
    },
    {
      title: 'House_Name',
      render: rowData => {
        return <span>{rowData.house_name}</span>;
      },
    },
    {
      title: 'ActivationKey',
      render: rowData => {
        return <span>{rowData.activation_key}</span>;
      },
    }
  ];

  if (permissions.includes("student.update")) {
    formatedData.push({
      title: 'Operation',
      render: rowData => {
        return (
          <span>
            {permissions.includes("student.update") && <React.Fragment>
              {(rowData.suspended) === 0 &&
                <Link to={`/student/update/${rowData.id}`}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    color="#321fdb"
                    style={{
                      cursor: "pointer"
                    }}
                  />
                </Link>}
            </React.Fragment>}
            &nbsp;&nbsp;
            {permissions.includes("student.update") &&
              <FontAwesomeIcon
                icon={rowData.suspended === 0 ? faEye : faEyeSlash}
                color={rowData.suspended === 0 ? "#321fdb" : "#f00"}
                className="cursor-pointer"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to ${rowData.suspended === 0 ? "InActive" : "Active"} the student?`
                    )
                  )
                    statusHandler(rowData.id, rowData.suspended === 0 ? 1 : 0)
                }}
              />
            }
            &nbsp;&nbsp;
            {permissions.includes("student.delete") &&
              <span onClick={() => studentDelete(rowData.id)}>
                <i className="fa fa-trash text-danger"
                  style={{ cursor: 'pointer' }}></i>
              </span>
            }
          </span>
        );
      },
    });
  }
  return formatedData;
};
