import React from 'react';
import { Multiselect } from "multiselect-react-dropdown";
import { Form, Alert } from "react-bootstrap";
import { termOptions } from '../../globalConstants';
import Select from '../../components/Select/Select';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

const AssessmentFilter = (props) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Class</Form.Label>
            <Multiselect
              options={props.programData} // Options to display in the dropdown
              selectedValues={props.state.selectedProgram} // Preselected value to persist in dropdown
              onSelect={props.onProgramSelect} // Function will trigger on select event
              onRemove={props.onRemove} // Function will trigger on remove event
              singleSelect={true}
              showCheckbox={true}
              id="program_id"
              displayValue="name" // Property name to display in the dropdown options
            />
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Subject</Form.Label>
            <Multiselect
              className="form-select"
              options={props.subjectData} // Options to display in the dropdown
              selectedValues={props.state.selectedSubjects} // Preselected value to persist in dropdown
              onSelect={props.onSubjectSelect} // Function will trigger on select event
              onRemove={props.onSubjectRemove} // Function will trigger on remove event
              showCheckbox={true}
              id="subject_id"
              displayValue="name" // Property name to display in the dropdown options
            />
          </Form.Group>
        </div>
        <div className="col-md-4">
        <Select
            label="Exam Hearders"
            name="name"
            placeHolder={"Select Exam Headers"}
            value={props.state.name}
            onChangeHandler={props.changeHandler}
            options={props.state.examList}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Evaluation</Form.Label>
            <Multiselect
              options={props.evaluationType} // Options to display in the dropdown
              selectedValues={props.state.selctedEvaluationType} // Preselected value to persist in dropdown
              onSelect={props.onEvaluationSelect} // Function will trigger on select event
              onRemove={props.onEvaluationRemove} // Function will trigger on remove event
              ref={props.multiselectRefTracker}
              singleSelect={true}
              showCheckbox={true}
              id="evaluation_type_id"
              displayValue="name" // Property name to display in the dropdown options
            />
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Input
            label="From"
            type="date"
            name="start"
            value={props.state.start}
            onChangeHandler={props.changeHandler}
          />
        </div>
        <div className="col-md-4">
          <Input
            label="To"
            type="date"
            name="end"
            value={props.state.end}
            onChangeHandler={props.changeHandler}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Status"
            name="status"
            placeHolder={"Select Status"}
            value={props.state.status}
            onChangeHandler={props.changeHandler}
            options={props.state.statusData}
          />
        </div>
        <div className="col-md-4">
          <Select
            label="Term"
            name="term"
            placeHolder={"Select Term"}
            value={props.state.term}
            onChangeHandler={props.changeHandler}
            options={termOptions}
          />
        </div>
        <div className="col text-right border-top pt-3 mt-3">
          <Button
            variant="primary"
            type="submit"
            className="mx-2"
            onClick={e => props.handleSearch({ selected: 0 })}
          >
            Search
          </Button>

          <Button
            variant="secondary"
            onClick={(event) => props.handleReset(event)}
          > Reset
          </Button>
        </div>
      </div>
      {props.state.msg ? (
        <Alert variant="info">
          You are getting filtered Records
        </Alert>
      ) : null}
    </React.Fragment>
  );
};

export default AssessmentFilter;
