import React from 'react';
import Loader from '../../components/Loader/Loader';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

const OrganizationForm = ({ submitHandler, changeHandler, name, loading }) => {
  return (
    <form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-10">
          <Input
            label={'School Name'}
            type={'text'}
            name={'name'}
            onChangeHandler={changeHandler}
            placeholder={'Name'}
            value={name}
            required={true}
          />
        </div>
        <div className="col-md-2 mt-4">
          <Button variant="success" type="submit" disabled={loading}>
            Submit
          </Button>
        </div>
      </div>
      {loading && <Loader />}
    </form>
  );
};

export default OrganizationForm;
