import React from 'react';
import { Form } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Switch from '../../../components/Switch/Switch';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const EventForm = ({ changeHandler, handleEditorChange, submitHandler,
  title, description, is_holiday, event_date }) => {
  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-12">
          <Input
            label="Title"
            type="text"
            name="title"
            value={title}
            placeholder={"Enter Title"}
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-12">
          <Input
            label="Event Date"
            type="date"
            name="event_date"
            value={event_date}
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-12">
          <Form.Group>
            <Form.Label>Is Holiday</Form.Label>
            <Switch
              name="is_holiday"
              id="is_holiday"
              label={"Is Holiday"}
              checked={is_holiday.toString() === '1'}
              value={'1'}
              onChange={changeHandler}
            />
          </Form.Group>
        </div>
        <div className="col-md-12 mt-2">
          <Form.Group className="mb-3"
            controlId="description">
            <Form.Label>Description</Form.Label>
            <CKEditor
              name="description"
              id={'description'}
              editor={ClassicEditor}
              config={{
                toolbar: [
                  'heading', '|',
                  'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
                  'undo', 'redo'
                ]
              }}
              data={description}
              onChange={handleEditorChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="mt-3">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EventForm;
