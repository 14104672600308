import React from 'react';
import { Form, Button } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const FaqForm = ({ changeHandler,handleEditorChange, submitHandler, title, description }) => {
  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-12">
          <Form.Group>
            <Form.Label>Question</Form.Label>
            <div className="input-group">
              <input name="title" id="title"
                className="form-control daterange"
                value={title}
                type="text"
                onChange={changeHandler}
                required />
            </div>
          </Form.Group>
        </div>
        <div className="col-md-12 mt-2">
          <Form.Group className="mb-3"
            controlId="description">
            <Form.Label>Answer</Form.Label>
            <CKEditor
              name="description"
              id={'description'}
              editor={ClassicEditor}
              config={{
                toolbar: [
                  'heading', '|',
                  'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
                  'undo', 'redo'
                ]
              }}
              data={description}
              onChange={handleEditorChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className="row d-flex justify-content-end mt-3">
        <div className="col-md-2 mx-3">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default FaqForm;
