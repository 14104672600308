import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getFaqById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.FAQ.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getFaqs = async (page) => {
  try {
    const response = await axios.get(ENDPOINTS.FAQ.GET, {
      params: {
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createFaq = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.FAQ.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateFaq = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.FAQ.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteFaq = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.FAQ.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}


