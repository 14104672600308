import React from 'react';
import { Form } from 'react-bootstrap';
import '../AssessmentMarking/ExcelBulkUpload.css';
import ExcelBulkUpload from '../ExcelBulkUpload/ExcelBulkUpload';
import Switch from '../../components/Switch/Switch';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';

const StudentForm = (props) => {
  const {
    uuid, user_name, email, first_name, last_name,
    gender, dob, phone1, phone2, category, aadhar_number,
    father_name, mother_name, house_name, roll_no,
    program_id
  } = props.data;
  const {
    setImage, preview, changeHandler, downloadExcel, excelFileReader, updateFlag,
    bulkUploadFile, submitHandler, id, loader, isBulk, programData, setUpdateFlag } = props;

  return (
    <Form>
      {isBulk ?
        <React.Fragment>
          <Switch
            label="Update students Data"
            onChange={() => setUpdateFlag(!updateFlag)}
            id="updateFlag"
            name="updateFlag"
            checked={updateFlag} />
          <ExcelBulkUpload
            loader={loader}
            downloadExcel={downloadExcel}
            excelFileReader={excelFileReader}
            bulkUploadFile={bulkUploadFile}
          />
        </React.Fragment>
        :
        <div className="row">
          <div className="col-md-4">
            <Input
              label="Admission Number"
              type="text"
              name="id_number"
              value={uuid}
              onChangeHandler={changeHandler}
              disabled={id ? true : false}
              placeholder="Enter ID Number"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Profile Image"}
              type="file"
              name="image"
              onChangeHandler={(e) => setImage(e.target.files[0])}
              placeholder="image"
              required={true}
            />
          </div>
          <div className="col-md-4">
            {
              <img
                id="profileImage"
                src={preview}
                alt="profile"
                style={{
                  position: "relative",
                  width: "100px",
                  height: "100px",
                  overflow: "hidden",
                  borderRadius: "5%"
                }}
              />
            }
          </div>
          <div className="col-md-4">
            <Input
              label="First Name"
              type="text"
              name="first_name"
              value={first_name}
              onChangeHandler={changeHandler}
              placeholder="Enter First Name"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="Last Name"
              type="text"
              name="last_name"
              value={last_name}
              onChangeHandler={changeHandler}
              placeholder="Enter Last Name"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Select
              label={'Gender'}
              name='gender'
              value={gender || ''}
              required={true}
              placeHolder={"Select Gender"}
              onChangeHandler={changeHandler}
              options={[{ id: "male", name: "Male" },
              { id: "female", name: "Female" },
              { id: "transgender", name: "Transgender" }]}
            />
          </div>
          <div className="col-md-4">
            <Select
              label={'Class'}
              name='program_id'
              value={program_id || ''}
              required={true}
              placeHolder={"Select Class"}
              onChangeHandler={changeHandler}
              options={programData}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Dob"}
              type="date"
              name="dob"
              value={dob}
              onChangeHandler={changeHandler}
              placeholder="Enter Dob"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="Contact"
              type="number"
              name="phone1"
              value={phone1}
              onChangeHandler={changeHandler}
              placeholder="Enter Contact"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="Optional Contact"
              type="number"
              name="phone2"
              value={phone2}
              onChangeHandler={changeHandler}
              placeholder="Enter Contact"
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"User Name"}
              type="text"
              name="user_name"
              value={user_name}
              onChangeHandler={changeHandler}
              placeholder="Enter User Name"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Email"}
              type="email"
              name="email"
              value={email}
              onChangeHandler={changeHandler}
              placeholder="Enter Email"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Roll No"}
              type="text"
              name="roll_no"
              value={roll_no}
              onChangeHandler={changeHandler}
              placeholder="Enter Roll No"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Category"}
              type="text"
              name="category"
              value={category}
              onChangeHandler={changeHandler}
              placeholder="Enter Category"
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Aadhar Number"}
              type="text"
              name="aadhar_number"
              value={aadhar_number}
              onChangeHandler={changeHandler}
              placeholder="Enter Aadhar Number"
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Father Name"}
              type="text"
              name="father_name"
              value={father_name}
              onChangeHandler={changeHandler}
              placeholder="Enter Father Name"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"Mother Name"}
              type="text"
              name="mother_name"
              value={mother_name}
              onChangeHandler={changeHandler}
              placeholder="Enter Mother Name"
              required={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              label={"House Name"}
              type="text"
              name="house_name"
              value={house_name}
              onChangeHandler={changeHandler}
              placeholder="Enter House Name"
              required={true}
            />
          </div>
        </div>
      }
      {/* ... Add form fields for other student fields ... */}
      {isBulk ? '' :
        <div className="col-md-12 d-flex justify-content-end">
          <div className="mt-2">
            <Button
              onClick={submitHandler}
              variant="primary"
              size="medium"
              disabled={loader}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>}
    </Form>
  );
};

export default StudentForm;
