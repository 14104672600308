import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { Link } from "react-router-dom";
import { FaFolderOpen } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import { deleteProgram } from "../../services/ProgramServices";
import ListCard from "../../components/Card/ListCard";
import CardHeader from "../../components/Card/CardHeader";

const ProgramList = () => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);
  const lastPage = useSelector((state) => state.program.programs.last_page);

  useEffect(() => {
    dispatch(fetchPrograms({ selected: 0 }));
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      const response = await deleteProgram(id);
      if (response.status === 200) {
        const msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: "success" });
        dispatch(fetchPrograms({ selected: 0 }));
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error while deleting:", error.response);
    }
  };

  return (
    <div>
      {!programData ? (
        <Loader />
      ) : (
        <div className="card">
          <CardHeader
            title="Classes"
            icon={<FaFolderOpen />}
            permissions={permissions}
            button={permissions.includes("class.create") && (
              <Link color="#FFFFFF" className="btn btn-primary" to="/program/create">Add Class</Link>
            )}
          />
          <div className="card-body row">
            {programData && programData.map((item, idx) => (
              <ListCard
                key={idx}
                permissions={permissions}
                updatePermission="class.update"
                deletePermission="class.delete"
                updateUrl={`/program/update/${item.id}`}
                icon="fa-solid fa-folder-open"
                item={item}
                onDelete={handleDelete}
              />
            ))}
          </div>
          <div className="card-body evaluating-table">
            {programData.length ? (
              <Pagination
                totalPage={lastPage}
                handlePageChange={(i) => dispatch(fetchPrograms(i))}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramList;
