import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTraits } from '../../redux/trait/traitActions';
import { fetchPrograms } from '../../redux/program/programActions';
import Card from '../../components/Card/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import TraitForm from './TraitForm';
import { getTraitById, updateTrait, createTrait } from '../../services/TraitService';

const TraitCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const programData = useSelector((state) => state.program.programs.data ?? []);
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [loader, setLoader] = useState(false);
  const [programIds, setProgramIds] = useState([]);

  useEffect(() => {
    if (params.id) {
      getUniqueTrait();
    }
    dispatch(fetchPrograms());
  }, [params.id, dispatch]);

  async function getUniqueTrait() {
    try {
      setLoader(true);
      const response = await getTraitById(params.id);

      if (response.status === 200) {
        const { id, name } = response.data.data;
        const program = response.data.data.programs;
        const programIds = program.map((item) => item.id.toString());
        setId(id);
        setName(name);
        setProgramIds(programIds);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    }
  }

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const handleInputChange = (event) => {
    const progId = event.target.value;
    const value = event.target.value;
    let updatedProgramIds;

    if (event.target.checked) {
      updatedProgramIds = [...programIds, progId];
    } else {
      updatedProgramIds = programIds.filter((id) => id !== value);
    }
    setProgramIds(updatedProgramIds);
  };

  const handleSwitchChange = (event) => {
    let updatedProgramIds;

    if (event.target.checked) {
      updatedProgramIds = programData.map((program) => program.id.toString());
    } else {
      updatedProgramIds = [];
    }

    setProgramIds(updatedProgramIds);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');
    const data = { name, program_ids: programIds, session_id };
    try {
      setLoader(true);
      const response = id ? await updateTrait(id, data) : await createTrait(data);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        dispatch(fetchTraits());
        navigate('/trait/list');
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setLoader(false);
    };
  }

  const back = () => {
    navigate('/trait/list');
  };

  return (
    <Card
      componentName={
        id
          ? "Update Co-Scholastic Subjects"
          : "Add Co-Scholastic Subjects"
      }
      back={back}
    >
      <TraitForm
        onSubmit={submitHandler}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onSwitchChange={handleSwitchChange}
        loader={loader}
        programData={programData}
        programIds={programIds}
        name={name}
      />
    </Card>
  );
};

export default TraitCreate;
