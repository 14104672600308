import React, { useState, useEffect, useCallback } from 'react';
import Card from '../../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import TimeTableForm from './TimeTableForm';
import { getSubjects } from '../../../services/SubjectServices';
import { getTimeTableById, createTimeTable, updateTimeTable } from '../../../services/AppManagement/TimeTableService';
import { getPrograms } from '../../../services/ProgramServices';

const TimeTableManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [programData, setProgramData] = useState([]);
  const [program_ids, setProgramIds] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [data, setData] = useState([{
    teacher_id: '', subject_id: '',
    day: '', start_time: '', end_time: ''
  }]);
  const id = params.id;
  const DAYS_OF_WEEK = [
    { id: 'Sunday', name: 'Sunday' },
    { id: 'Monday', name: 'Monday' },
    { id: 'Tuesday', name: 'Tuesday' },
    { id: 'Wednesday', name: 'Wednesday' },
    { id: 'Thursday', name: 'Thursday' },
    { id: 'Friday', name: 'Friday' },
    { id: 'Saturday', name: 'Saturday' }
  ];

  useEffect(() => {
    if (id) {
      getUniqueTimeTable();
    }
  }, []);

  const getUniqueTimeTable = useCallback(async () => {
    try {
      const response = await getTimeTableById(params.id);
      if (response.status === 200) {
        const data = response.data.data;
        const obj = { subject_id: data.subject_id, day: data.day, start_time: data.start_time, end_time: data.end_time };
        setData([obj]);
        let programs = data.class_time_table_programs.map((val) => val.program);
        setProgramIds(programs);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params.id]);

  const changeHandler = useCallback((event, idx) => {
    const { name, value } = event.target;
    const list = [...data];
    list[idx][name] = value;
    setData(list);
  }, [data]);

  const getSubject = useCallback(async () => {
    try {
      const res = await getSubjects()
      const subjectData = res.data;
      setSubjectData(subjectData);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const classAddHandler = useCallback((e) => {
    setProgramIds([...e]);
  }, [program_ids]);

  const classRemoveHandler = useCallback((e) => {
    setProgramIds([...e]);
  }, [program_ids]);

  useEffect(() => {
    getSubject();
    async function fetchPrograms() {
      try {
        const response = await getPrograms();
        if (response.success) {
          setProgramData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPrograms();
    if (id) {
      getUniqueTimeTable();
    }
  }, [getUniqueTimeTable, getSubject, id]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');
    let programIds = [];
    programIds = program_ids && program_ids.map((val) => val.id);
    let request_data = { session_id, program_ids: programIds, data };
    try {
      const response = id
        ? await updateTimeTable(id, { id, session_id, program_ids: programIds, data: data[0] })
        : await createTimeTable(request_data);
      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        navigate('/timetable/list');
      }
    } catch (error) {
      console.error(error);
    }
  }, [data, id, navigate, program_ids]);

  const addNewRow = useCallback(() => {
    setData((prevState) => ([...prevState, { teacher_id: '', subject_id: '', day: '', start_time: '', end_time: '' }]));
  }, []);

  const clickOnDelete = useCallback((e, record) => {
    e.preventDefault();
    e.stopPropagation();
    setData((prevState) => (prevState.filter((r) => r !== record)));
  }, []);

  const back = useCallback(() => {
    navigate('/timetable/list');
  }, [navigate]);

  return (
    <Card componentName={id ? 'Update Time Table' : 'Add Time Table'} back={back}>
      <TimeTableForm
        submitHandler={handleSubmit}
        changeHandler={changeHandler}
        classAddHandler={classAddHandler}
        classRemoveHandler={classRemoveHandler}
        addRow={addNewRow}
        clickOnDelete={clickOnDelete}
        id={id}
        data={data}
        programData={programData}
        program_ids={program_ids}
        subjectData={subjectData}
        DAYS_OF_WEEK={DAYS_OF_WEEK}
      />
    </Card>
  );
};
export default TimeTableManage;
