import React from "react";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (examDelete, permissions) => {
  let count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{rowData.name}</span>;
      },
    },
    {
      title: 'Position',
      render: rowData => {
        return <span>{rowData.position}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>
          {
            (rowData.term === 1 || rowData.term === '1') ? 'Term-1' :
              (rowData.term === 2 || rowData.term === '2') ? 'Term-2' :
                (rowData.term === 3 || rowData.term === '3') ? "Common Pre-Board" :
                  (rowData.term === 4 || rowData.term === '4') ? "Pre-Board" : rowData.term
          }</span>;
      },
    },
    {
      title: 'Classes',
      render: rowData => {
        return <React.Fragment>{rowData.programs && rowData.programs.map(item => <span>{item.name}, </span>)}</React.Fragment>;
      },
    },
  ];

  if (permissions.includes("assessment.list.update") || permissions.includes("assessment.list.delete")) {
    formatedData.push({
      title: 'Action',
      render: item => {
        return (
          <React.Fragment>
            <Link
              to={`/assessment/update/${item.id}`}
              className="mr-2"
            >
              <FontAwesomeIcon icon={faEdit} color="blue" />
            </Link>
            <span
              className="cursor-pointer"
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you wish to delete this item?"
                  )
                )
                  examDelete(item.id);
              }}
            >&nbsp;&nbsp;
              <FontAwesomeIcon icon={faTrash} color="red" />
            </span>
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
