import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button, Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import AssessmentFilter from "./AssessmentFilter";
import Table from "../../components/Table/Table";
import { tableConstants } from "./assessmentMarkingTableConstant";
import Pagination from "../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import { getTeacherSubjectMapping } from "../../services/TeacherSubjectMappingService";
import { getExams, getFilteredExams, getProgramWiseExamList } from "../../services/ExamService";

const AssessmentMarkingList = () => {
  const [state, setState] = useState({
    msg: false,
    loading: false,
    eventData: [],
    programData: [],
    subjectData: [],
    evaluationTypeData: [],
    program_id: [],
    subject_id: [],
    evaluation_type_id: [],
    start: "",
    end: "",
    name: "",
    term: "",
    reset_msg: false,
    status: "",
    selectedSubjects: [],
    selectedProgram: [],
    selectedEvaluationType: [],
    statusData: [
      { id: 1, name: "Not Graded" },
      { id: 2, name: "In Progress" },
      { id: 3, name: "Graded" },
    ],
    permissions: JSON.parse(localStorage.getItem("permissions")),
    role: JSON.parse(localStorage.getItem("roles")),
    session_id: localStorage.getItem("session_id"),
    page: 1,
    lastPage: "",
    search: false,
    advanceSearch: false,
  });

  const dispatch = useDispatch();
  const evaluationType = useSelector((state) => state.evaluationType.evaluationTypes.data);

  useEffect(() => {
    const org_id = localStorage.getItem("organization_id");
    const session_id = localStorage.getItem("session_id");

    if (!org_id) {
      enqueueSnackbar("Organization ID is missing.", { variant: "error" });
      return;
    }
    if (!session_id) {
      enqueueSnackbar("Session ID is missing.", { variant: "error" });
      return;
    }

    dispatch(fetchEvaluationTypes());
    fetchTeacherSubjects();
    getEventList({ selected: 0 });
  }, [dispatch]);

  const fetchTeacherSubjects = async () => {
    try {
      const session_id = localStorage.getItem("session_id");
      const user_id = JSON.parse(localStorage.getItem("user")).id;
      const response = await getTeacherSubjectMapping(user_id, session_id);
      if (response.status === 200) {
        const programList = response.data.data.programs || [];
        const class_in_charge = response.data.data.class_in_charge || [];
        const programs = programList.map((item) => ({ id: item.id, subjects: [...item.subjects] }));
        const programData = [...programList, ...class_in_charge];

        const uniqueProgramData = Array.from(new Set(programData.map((item) => item.id)))
          .map((id) => programData.find((item) => item.id === id));

        setState((prevState) => ({
          ...prevState,
          programData: uniqueProgramData,
          programs,
        }));
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, disablesubmit: true }));
      console.error(error);
    }
  };

  const getEventList = async ({ selected }) => {
    const page_number = selected + 1;
    const { session_id } = state;
    setState((prevState) => ({ ...prevState, loading: true }));
    try {
      const response = await getExams(session_id, page_number);
      if (response.status === 200) {
        const res = response.data;
        const current_page = res.current_page;
        const last_page = res.last_page;
        const eventData = res.data;
        setState((prevState) => ({
          ...prevState,
          eventData,
          page: current_page,
          lastPage: last_page,
          loading: false,
        }));
      }
      setState((prevState) => ({ ...prevState }));
    } catch (error) {
      setState((prevState) => ({ ...prevState }));
    }
    finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleSearch = async ({ selected }) => {
    const {
      start, end, name, program_id,
      subject_id, evaluation_type_id,
      status, term,
    } = state;

    const page = selected + 1;
    const session_id = localStorage.getItem("session_id");
    if (!session_id) {
      enqueueSnackbar("Session ID is missing.", { variant: "error" });
      return;
    }
    if (term || start || end || name || program_id || subject_id || evaluation_type_id || status) {
      setState((prevState) => ({ ...prevState, search: true }));
    }
    setState((prevState) => ({ ...prevState, loading: true, eventData: [] }));

    try {
      const response = await getFilteredExams(session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      if (response.status === 200) {
        const eventData = response.data.data;
        const last_page = response.data.last_page;
        const msg = state.reset_msg ? false : true;
        setState((prevState) => ({
          ...prevState,
          eventData,
          msg,
          page: page,
          lastPage: last_page,
          reset_msg: false,
        }));
      }
    }
    catch (error) {
      setState((prevState) => ({ ...prevState }));
      enqueueSnackbar("Failed to fetch data. Please try again later.", { variant: "error" });
    }
    finally {
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      name: "",
      program_id: [],
      subject_id: [],
      evaluation_type_id: [],
      start: "",
      end: "",
      reset_msg: true,
      status: "",
      selectedProgram: [],
      selectedSubjects: [],
      selectedEvaluationType: [],
      term: "",
      search: false,
      examList: [],
    }));
    getEventList({ selected: 1 });
  };

  const onProgramSelect = (event) => {
    const subjectData = event[0].subjects;
    setState((prevState) => ({
      ...prevState,
      subjectData,
      search: true,
      subject_id: [],
      selectedProgram: event,
      selectedSubjects: [],
      program_id: ["&program_id[]=" + event[event.length - 1].id],
    }));
    getExamList(event[event.length - 1].id);
  };

  const onSubjectSelect = (event) => {
    setState((prevState) => ({
      ...prevState,
      search: true,
      selectedSubjects: event,
      subject_id: [
        ...prevState.subject_id,
        "&subject_id[]=" + event[event.length - 1].id,
      ],
    }));
  };

  const onEvaluationSelect = (event) => {
    setState((prevState) => ({
      ...prevState,
      search: true,
      selectedEvaluationType: event,
      evaluation_type_id: [
        ...prevState.evaluation_type_id,
        "&evaluation_type_id[]=" + event[event.length - 1].id,
      ],
    }));
  };

  const onSubjectRemove = (event) => {
    const subject_id = event.map((item) => "&subject_id[]=" + item.id);
    setState((prevState) => ({
      ...prevState,
      search: true,
      selectedSubjects: event,
      subject_id,
    }));
  };

  const onEvaluationRemove = (event) => {
    const evaluation_type_id = event.map((item) => "&evaluation_type_id[]=" + item.id);
    setState((prevState) => ({
      ...prevState,
      search: true,
      selectedEvaluationType: event,
      evaluation_type_id,
    }));
  };

  const getExamList = async (program_id) => {
    setState((prevState) => ({ ...prevState, student_loader: true }));
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          student_loader: false,
          examList: response.data.data,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        disableSubmit: true,
        student_loader: false,
      }));
      console.error(error);
    }
  };

  const advancedSearchHandler = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, advanceSearch: !prevState.advanceSearch }));
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, search: true, [name]: value }));
  };

  const { advanceSearch, programData, permissions, subjectData,
    eventData, page, lastPage, search, loading } = state;

  return (
    <div className="card">
      <div className="event-list">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div className="page-title d-flex align-items-center">
              <Button className="btn-success" onClick={advancedSearchHandler}>
                <FaSearch /> Search
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className={`filter-event ${advanceSearch ? "show" : "hide"} mb-3`} id="advanced-search">
            <AssessmentFilter
              state={state}
              evaluationType={evaluationType}
              programData={programData}
              subjectData={subjectData}
              changeHandler={changeHandler}
              onProgramSelect={onProgramSelect}
              onSubjectSelect={onSubjectSelect}
              onEvaluationSelect={onEvaluationSelect}
              onSubjectRemove={onSubjectRemove}
              onEvaluationRemove={onEvaluationRemove}
              handleSearch={handleSearch}
              handleReset={handleReset}
            />
          </div>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="evaluating-table event-tablecus">
              {eventData && eventData.length !== 0 ? (
                <Table cols={tableConstants(permissions, page)}
                  data={eventData}
                  hoverable={true} striped={true} />
              ) : (
                <div className="d-flex justify-content-center">
                  <h4>No Data Found</h4>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {eventData && eventData.length > 0 && (
        <div className="card-footer">
          <Pagination
            totalPage={lastPage}
            pages={lastPage}
            handlePageChange={(i) => {
              search ? handleSearch(i) : getEventList(i);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AssessmentMarkingList;
