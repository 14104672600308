import React from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (permissions, notificationDelete) => {
  let count = 1;
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'IdNumber',
      render: rowData => {
        return <span>{rowData.student.id_number}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{rowData.student.first_name}</span>;
      },
    },
    {
      title: 'Description',
      render: rowData => {
        return <div dangerouslySetInnerHTML={{ __html: rowData.message }} />;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <span>
          {permissions.includes('notification.update') &&
            <Link to={`/notification/update/${rowData.id}`}>
              <FontAwesomeIcon
                icon={faEdit}
                color="#321fdb"
                style={{
                  marginRight: "10px",
                  cursor: "pointer"
                }}
              />
            </Link>
          }
          &nbsp;
          {permissions.includes("notification.delete") &&
            <span
              className="cursor-pointer"
              onClick={() => {
                if (window.confirm(`Are you sure you wish to delete this item?`))
                  notificationDelete(rowData.id)
              }}>
              <i className="fa fa-trash text-danger"></i>
            </span>
          }
        </span>;
      },
    },
  ];
  return formatedData;
};
