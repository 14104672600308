import React, { useState, useEffect } from 'react';
import Card from '../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { createEvaluationType, getEvaluationTypeById, updateEvaluationType } from '../../services/EvaluationTypeService';
import { getScoreSchemes } from '../../services/ScoreSchemeService';
import EvaluationTypeForm from './EvaluationTypeForm';

const EvaluationTypeCreate = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [name, setName] = useState('');
  const [scoreSchemeList, setScoreSchemeList] = useState([]);
  const [score_scheme_ids, setScoreSchemeIds] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoader(true);
        const response = await getEvaluationTypeById(params.id);
        if (response.status === 200) {
          const id = response.data.data.id;
          const name = response.data.data.name;
          const score_scheme_ids = response.data.data.score_scheme.map(score => score.score_scheme_id.toString());
          setId(id);
          setName(name);
          setScoreSchemeIds(score_scheme_ids);
          setIsLoader(false);
        }
      }
      catch (error) {
        console.error(error);
      }
    }
    if (params.id) {
      fetchData();
    }
    const fetchScoreScheme = async () => {
      try {
        const response = await getScoreSchemes();
        console.log(response.data, "score scheme list");
        if (response.success) {
          setScoreSchemeList(response.data);
        }
      }
      catch (error) {
        console.error(error);
      }
    }
    fetchScoreScheme()
  }, [params.id]);

  const handleChange = event => {
    setName(event.target.value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    let updatedScoreSchemeIds;

    if (event.target.checked) {
      updatedScoreSchemeIds = [...score_scheme_ids, value];
    } else {
      updatedScoreSchemeIds = score_scheme_ids.filter(id => id !== value);
    }

    setScoreSchemeIds(updatedScoreSchemeIds);
  };

  const handleSwitchChange = (event) => {
    const updatedScoreSchemeIds = event.target.checked
      ? scoreSchemeList.map(scorescheme => scorescheme.id.toString())
      : [];
    setScoreSchemeIds(updatedScoreSchemeIds);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoader(true);
    const postData = { name, score_scheme_ids };
    try {
      const request = params.id
        ? await updateEvaluationType(id, postData)
        : await createEvaluationType(postData);
      if (request.status === 200) {
        enqueueSnackbar(request.data.message.success, { variant: 'success' });
        navigate('/evaluation/type/list');
      }
    }
    catch (error) {
      return error;
    }
    finally {
      setIsLoader(false);
    }
  };

  const back = () => {
    navigate('/evaluation/type/list');
  };

  return (
    <Card componentName={id ? 'Update Evaluation Type' : 'Add Evaluation Type'} back={back}>
      <EvaluationTypeForm
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        onInputChange={handleInputChange}
        onSwitchChange={handleSwitchChange}
        name={name}
        scoreSchemeList={scoreSchemeList}
        score_scheme_ids={score_scheme_ids}
        isLoader={isLoader}
      />
    </Card>
  );
};

export default EvaluationTypeCreate;
