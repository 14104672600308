import { ORGANIZATION_ID } from "./organizationIDTypes";

const orgId = localStorage.getItem('organization_id')
const organizationIDReducer = (state = orgId, action)=>{
    switch(action.type){
        case ORGANIZATION_ID:
            return{
                ...state,
                organizationId: action.payload
            }
        default: return state;
    }

}
export default organizationIDReducer;