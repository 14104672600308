import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getEventCalendarById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EVENT_CALENDAR.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getEventCalendars = async (session_id, page) => {
  try {
    const response = await axios.get(ENDPOINTS.EVENT_CALENDAR.GET, {
      params: {
        session_id: session_id,
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createEventCalendar = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.EVENT_CALENDAR.CREATE, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateEventCalendar = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.EVENT_CALENDAR.UPDATE}/${id}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteEventCalendar = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.EVENT_CALENDAR.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
