import {
  FETCH_SUBJECTS_FAILURE,
  FETCH_SUBJECTS_REQUEST,
  FETCH_SUBJECTS_SUCCESS
} from "./subjectTypes";
import axios from 'axios';

export const fetchSubjectsRequest = () => {
  return {
    type: FETCH_SUBJECTS_REQUEST
  }
}
export const fetchSubjectsSuccess = subjects => {
  return {
    type: FETCH_SUBJECTS_SUCCESS,
    payload: subjects
  }
}
export const fetchSubjectsFailure = error => {
  return {
    type: FETCH_SUBJECTS_FAILURE,
    payload: error
  }
}
export const fetchSubjects = ({ selected }) => {
  let page = selected + 1
  let org_id = localStorage.getItem('organization_id');
  return (dispatch) => {
    dispatch(fetchSubjectsRequest)
    axios.get(`/subjects?organization_id=${org_id}&&page=${page}`)
      .then(response => {
        const subjects = response.data;
        dispatch(fetchSubjectsSuccess(subjects));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchSubjectsFailure(errorMsg))
      })
  }
}
