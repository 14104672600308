import organizationReducer from './organization/organizationReducer';
import organizationIDReducer from './organizationID/organizationIDReducer';
import sessionReducer from './session/sessionReducer';
import programReducer from './program/programReducer';
import scoreSchemesReducer from './scoreSchemes/scoreSchemesReducer';
import subjectReducer from './subject/subjectReducer';
import evaluationTypeReducer from './evaluationType/evaluationTypeReducer';
import eventReducer from './event/eventReducer';
import teacherReducer from './teacher/teacherReducer';
import scoreSchemesCategoryReducer from './scoreSchemesCategory/scoreSchemesCategoryReducer';
import translatorReducer from './translator/translatorReducer';
import formulaBuilder from './formulaBuilder/formulaBuilderReducer';
import traitReducer from './trait/traitReducer';
import { combineReducers } from 'redux';
const rootReducer = combineReducers({
    organization: organizationReducer,
    organizationID: organizationIDReducer,
    session: sessionReducer,
    program: programReducer,
    scoreScheme: scoreSchemesReducer,
    subject: subjectReducer,
    evaluationType: evaluationTypeReducer,
    event: eventReducer,
    teacher: teacherReducer,
    scoreSchemeCategory: scoreSchemesCategoryReducer,
    translator: translatorReducer,
    formulaBuilder: formulaBuilder,
    trait: traitReducer,
})
export default rootReducer;