import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getScoreSchemes = async () => {
  try {
    const response = await axios.get(ENDPOINTS.SCORE_SCHEME.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getScoreSchemeById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.SCORE_SCHEME.GET_SCORE_SCHEME}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createScoreScheme = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.SCORE_SCHEME.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateScoreScheme = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.SCORE_SCHEME.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteScoreScheme = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.SCORE_SCHEME.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getScoreSchemeByEvaluationType = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.SCORE_SCHEME.GET_BY_EVALUATION_TYPE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
