import {
  FETCH_ORGANIZATIONS_FAILURE,
  FETCH_ORGANIZATIONS_REQUEST,
  FETCH_ORGANIZATIONS_SUCCESS,
  FETCH_ACCESS_ORGANIZATIONS_FAILURE,
  FETCH_ACCESS_ORGANIZATIONS_REQUEST,
  FETCH_ACCESS_ORGANIZATIONS_SUCCESS
} from "./organizationTypes"

const initialState = {
  loading: false,
  organizations: [],
  accessOrganizations: [],
  error: ''
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ORGANIZATIONS_SUCCESS:
      return {
        loading: false,
        organizations: action.payload,
        error: '',
      }
    case FETCH_ORGANIZATIONS_FAILURE:
      return {
        loading: false,
        organizations: [],
        error: action.payload
      }
    case FETCH_ACCESS_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        accessOrganizations:[],
      }
    case FETCH_ACCESS_ORGANIZATIONS_SUCCESS:
      return {
        loading: false,
        accessOrganizations: action.payload,
        error: '',
      }
    case FETCH_ACCESS_ORGANIZATIONS_FAILURE:
      return {
        loading: false,
        accessOrganizations: [],
        error: action.payload
      }
    default: return state;
  }
}
export default reducer;
