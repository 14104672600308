import React from 'react';
import { Form } from 'react-bootstrap';
import Select from '../../../components/Select/Select';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const LeaveApproval = ({ leaveApproverHandler, approverHandler, status, reject_reason }) => {
  return (
    <Form onSubmit={leaveApproverHandler}>
      <div className="row">
        <div className="col-md-12">
          <Select
            label="Status"
            name="status"
            value={status}
            options={[
              { id: 'pending', name: 'Pending' },
              { id: 'approved', name: 'Approved' },
              { id: 'rejected', name: 'Rejected' }
            ]}
            onChangeHandler={approverHandler}
            placeHolder="Select Status"
          />
        </div>
        <div className="col-md-12 mt-2">
          <Input
            label="Reason"
            name="reject_reason"
            value={reject_reason}
            type="text"
            onChangeHandler={approverHandler}
            required={true}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-end mt-3">
        <div className="col-md-2 mx-3">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default LeaveApproval;
