import {
    FETCH_SUBJECTS_FAILURE,
    FETCH_SUBJECTS_REQUEST,
    FETCH_SUBJECTS_SUCCESS
} from "./subjectTypes"

const initialState = {
    loading: false,
    subjects: [],
    error: ''
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SUBJECTS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_SUBJECTS_SUCCESS:
            return {
                loading: false,
                subjects: action.payload,
                error: '',
            }
        case FETCH_SUBJECTS_FAILURE:
            return {
                loading: false,
                subjects: [],
                error: action.payload
            }
        default: return state;
    }
}
export default reducer;