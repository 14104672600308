import {
    FETCH_PROGRAMS_FAILURE,
    FETCH_PROGRAMS_REQUEST,
    FETCH_PROGRAMS_SUCCESS
} from "./programTypes"

const initialState = {
    loading: false,
    programs: [],
    error: ''
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PROGRAMS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_PROGRAMS_SUCCESS:
            return {
                loading: false,
                programs: action.payload,
                error: '',
            }
        case FETCH_PROGRAMS_FAILURE:
            return {
                loading: false,
                programs: [],
                error: action.payload
            }
        default: return state;
    }
}
export default reducer;