import {
  FETCH_SCORESCHEMES_FAILURE,
  FETCH_SCORESCHEMES_REQUEST,
  FETCH_SCORESCHEMES_SUCCESS
} from "./scoreSchemesTypes";
import axios from 'axios';

export const fetchScoreSchemesRequest = () => {
  return {
    type: FETCH_SCORESCHEMES_REQUEST
  }
}
export const fetchScoreSchemesSuccess = scoreSchemes => {
  return {
    type: FETCH_SCORESCHEMES_SUCCESS,
    payload: scoreSchemes
  }
}
export const fetchScoreSchemesFailure = error => {
  return {
    type: FETCH_SCORESCHEMES_FAILURE,
    payload: error
  }
}
export const fetchScoreSchemes = ({ selected }) => {
  let page = selected + 1;
  const org_id = localStorage.getItem('organization_id')
  return (dispatch) => {
    dispatch(fetchScoreSchemesRequest)
    axios.get(`/score/schemes?organization_id=${org_id}&&page=${page}`)
      .then(response => {
        const scoreSchemes = response.data;
        dispatch(fetchScoreSchemesSuccess(scoreSchemes));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchScoreSchemesFailure(errorMsg))
      })
  }
}
