import './Tooltip.css';

export default function Tooltip({
  tooltipText = "Tooltip",
  position = "top",
  children
}) {
  return (
    <div className="tooltip-trigger">
      {children}
      <div className={`tooltip tooltip-${position}`}>
        {tooltipText}
      </div>
    </div>
  )
}
