import React from "react";

const TheFooter = () => {
  return (
    <footer className="footer_bg bg-white mt-2">
      <div className="d-flex justify-content-center">
        <span>For any queries, email us at <strong className="brand-title">ets+app@teams.apeejay.edu</strong></span>
      </div>
    </footer>
  );
};
export default React.memo(TheFooter);
