import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeachers } from "../../redux/teacher/teacherActions";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { tableConstants } from "./UserTableConstant";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import Table from "../../components/Table/Table";
import { enqueueSnackbar } from "notistack";
import { updateUserStatus } from "../../services/UserService";

const TeacherList = () => {
  const [name, setName] = useState("");
  const [permissions] = useState(JSON.parse(localStorage.getItem("permissions")));

  const dispatch = useDispatch();
  const teacherData = useSelector((state) => state.teacher.teachers.data);
  const lastPage = useSelector((state) => state.teacher.teachers.last_page);

  useEffect(() => {
    if (!localStorage.getItem("organization_id")) {
      enqueueSnackbar("Select any organization!", { variant: "error" });
    } else {
      dispatch(fetchTeachers(name, { selected: 0 }));
    }
  }, [dispatch, name]);

  const statusHandler = async (id, status) => {
    try {
      const response = await updateUserStatus(id, status);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        dispatch(fetchTeachers(name, 0));
      } else {
        enqueueSnackbar(response.data.message.error, { variant: "error" });
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="card">
      {!teacherData ? (<Loader />) :
        (<div className="roleUser-list">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title-page page-title d-flex align-items-center">
                <div className="mx-2">
                  <FaUser />
                </div>
                <span>User List</span>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name..."
                  onChange={(e) => {
                    setName(e.target.value);
                    dispatch(fetchTeachers(e.target.value, 0));
                  }}
                />
              </div>
              {permissions.includes("users.create") && (
                <Link className="btn btn-primary" to="/user/create">
                  Add User
                </Link>
              )}
            </div>
          </div>
          <div className="card-body evaluating-table">
            {teacherData && teacherData.length ? (
              <Table
                cols={tableConstants(permissions, statusHandler)}
                data={teacherData}
                hoverable={true}
                striped={true}
              />
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center vh-10">
                <div>
                  <p className="mt-3">No data found.</p>
                </div>
              </div>
            )}
          </div>
        </div>
        )}
      {teacherData && teacherData.length ? (
        <Pagination
          totalPage={lastPage}
          handlePageChange={(i) => dispatch(fetchTeachers(name, i))}
        />
      ) : null}
    </div>
  );
};

export default TeacherList;
