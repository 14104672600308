import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrganizations } from "../../redux/organization/organizationActions";
import { Link } from "react-router-dom";
import { FaUniversity } from "react-icons/fa";
import { enqueueSnackbar } from "notistack";
import { deleteOrganization } from "../../services/OrganizationService";
import ListCard from "../../components/Card/ListCard";
import CardHeader from "../../components/Card/CardHeader";

const OrganizationList = () => {
  const dispatch = useDispatch();
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const organizationData = useSelector(
    (state) => state.organization.organizations.data
  );

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  const deleteHandler = async (id) => {
    try {
      const response = await deleteOrganization(id);
      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: "success" });
        dispatch(fetchOrganizations());
      }
    } catch (error) {
      console.error("API Error:", error.response);
    }
  };

  return (
    <div className="organization-list">
      <div className="animated">
        <div className="card">
          <CardHeader
            title="Schools"
            icon={<FaUniversity />}
            permissions={permissions}
            button={permissions.includes("schools.create") && (
              <Link color="#FFFFFF" className="btn btn-primary" to="/organization/create">
                Add School
              </Link>
            )}
          />
          <div className="card-body row">
            {organizationData &&
              organizationData.map((item, idx) => (
                <ListCard
                  key={idx}
                  permissions={permissions}
                  updatePermission="schools.update"
                  deletePermission="schools.delete"
                  updateUrl={`/organization/update/${item.id}`}
                  icon="fa-solid fa-building-columns"
                  item={item}
                  onDelete={deleteHandler}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationList;
