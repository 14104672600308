import React from 'react';
import Switch from '../../../components/Switch/Switch';

const StudentListTable = ({ handleSwitchChange, handleInputChange, studentList, student_ids }) => {
  return (
    <table className="table table-hover table-bordered mt-3">
      <thead>
        <tr>
          <th>
            <Switch
              label={'All'}
              checked={studentList.length > 0 && student_ids.length === studentList.length}
              color={"success"}
              name="all"
              onChange={handleSwitchChange}
              id="select-all"
            />
          </th>
          <th>Roll NO</th>
          <th>Admission NO</th>
          <th>Student Name</th>
          <th>Class</th>
        </tr>
      </thead>
      {studentList && studentList.length > 0 ? (
        <tbody>
          {studentList.map((student, index) => (
            <tr key={index}>
              <td>
                <Switch
                  name="student_ids"
                  id={student.id}
                  checked={student_ids.includes(student.id.toString())}
                  value={student.id}
                  onChange={handleInputChange}
                  label={student.first_name}
                />
              </td>
              <td>{student.roll_no}</td>
              <td>{student.id_number}</td>
              <td>{`${student.first_name} ${student.last_name}`}</td>
              <td>{student.programs && student.programs.name}</td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          <tr align="center">
            <td colSpan="6">No Record Found</td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default StudentListTable;
