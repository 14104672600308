import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import baseUrl from "./config";
import axios from "axios";
import App from "./App";
import { createRoot } from 'react-dom/client';
import LocalStorageService from "./services/LocalStorageServices";
import { enqueueSnackbar } from "notistack";
// LocalstorageService
const localStorageService = LocalStorageService.getService();

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers["Authorization"] = "bearer " + token;
    }
    return config;
  },
  (error) => {
    return error;
  }
);

//Add a response interceptor

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      //console.log(error.response.data.message.errors["token"]);
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      localStorage.removeItem("token");
      //window.location.reload();
      this.props.history.push("/login");
      // return error.response;
    }
    const errors = error.response.data.message.errors;
    if (typeof errors === "string" && error.response.status === 422) {
      enqueueSnackbar(errors.toString(), { variant: "error" });
      return;
    }
    for (const item in errors) {
      let x = errors[item].toString();
      enqueueSnackbar(x, { variant: "error" });
    }
    if (
      error.response.status === 401 &&
      originalRequest.url === `${baseUrl}/api/auth/v1/`
    ) {
      this.props.history.push("/login");
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorageService.getRefreshToken();
      return axios
        .post(`${baseUrl}/api/v1/refresh`, {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 201) {
            localStorageService.setToken(res.data);
            axios.defaults.headers.common["Authorization"] =
              "bearer " + localStorageService.getAccessToken();
            return axios(originalRequest);
          }
        });
    }
    return error;
  }
);

// Ensure the target container is a valid DOM element
const rootContainer = document.getElementById('root');

if (rootContainer) {
  // Use createRoot to render your main component inside the root
  const root = createRoot(rootContainer);
  root.render(<Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>)
} else {
  console.error('Target container is not a valid DOM element.');
}
