import React from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const tableConstants = (permissions, showModel, wordOfTheDayDelete) => {
  let count = 1;
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'title',
      render: rowData => {
        return <span>{rowData.title}</span>;
      },
    },
    {
      title: 'Quote',
      render: rowData => {
        return <span>{rowData.quote}</span>;
      },
    },
    {
      title: 'Date',
      render: rowData => {
        return <span>{rowData.word_date}</span>;
      },
    },
    {
      title: 'Description',
      render: rowData => {
        return <span>
          <div dangerouslySetInnerHTML={{ __html: rowData.description }} />
        </span>;
      },
    },
    {
      title: 'Action',
      render: rowData => {
        return <span>
          {permissions.includes('word_of_the_day.update') &&
            <FontAwesomeIcon
              icon={faEdit}
              color="#321fdb"
              style={{
                marginRight: "10px",
                cursor: "pointer"
              }}
              onClick={() => showModel(rowData)}
            />
          }
          &nbsp;
          {permissions.includes("word_of_the_day.delete") &&
            <span
              className="cursor-pointer"
              onClick={() => {
                if (window.confirm(`Are you sure you wish to delete this item?`))
                  wordOfTheDayDelete(rowData.id)
              }}>
              <i className="fa fa-trash text-danger"></i>
            </span>
          }
        </span>;
      },
    },
  ];
  return formatedData;
};
