import React from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (permissions) => {
  var count = 1;
  let formatedData = [
    {
      title: 'S.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Class',
      render: rowData => {
        return <span>{rowData.program.name}</span>;
      },
    },
    {
      title: 'Session',
      render: rowData => {
        return <span>{rowData.session_name}</span>;
      },
    },
    {
      title: 'Term',
      render: rowData => {
        return <span>
          {rowData.term === "UT-1" ? "UT-I" : null}
          {rowData.term === "UT-2" ? "UT-II" : null}
          {rowData.term === "UT-3" ? "UT-III" : null}
          {rowData.term === "PT-1" ? "PT-I" : null}
          {rowData.term === "PT-2" ? "PT-II" : null}
          {rowData.term === "PT-3" ? "PT-III" : null}
          {rowData.term === "1" ? "Term-I" : null}
          {rowData.term === "3" ? "Common Pre-Board" : null}
          {rowData.term === "4" ? "Pre-Board" : null}
          {rowData.term === "2" ? "Term-II" : null}
        </span>;
      },
    },
  ];

  if (permissions.includes("publish.result.update")) {
    formatedData.push({
      title: 'Action',
      render: rowData => {
        return (
          <React.Fragment>
            {permissions.includes("publish.result.update") &&
              <Link to={"/publish/result/update/" + rowData.id}>
                  <FontAwesomeIcon
                    icon={faEdit}
                    color="#321fdb"
                    style={{
                      marginRight: "10px",
                      cursor: "pointer"
                    }}
                  />
              </Link>}
          </React.Fragment>
        );
      },
    });
  }
  return formatedData;
};
