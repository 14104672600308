import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { fetchSubjects } from "../../redux/subject/subjectActions";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import AssessmentFilter from "../AssessmentMarking/AssessmentFilter";
import { tableConstants } from "./examTableConstant";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import './Exam.css';
import {
  getExams, getFilteredExams, deleteExam,
  getProgramWiseSubjectList, getProgramWiseExamList,
  examActiveInActive
} from "../../services/ExamService";
import { enqueueSnackbar } from "notistack";

const EventList = () => {
  const [state, setState] = useState({
    msg: false,
    loading: true,
    eventData: [],
    programData: [],
    subjectData: [],
    evaluationTypeData: [],
    program_id: [],
    subject_id: [],
    evaluation_type_id: [],
    selectedSubjects: [],
    selectedProgram: [],
    selctedEvaluationType: [],
    start: "",
    end: "",
    name: "",
    term: '',
    reset_msg: false,
    status: "",
    statusData: [
      { id: 1, name: "Not Graded" },
      { id: 2, name: "In Progress" },
      { id: 3, name: "Graded" },
    ],
    current_page: 1,
    last_page: 1,
    examList: [],
    search: false,
    advanceSearch: false,
  });

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);
  const subjectData = useSelector((state) => state.subject.subjects.data);
  const evaluationType = useSelector((state) => state.evaluationType.evaluationTypes.data);

  useEffect(() => {
    const org_id = localStorage.getItem("organization_id");
    const session_id = localStorage.getItem("session_id");

    if (!org_id || !session_id) return;

    dispatch(fetchPrograms());
    dispatch(fetchSubjects({ selected: 0 }));
    dispatch(fetchEvaluationTypes());
    getEventList({ selected: 0 });
  }, [dispatch]);

  const getEventList = async ({ selected }) => {
    let current_page = selected + 1;
    const session_id = localStorage.getItem("session_id");
    try {
      setState(prevState => ({ ...prevState, loading: true }));
      const response = await getExams(session_id, current_page);
      if (response.status === 200) {
        const eventData = response.data.data;
        const last_page = response.data.last_page;
        setState(prevState => ({ ...prevState, eventData, current_page, last_page }));
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const getSubjectList = async (id) => {
    try {
      const response = await getProgramWiseSubjectList(id);
      if (response.status === 200) {
        const subjectData = response.data.data;
        setState(prevState => ({ ...prevState, subjectData }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = async ({ selected }) => {
    let page = selected + 1;

    const {
      start, end, name, program_id, subject_id, evaluation_type_id, status, term
    } = state;

    const session_id = localStorage.getItem("session_id");

    if (!session_id) return;

    if (term || start || end || name || program_id || subject_id || evaluation_type_id || status) {
      setState(prevState => ({ ...prevState, search: true }));
    }

    setState(prevState => ({ ...prevState, loading: true }));
    try {
      const response = await getFilteredExams(session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      if (response.status === 200) {
        const eventData = response.data.data;
        const last_page = response.data.last_page;
        setState(prevState => ({ ...prevState, eventData, current_page: page, last_page }));
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    setState({
      name: "", program_id: [], subject_id: [],
      evaluation_type_id: [], start: "", end: "",
      reset_msg: true, status: "", selectedProgram: [],
      selectedSubjects: [], selctedEvaluationType: [],
      term: '', search: false, examList: [],
    });
    getEventList({ selected: 0 });
  };

  const getExamList = async (program_id) => {
    setState(prevState => ({ ...prevState, student_loader: true }));
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        const examList = response.data.data;
        setState(prevState => ({ ...prevState, student_loader: false, examList }));
      }
    } catch (error) {
      setState(prevState => ({ ...prevState, disableSubmit: true, student_loader: false }));
      console.error(error);
    }
  };

  const deleteEvent = async (id) => {
    try {
      const response = await deleteExam(id);
      if (response.status === 200) {
        enqueueSnackbar("Exam deleted successfully", { variant: "success" });
        getEventList({ selected: 0 });
      }
    } catch (error) {
      console.error("API Request Error:", error);
      setState(prevState => ({ ...prevState, disableSubmit: true }));
    }
  };

  const onProgramSelect = (event) => {
    setState(prevState => ({
      ...prevState,
      search: true,
      subject_id: [],
      selectedProgram: event,
      selectedSubjects: [],
      program_id: ["&program_id[]=" + event[event.length - 1].id],
    }));
    getExamList(event[event.length - 1].id);
    getSubjectList(event[event.length - 1].id);
  };

  const onSubjectSelect = (event) => {
    setState(prevState => ({
      ...prevState,
      search: true,
      selectedSubjects: event,
      subject_id: [...prevState.subject_id, "&subject_id[]=" + event[event.length - 1].id],
    }));
  };

  const onEvaluationSelect = (event) => {
    setState(prevState => ({
      ...prevState,
      search: true,
      selctedEvaluationType: event,
      evaluation_type_id: [...prevState.evaluation_type_id, "&evaluation_type_id[]=" + event[event.length - 1].id],
    }));
  };

  const onSubjectRemove = (event) => {
    const subject_id = event.map(item => "&subject_id[]=" + item.id);
    setState(prevState => ({ ...prevState, search: true, selectedSubjects: event, subject_id }));
  };

  const onEvaluationRemove = (event) => {
    const evaluation_type_id = event.map(item => "&evaluation_type_id[]=" + item.id);
    setState(prevState => ({ ...prevState, search: true, selctedEvaluationType: event, evaluation_type_id }));
  };

  const advancedSearchHandler = (e) => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, advanceSearch: !prevState.advanceSearch }));
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState(prevState => ({ ...prevState, search: true, [name]: value }));
  };

  const eventHideAndShowHandler = async (e, item) => {
    try {
      console.log("item", item);
      let is_active = e.target.checked ? 1 : 0;
      const data = { is_active, event_id: item.id };
      const response = await examActiveInActive(data);
      if (response.status === 200) {
        enqueueSnackbar("Exam status updated successfully", { variant: "success" });
        getEventList({ selected: 0 });
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const { advanceSearch, eventData, last_page, search, current_page } = state;

  return (
    <div className="card">
      {state.loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <div className="page-title d-flex align-items-center">
                <Button className="btn-success" onClick={advancedSearchHandler}>
                  <FaSearch /> Search
                </Button>
              </div>
              {permissions && permissions.includes("exams.create") && (
                <Link className="btn btn-primary" to="/exam/create">
                  Add
                </Link>
              )}
            </div>
          </div>

          <div className="card-body">
            <div className={`filter-event ${advanceSearch ? 'show' : 'hide'} mb-5`} id="advanced-search">
              <AssessmentFilter
                state={state}
                evaluationType={evaluationType}
                programData={programData}
                subjectData={subjectData}
                changeHandler={changeHandler}
                onProgramSelect={onProgramSelect}
                onSubjectSelect={onSubjectSelect}
                onEvaluationSelect={onEvaluationSelect}
                onSubjectRemove={onSubjectRemove}
                onEvaluationRemove={onEvaluationRemove}
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </div>

            {eventData && eventData.length > 0 ? (
              <div className="evaluating-table event-tablecus">
                <Table
                  cols={tableConstants(deleteEvent, eventHideAndShowHandler, permissions, current_page)}
                  data={eventData}
                  hoverable={true}
                  striped={true}
                />
              </div>
            ) : (
              <div className="d-flex justify-content-center"> No data found</div>
            )}
          </div>
        </div>
      )}
      {eventData && eventData.length > 0 && (
        <div className="card-footer">
          <Pagination
            totalPage={last_page}
            handlePageChange={(i) => search ? handleSearch(i) : getEventList(i)}
          />
        </div>
      )}
    </div>
  );
};

export default EventList;
