import React from 'react';
import { Form } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';
import { StudentRow } from "./StudentRow";
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';

const PublishResultForm = ({ submitHandler, changeHandler, handleSwitchChange, handleInputChange, setStartDateTime, setEndDateTime, termOptions, term, class_id, programData, studentList, student_idnumbers, start_date_time, end_date_time, loading
}) => {
  return (
    <Form onSubmit={submitHandler}>
      <div className="card-body evaluating-table">
        <div className="row">
          <div className="col-md-6">
            <Select
              label="Term"
              name="term"
              placeHolder={"Select Term"}
              value={term}
              onChangeHandler={changeHandler}
              options={termOptions}
            />
          </div>
          <div className="col-md-6">
            <Select
              label={"Class"}
              name={"class_id"}
              placeHolder={"Select Class"}
              value={class_id}
              onChangeHandler={changeHandler}
              options={programData}
            />
          </div>
        </div>
        <div className="col-md-12">
          {loading ? <Loader />
            :
            <React.Fragment>
              {(studentList && studentList.length > 0) &&
                <table className="table table-hover table-bordered mt-3">
                  <thead>
                    <tr>
                      <th>Roll No</th>
                      <th>Admission NO</th>
                      <th>Student Name</th>
                      <th>
                        <Switch
                          checked={student_idnumbers.length === studentList.length && studentList.length > 0}
                          value="1"
                          name="all"
                          onChange={handleSwitchChange}
                          id="select-all"
                          label="Select All"
                        />
                      </th>
                    </tr>
                  </thead>
                  {studentList && studentList.length > 0 ?
                    <tbody>
                      {studentList.map((student, index) => (
                        <StudentRow key={index}
                          student={student}
                          handleInputChange={handleInputChange}
                          index={index}
                          student_idnumbers={student_idnumbers}
                        />
                      ))}
                    </tbody>
                    :
                    <tbody>
                      <tr align="center"><td colSpan="6">No Record Found</td></tr>
                    </tbody>
                  }
                </table>
              }
            </React.Fragment>
          }
        </div>
        <div className="row">
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>Start Date Time<i className="text-danger">*</i></Form.Label>
              <div className="input-group">
                <input name="start_date_time" id="start_date_time"
                  className="form-control daterange"
                  type="date" onChange={e => setStartDateTime(e.target.value)}
                  value={start_date_time}
                  required />
              </div>
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group>
              <Form.Label>End Date Time<i className="text-danger">*</i></Form.Label>
              <div className="input-group">
                <input name="end_date_time" id="end_date_time"
                  className="form-control daterange"
                  type="date" onChange={e => setEndDateTime(e.target.value)}
                  value={end_date_time}
                  required />
              </div>
            </Form.Group>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Button type="submit" className="btn btn-primary mt-2">Publish</Button>
        </div>
      </div>
    </Form>
  );
};

export default PublishResultForm;
