import React, { Suspense } from 'react';

const TheContent = (props) => {

  return (
    <main className="">
      <div className=''>
        <Suspense fallback={''}>
          {props.children}
        </Suspense>
      </div>
    </main>
  );
};

export default React.memo(TheContent);
