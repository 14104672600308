import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getOrganizations = async () => {
  try {
    const response = await axios.get(ENDPOINTS.CANDIDATE.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getOrganizationById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.ORGANIZATION.GET_ORGANIZATION}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createOrganization = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.ORGANIZATION.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateOrganization = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.ORGANIZATION.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteOrganization = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.ORGANIZATION.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUserOrganizations = async () => {
  try {
    const response = await axios.get(ENDPOINTS.ORGANIZATION.USER_ORGANIZATION);
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};
