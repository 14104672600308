import React from 'react';
import './ExcelBulkUpload.css';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

const MarkingExcelBulkUpload = ({
  excelDataReader,
  excelDataSubmitHandler,
  downloadExcel
}) => {

  return (
    <React.Fragment>
      <div className="bulk-upload-header text-center" style={{ width: '100%' }}>
        <div className="header-item">
          <span className='header-text'> Upload Excel</span>
        </div>
      </div>
      <div className="download-excel">
        <span className="download-excel-text">Don't have the marking excel? Click Here*</span>
        <Button type="submit" className='mx-2' onClick={downloadExcel} variant="success">
          Download Excel
        </Button>
      </div>
      <div className="upload-container">
        <h4 className='text-align-center'>Upload Excel Files</h4>
        <div className="file-upload">
          <Input
            type="file"
            name='file'
            className='form-control'
            onChangeHandler={excelDataReader}
            multiple accept=".xlsx, .xls"
          />
        </div>
        <Button type="button" onClick={excelDataSubmitHandler}
          variant="success">Submit Excel</Button>
      </div>
    </React.Fragment>

  );
};

export default MarkingExcelBulkUpload;
