import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessions } from "../../redux/session/sessionActions";
import { Link } from "react-router-dom";
import { FaClock } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { enqueueSnackbar } from "notistack";
import CardHeader from "../../components/Card/CardHeader";
import { deleteSession } from "../../services/SessionService";
import ListCard from "../../components/Card/ListCard";

const SessionList = () => {
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const dispatch = useDispatch();
  const { sessionData, lastPage } = useSelector(state => ({
    sessionData: state.session.sessions.data,
    lastPage: state.session.sessions.last_page,
  }));

  useEffect(() => {
    dispatch(fetchSessions());
  }, [dispatch]);

  const deleteHandler = async (id) => {
    try {
      const response = await deleteSession(id);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        dispatch(fetchSessions());
      }
    } catch (error) {
      console.error("Error deleting session:", error);
    }
  };

  return (
    <React.Fragment>
      {!sessionData ? (
        <Loader />
      ) : (
        <div className="card">
          <CardHeader
            title="Sessions"
            icon={<FaClock />}
            permissions={permissions}
            button={permissions.includes("sessions.create") && (
              <Link className="btn btn-primary" to="/session/create">
                Add Session
              </Link>
            )}
          />
          <div className="card-body row">
            {sessionData && sessionData.map((item, idx) => (
              <ListCard
                key={idx}
                permissions={permissions}
                updatePermission="sessions.update"
                deletePermission="sessions.delete"
                updateUrl={`/session/update/${item.id}`}
                icon="fa-solid fa-clock"
                item={item}
                onDelete={deleteHandler}
                preTitle="SESSION:"
                description={
                  <div className="st-en-d d-flex justify-content-between align-items-center">
                    <div className="small">
                      <i className="fa-solid fa-calendar-check"></i> Start: {item.start}
                    </div>
                    <div className="small">
                      <i className="fa-solid fa-calendar-check"></i> End: {item.end}
                    </div>
                  </div>
                }
              />
            ))}
          </div>
          <div className="card-body evaluating-table">
            {sessionData.length > 0 && (
              <Pagination
                totalPage={lastPage}
                handlePageChange={(i) => dispatch(fetchSessions(i))}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SessionList;
