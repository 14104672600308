import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { fetchPrograms } from '../../../redux/program/programActions';
import { fetchSessions } from "../../../redux/session/sessionActions";
import { FaUserAlt } from 'react-icons/fa';
import Loader from '../../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getClassWiseStudentList } from '../../../services/StudentService';
import CardHeader from '../../../components/Card/CardHeader';
import StudentListTable from './StudentListTable';
import PromoteFilter from './PromoteFilter';
import { updatePromotedStudents } from '../../../services/StudentService';

function ManagePromotedStudents() {
  const [program_id, setProgramId] = useState('');
  const [session_id, setSessionId] = useState('');
  const [loader, setLoader] = useState(false);
  const [student_ids, setStudentIds] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const dispatch = useDispatch();
  const programList = useSelector((state) => state.program.programs.data);
  const sessionData = useSelector((state) => state.session.sessions.data);

  useEffect(() => {
    dispatch(fetchPrograms({ selected: 0 }));
    dispatch(fetchSessions());
  }, [])

  const getStudentList = async (e) => {
    const session_id = localStorage.getItem("session_id");
    setStudentList([]);
    setStudentIds([]);
    if (program_id === '' || session_id === '') {
      enqueueSnackbar("Please select class and session", { variant: "error" });
      return;
    }
    try {
      setLoader(true);
      const response = await getClassWiseStudentList(session_id, program_id);
      if (response.status === 200) {
        const data = response.data;
        setStudentIds(response.data.data.map((student) => student.id.toString()));
        setStudentList(data.data);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  const handleInputChange = (event) => {
    const student_id = event.target.value;
    const value = event.target.value;
    let studentIds;

    if (event.target.checked) {
      studentIds = [...student_ids, student_id];
    } else {
      studentIds = student_ids.filter((id) => id !== value);
    }
    setStudentIds(studentIds);
  };

  const handleSwitchChange = (event) => {
    let student_ids;
    if (event.target.checked) {
      student_ids = studentList.map((student) => student.id.toString());
    } else {
      student_ids = [];
    }
    setStudentIds(student_ids);
  };

  const updateHandler = async () => {
    if (program_id === '' || session_id === '') {
      enqueueSnackbar("Please select class and session", { variant: "error" });
      return;
    }
    try {
      setLoader(true);
      const roll_nos = studentList.filter((student) => student_ids.includes(student.id.toString())).map((student) => student.roll_no);
      const data = {
        student_ids: student_ids,
        program_id: program_id,
        session_id: session_id,
        roll_nos: roll_nos,
        organization_id: localStorage.getItem('organization_id')
      };
      const response = await updatePromotedStudents(data);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: "success" });
        getStudentList();
      }
      setLoader(false);
    }
    catch (error) {
      setLoader(false);
      console.log(error);
    }
  }

  return (
    <div className="card">
      <CardHeader
        title="Promoted Students"
        icon={<FaUserAlt size={20} />}
        button={
          <Link to="/student/promote" className="btn btn-secondary">Back</Link>
        }
      />
      <div className="card-body">
        <div className="row">
          <PromoteFilter
            setSessionId={setSessionId}
            setProgramId={setProgramId}
            sessionData={sessionData}
            programList={programList}
          />
          <div className="col-md-2 mt-4">
            <Button variant="secondary" onClick={getStudentList}>Get Students</Button>
          </div>
        </div>
        {loader ? (<Loader />) :
          <StudentListTable
            handleSwitchChange={handleSwitchChange}
            handleInputChange={handleInputChange}
            studentList={studentList}
            student_ids={student_ids}
          />
        }
        <div className="d-flex justify-content-end">
          <Button variant="success" onClick={updateHandler}>Update</Button>
        </div>
      </div>
    </div>
  );
}
export default ManagePromotedStudents;

