import React from "react";

export const tableConstants = () => {
  let count = 1;
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'RollNo',
      render: rowData => {
        return <span>{(rowData.student && rowData.student.student_program) ?
          rowData.student.student_program.roll_no : ""}</span>;
      },
    },
    {
      title: 'IDNumber',
      render: rowData => {
        return <span>{rowData.student && rowData.student.id_number}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{rowData.student && rowData.student.first_name + " " + rowData.student.last_name}</span>;
      },
    },
    {
      title: 'Attendance',
      render: rowData => {
        return <span>
          {(rowData.att_label_id === 1) ?
            <span className="badge bg-success">{rowData.attendance_label.label_name}</span> : null}
          {(rowData.att_label_id === 2) ?
            <span className="badge bg-danger">{rowData.attendance_label.label_name}</span> : null}
          {(rowData.att_label_id === 3) ?
            <span className="badge bg-warning">{rowData.attendance_label.label_name}</span> : null}

          {(rowData.att_label_id === 4) ?
            <span className="badge bg-info">{rowData.attendance_label ? rowData.attendance_label.label_name : ''}</span> : null}
          {(rowData.att_label_id === 5) ?
            <span className="badge bg-secondary">{rowData.attendance_label.label_name}</span> : null}
          {(rowData.att_label_id === 6) ?
            <span className="badge bg-primary">{rowData.attendance_label.label_name}</span> : null}
          {(rowData.att_label_id === 7) ?
            <span className="badge bg-secondary">{rowData.attendance_label.label_name}</span> : null}
          {(rowData.att_label_id === 8) ?
            <span className="badge bg-secondary">{rowData.attendance_label.label_name}</span> : null}
          {(rowData.att_label_id === 9) ?
            <span className="badge bg-secondary">{rowData.attendance_label.label_name}</span> : null}
        </span>;
      },
    },
  ];
  return formatedData;
};
