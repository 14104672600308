import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getTemplates = async (page) => {
  try {
    const response = await axios.get(`${ENDPOINTS.TEMPLATE.GET}?page=${page}`);
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getTemplateById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.TEMPLATE.GET_TEMPLATE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createTemplate = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.TEMPLATE.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateTemplate = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.TEMPLATE.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteTemplate = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.TEMPLATE.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
