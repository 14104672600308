import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Card from '../../components/Card/Card';
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import { updatePassword } from '../../services/UserService';
import { useNavigate } from 'react-router-dom';

const UpdatePassword = () => {
  const [new_password, setNewPassword] = useState('');
  const [current_password, setCurrentPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async (event) => {
    event.preventDefault();
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = user.id;
    const data = { current_password, new_password };

    if (new_password.length < 6) {
      enqueueSnackbar('Password must be at least 6 characters long', { variant: 'error' });
      return;
    }

    try {
      setLoading(true);
      const response = await updatePassword(user_id, data);
      if (response.status === 200) {
        enqueueSnackbar('Password updated successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Password update failed', { variant: 'error' });
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Extract and display validation errors
        const errors = error.response.data.message.errors.toString();
        enqueueSnackbar(errors, { variant: 'error' });
      } else {
        // General error handling
        enqueueSnackbar('Password update failed', { variant: 'error' });
      }
      console.log(error);
      return;
    } finally {
      setLoading(false);
    }
  };


  const cardTitle = 'Update Password';

  return (
    <Card componentName={cardTitle} back={()=>navigate('/')}>
      {loading ? <Loader /> :
        <Form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-md-12">
              <Input
                label={'Current Password'}
                type={'password'}
                name={'current_password'}
                onChange={e => setCurrentPassword(e.target.value)}
                placeholder={'Enter current password'}
                value={current_password}
                required={true}
              />
            </div>
            <div className="col-md-12 mt-2">
              <Input
                label={'New Password'}
                type={'password'}
                name={'new_password'}
                onChange={e => setNewPassword(e.target.value)}
                placeholder={'Enter new password'}
                value={new_password}
                required={true}
              />
            </div>
            <div className="d-flex justify-content-end">
              <div className="mt-2">
                <Button variant="success"
                  type="submit" disabled={loading}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Form>
      }
    </Card>
  );
};

export default UpdatePassword;
