import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const AttendanceChart = ({ data }) => {
  const chartRef = useRef();

  useEffect(() => {
    const margin = { top: 20, right: 30, bottom: 80, left: 60 }; // Increased bottom margin
    const width = 1600 - margin.left - margin.right; // Increased width
    const height = 500 - margin.top - margin.bottom;

    // Remove any previous SVG elements
    d3.select(chartRef.current).select("svg").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Set up the scales
    const x = d3
      .scaleBand()
      .domain(data.map(d => d.program.name))
      .range([0, width])
      .padding(0.2); // Increased padding

    const y = d3.scaleLinear().domain([0, d3.max(data, d => Math.max(d.present, d.absent))]).nice().range([height, 0]);

    // Tooltip element
    const tooltip = d3
      .select(chartRef.current)
      .append("div")
      .style("position", "absolute")
      .style("background", "#f4f4f4")
      .style("padding", "5px")
      .style("border", "1px solid #ccc")
      .style("border-radius", "5px")
      .style("visibility", "hidden");

    const showTooltip = (event, d) => {
      tooltip
        .html(`Program: ${d.program.name}<br>Present: ${d.present}<br>Absent: ${d.absent}`)
        .style("top", `${event.pageY - 10}px`)
        .style("left", `${event.pageX + 10}px`)
        .style("visibility", "visible");
    };

    const hideTooltip = () => {
      tooltip.style("visibility", "hidden");
    };

    // Draw present bars
    svg.append("g")
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", d => x(d.program.name))
      .attr("y", d => y(d.present))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(d.present))
      .attr("fill", "#82ca9d")
      .on("mouseover", showTooltip)
      .on("mousemove", showTooltip)
      .on("mouseout", hideTooltip);

    // Draw absent bars
    svg.append("g")
      .selectAll(".bar-absent")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar-absent")
      .attr("x", d => x(d.program.name))
      .attr("y", d => y(d.absent))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(d.absent))
      .attr("fill", "#8884d8")
      .on("mouseover", showTooltip)
      .on("mousemove", showTooltip)
      .on("mouseout", hideTooltip);

    // Add the x-axis
    svg.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Add the y-axis
    svg.append("g").call(d3.axisLeft(y));

    // X-axis label
    svg.append("text")
      .attr("x", width / 2)
      .attr("y", height + margin.bottom - 10)
      .attr("text-anchor", "middle")
      .text("Program");

    // Y-axis label
    svg.append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -margin.left + 10)
      .attr("text-anchor", "middle")
      .text("Count");

    // Clean up the chart when the component is unmounted
    return () => {
      d3.select(chartRef.current).select("svg").remove();
      d3.select(chartRef.current).select("div").remove();
    };
  }, [data]);

  return (
    <div style={{ overflowX: "auto" }}>
      <div ref={chartRef}></div>
    </div>
  );
};

export default AttendanceChart;
