import React from "react";
import Switch from "../../../components/Switch/Switch";

export const tableConstants = (permissions, selectHandler) => {
  let count = 1;
  let formatedData = [
    {
      title: 'Sr.No',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'Student Name',
      render: rowData => {
        return <span>{`${rowData.first_name} ${rowData.last_name}`}</span>;
      },
    },
    {
      title: 'Admission NO',
      render: rowData => {
        return <span>{rowData.id_number}</span>;
      },
    },
    {
      title: 'Email',
      render: (rowData) => {
        return <span>{rowData.email}</span>;
      },
    },
    {
      title: 'Gender',
      render: rowData => {
        return <span>{rowData.gender}</span>;
      },
    },
    {
      title: 'Contact No',
      render: rowData => {
        return <span>{rowData.phone1}</span>;
      },
    },
  ];

  if (permissions && permissions.includes("publish.result.update")) {
    formatedData.push({
      title: 'Enroll',
      render: (rowData, index) => {
        if (index === 0) {
          return (
            <Switch
              name="selectAll"
              id="selectAll"
              checked={false} // You might want to change this based on your requirements
              onChange={(e) => {/* Handle select all logic here */ }}
              label="Select All"
            />
          );
        } else {
          return (
            <Switch
              name="student_ids"
              id={rowData.id}
              checked={rowData.checked}
              value={rowData.id}
              onChange={(e) => selectHandler(e, rowData)}
              label={rowData.first_name}
            />
          );
        }
      },
    });
  }
  return formatedData;
};
