import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getStudentSubjectMappingList = async (session_id, program_id, subject_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.STUDENT_SUBJECT_MAPPING.GET}`, { params: { program_id, subject_id, session_id } });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createStudentSubjectMapping = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.STUDENT_SUBJECT_MAPPING.CREATE, { data });
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStudentSubjectMapping = async (data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.STUDENT_SUBJECT_MAPPING.UPDATE}`, { data });
    return response;
  } catch (error) {
    throw error;
  }
};

