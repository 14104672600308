import React, { useState, useEffect } from "react";
import { FaChalkboardTeacher } from "react-icons/fa";
import { tableConstants } from "./EventTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import Modal from "../../../components/Modal/Modal";
import { enqueueSnackbar } from "notistack";
import EventForm from "./EventForm";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import {
  getEventCalendars, createEventCalendar,
  updateEventCalendar, deleteEventCalendar
} from "../../../services/AppManagement/EventCalendarService";

const EventList = () => {
  const [data, setData] = useState([]);
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [isLoader, setIsLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitile] = useState('');
  const [description, setDescription] = useState('');
  const [is_holiday, setIsHoliday] = useState('0');
  const [id, setId] = useState(null);
  const [event_date, setEventDate] = useState(null);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const changeHandler = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'title')
      setTitile(value);
    else if (name === 'event_date')
      setEventDate(value);
    else {
      if (checked)
        setIsHoliday('1');
      else
        setIsHoliday('0');
    }
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    console.log({ event, editor, data, description }, "editor");
    setDescription(data);
  };

  async function fetchEvent({ selected }) {
    try {
      setIsLoader(true);
      let page = selected + 1;
      const session_id = localStorage.getItem('session_id');
      const response = await getEventCalendars(session_id, page);
      if (response.status === 200) {
        setCurrentPage(page - 1);
        setLastPage(response.data.last_page);
        setData(response.data.data);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  useEffect(() => {
    fetchEvent({ selected: 0 });
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    const session_id = localStorage.getItem('session_id');
    const data = {
      title: title,
      description: description,
      is_holiday: is_holiday,
      session_id: session_id,
      event_date: event_date
    };

    try {
      setIsLoader(true);
      let response;
      if (id) {
        data.id = id;
        data.status = 1;
        response = await updateEventCalendar(id, data);
      } else {
        response = await createEventCalendar(data);
      }
      if (response.status === 200 || response.status === 201) {
        setTitile('');
        setDescription('');
        setIsHoliday(false);
        setId(null);
        setEventDate(null);
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        closeModal();
        fetchEvent({ selected: 0 });
      }
      else {
        enqueueSnackbar(response.data.message.error, { variant: 'error' });
      }
    } catch (error) {
      setIsLoader(false);
      console.error("API Error:", error);
      enqueueSnackbar("Something went wrong", { variant: 'error' });
    }
  };
  const showModel = (rowData) => {
    openModal(true);
    setId(rowData.id);
    setTitile(rowData.title);
    setDescription(rowData.description);
    setIsHoliday(rowData.is_holiday);
    setEventDate(rowData.event_date);
  }
  const EventHandler = () => {
    openModal(true);
    setTitile('');
    setDescription('');
    setId(null);
    setEventDate(null);
    setIsHoliday('0');
  }

  const deleteHandler = async (rowData) => {
    try {
      setIsLoader(true);
      const response = await deleteEventCalendar(rowData.id);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        fetchEvent({ selected: 0 });
      }
    } catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }

  return (
    <div className="card">
      <CardHeader
        title="Event"
        icon={<FaChalkboardTeacher />}
        button={permissions.includes('event.calendar.create') &&
          <button className="btn btn-primary" onClick={EventHandler}>Add Event</button>}
      />
      <div className="card-body">
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <h5>{id ? "Update Event" : "Add Event"}</h5>
          <EventForm
            changeHandler={changeHandler}
            submitHandler={submitHandler}
            handleEditorChange={handleEditorChange}
            title={title}
            description={description}
            is_holiday={is_holiday}
            event_date={event_date}
          />
        </Modal>
        {isLoader ? <Loader /> :
          <div className="row mt-3">
            {data && (
              <React.Fragment>
                <Table
                  cols={tableConstants(permissions, deleteHandler, showModel)}
                  data={data}
                  hover={true}
                />
                <Pagination
                  totalPage={lastPage}
                  currentPage={currentPage}
                  handlePageChange={(i) => fetchEvent(i)}
                ></Pagination>
              </React.Fragment>
            )}
          </div>
        }
      </div>
    </div>
  );
};
export default EventList;
