import {
    FETCH_EVENTS_FAILURE,
    FETCH_EVENTS_REQUEST,
    FETCH_EVENTS_SUCCESS
} from "./eventTypes";
import axios from 'axios';

export const fetchEventsRequest = () => {
    return {
        type: FETCH_EVENTS_REQUEST
    }
}
export const fetchEventsSuccess = events => {
    return {
        type: FETCH_EVENTS_SUCCESS,
        payload: events
    }
}
export const fetchEventsFailure = error => {
    return {
        type: FETCH_EVENTS_FAILURE,
        payload: error
    }
}
export const fetchEvents = () => {
    return (dispatch) => {
        dispatch(fetchEventsRequest)
        const session_id = localStorage.getItem('session_id');
        axios.get(`/events?session_id=${session_id}`)
            .then(response => {
                const events = response.data;
                dispatch(fetchEventsSuccess(events));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchEventsFailure(errorMsg))
            })
    }
}
