import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getRolePermissions = async (page) => {
  try {
    const response = await axios.get(`${ENDPOINTS.ROLE_PERMISSION.GET}?page=${page}`);
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getRolePermissionById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.ROLE_PERMISSION.GET_ROLE_PERMISSION}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createRolePermission = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.ROLE_PERMISSION.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateRolePermission = async (id, data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.ROLE_PERMISSION.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteRolePermission = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.ROLE_PERMISSION.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getPermissions = async () => {
  try {
    const response = await axios.get(ENDPOINTS.ROLE_PERMISSION.GET_PERMISSION);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getPermissionsByUser = async () => {
  try {
    const response = await axios.get(`${ENDPOINTS.ROLE_PERMISSION.GET_PERMISSION_BY_USER}`);
    return response;
  } catch (error) {
    throw error;
  }
}
