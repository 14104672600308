import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import LocalStorageService from "../../services/LocalStorageServices";
import loginImg from "../../assets/image/evalution-icon.png";
import baseURL from '../../config';
import { enqueueSnackbar } from "notistack";

const localStorageService = LocalStorageService.getService();

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPassword, setIsPassword] = useState(true);
  const [loading, setLoading] = useState(false);

  const handlerSubmit = async (event) => {
    event.preventDefault();
    if (email === "") {
      setEmailError("Email is required");
      enqueueSnackbar("Email is required", { variant: "error" });
      return;
    }
    else if (email !== "") {
      setEmailError("");
    }
    else if (password === "") {
      enqueueSnackbar("Password is required", { variant: "error" });
      setPasswordError("Password is required");
      return;
    }
    else if (password !== "") {
      setPasswordError("");
    }

    try {
      setLoading(true);
      const response = await axios.post(`${baseURL}/api/auth/v1/login`, {
        email,
        password,
      });
      if (response.status === 200) {
        let perimission_list = response.data.data.permissions
        let permissions = perimission_list.map(item => item.permission);
        localStorage.setItem("user", JSON.stringify(response.data.data.user));
        localStorage.setItem("organization_id", response.data.data.roles[0].organization_id);
        localStorage.setItem("roles", JSON.stringify(response.data.data.roles));
        localStorage.setItem("permissions", JSON.stringify(permissions));
        localStorageService.setToken(response.data.data);
        localStorage.setItem("token", response.data.data.token);
        window.location.href = "/";
      }
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.message.errors;
        let emailError = "",
          passwordError = "";
        for (const key in errors) {
          if (key === "email") emailError = errors[key];
          else passwordError = errors[key];
        }
        setEmailError(emailError);
        setPasswordError(passwordError);
      }
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center"
      style={{ marginTop: "10%" }}>
      <div className="container row justify-content-center">
        <div className="card col-md-8 justify-content-center">
          <Row>
            <Col md="6" className="text-white py-5 d-md-down-none bg_primary_gradient">
              <div className="card-body d-flex align-items-center justify-content-center">
                <img
                  src={loginImg}
                  alt="icon"
                  style={{ maxWidth: "60%", height: "auto" }}
                />
              </div>
            </Col>
            <Col md="6" className="p-0">
              <div>
                <div className="card-body">
                  <div style={{ color: "red", float: "right" }}>
                    {emailError}
                    <br></br>
                    {passwordError}
                  </div>
                  <Form>
                    <h1 className="pb-3">Login</h1>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend style={{ borderRadius: "0px" }}>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUser}
                            style={{ fontSize: '24px' }} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type="email"
                        placeholder="Enter Email"
                        autoComplete="email"
                        className="form-control"
                        onChange={(event) => setEmail(event.target.value)}
                        required
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon
                            style={{ cursor: "pointer", fontSize: '24px' }}
                            icon={isPassword ? faEye : faEyeSlash}
                            onClick={() => setIsPassword(!isPassword)}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <input
                        type={isPassword ? "password" : "text"}
                        placeholder="Password"
                        className="form-control"
                        autoComplete="current-password"
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </InputGroup>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading}
                      onClick={handlerSubmit}
                      className="px-4 mb-5 btn-primary"
                    >
                      Login
                    </Button>
                    <Row>
                      {loading ? (<Spinner
                        animation="border"
                         variant="primary" />): null}
                    </Row>
                    <div className="col-md-12 mt-2">
                      <div className="row p-3 border mt-0">
                        <div className="d-flex justify-content-center">
                          <span>For any queries, email us at <strong className="brand-title">ets+app@teams.apeejay.edu</strong></span>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Login;
