import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Table from "../../components/Table/Table";
import { FaLaptop } from "react-icons/fa";
import { tableConstants } from "./templateTableConstant";
import Loader from '../../components/Loader/Loader';
import { enqueueSnackbar } from 'notistack';
import { getTemplates, deleteTemplate } from '../../services/TemplateService';
import CardHeader from '../../components/Card/CardHeader';
import Pagination from '../../components/Pagination/Pagination';

const TemplateList = () => {
  const [templatesData, setTemplatesData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const fetchTemplates = async ({ selected }) => {
    const page = selected + 1;
    setCurrentPage(selected);
    try {
      setIsLoader(true);
      const templatesResponse = await getTemplates(page);
      if (templatesResponse.status === 200) {
        const templatesData = templatesResponse.data.data;
        setLastPage(templatesResponse.data.last_page);
        setTemplatesData(templatesData);
        setIsLoader(false);
      }
    } catch (error) {
      setIsLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTemplates({ selected: 0 });
  }, []);

  const templateDelete = async (id) => {
    try {
      const response = await deleteTemplate(id);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        fetchTemplates();
      }
    } catch (error) {
      // Handle error
      return error;
    }
  };

  return (
    <React.Fragment>
      {isLoader ? (
        <Loader />
      ) : (
        <div className="card">
          <CardHeader
            title="Templates"
            icon={<FaLaptop />}
            button={permissions.includes("template.create") && (
              <Link className='btn btn-primary' to="/template/create">
                Add Template
              </Link>
            )}
          />
          <div className="card-body evaluating-table">
            <Table
              cols={tableConstants(templateDelete, permissions)}
              data={templatesData}
              hoverable={true}
              striped={true}
            />
            <Pagination
              totalPage={lastPage}
              currentPage={currentPage}
              handlePageChange={(i) => fetchTemplates(i)}
            ></Pagination>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TemplateList;
