import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from '../../../redux/program/programActions';
import { Link } from "react-router-dom";
import { FaBolt } from "react-icons/fa";
import { tableConstants } from "./LeaveTableConstant";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import Modal from "../../../components/Modal/Modal";
import { enqueueSnackbar } from "notistack";
import LeaveFilter from "./LeaveFilter";
import LeaveApproval from "./LeaveApproval";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { getLeaves, leaveApproval } from "../../../services/AppManagement/LeaveService";

const LeaveList = () => {
  const [data, setData] = useState([]);
  const [program_id, setProgramId] = useState('');
  const [leave_date, setLeaveDate] = useState('');
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [status, setStatus] = useState('');
  const [reject_reason, setRejectReason] = useState('');
  const [leave_id, setLeaveId] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const dispatch = useDispatch();
  const programData = useSelector(state => state.program.programs.data);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramId(value);
    }
    if (name === 'leave_date') {
      setLeaveDate(value);
    }
    if (name === 'status') {
      setStatus(value);
    }
  }

  async function fetchLeave({ selected }) {
    const session_id = localStorage.getItem('session_id');
    setCurrentPage(selected);
    const page = selected + 1;
    try {
      setIsLoader(true);
      const response = await getLeaves(program_id, session_id, leave_date, status, page);
      setIsLoader(false);
      if (response.status === 200) {
        setData(response.data.data.data);
        setLastPage(response.data.data.last_page);
      }
      else {
        enqueueSnackbar("Leave Fetching Failed", { variant: 'error' });
        setData([]);
        return;
      }
    }
    catch (error) {
      setIsLoader(false);
      console.error("API Error:", error.response);
    }
  }
  useEffect(() => {
    fetchLeave({ selected: 0 });
    dispatch(fetchPrograms());
  }, []);

  const approverHandler = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === 'status') {
      setStatus(value);
    }
    if (name === 'reject_reason') {
      setRejectReason(value);
    }
  }

  const leaveApproverHandler = async (e) => {
    e.preventDefault();
    const session_id = localStorage.getItem('session_id');
    const data = {
      status: status,
      reject_reason: reject_reason,
      session_id: session_id
    };
    try {
      setIsLoader(true);
      const response = await leaveApproval(leave_id, data);
      if (response.status === 200) {
        enqueueSnackbar("Leave Verified Successfully", { variant: 'success' });
        closeModal();
        fetchLeave({ selected: 0 });
        setStatus('');
        setRejectReason('');
        setLeaveId('');
      }
      else if (response.status === 422) {
        enqueueSnackbar("Leave Verification Failed", { variant: 'error' });
      }
      else {
        enqueueSnackbar("Leave Verification Failed", { variant: 'error' });
      }
    }
    catch (error) {
      setIsLoader(false);
    }
    finally {
      setIsLoader(false);
    }
  }

  const showModel = (rowData) => {
    openModal(true);
    if (rowData.status === 'pending') {
      setStatus();
      setRejectReason();
    }
    else {
      setStatus(rowData.status);
      setRejectReason(rowData.reject_reason);
    }
    setLeaveId(rowData.id);
  }


  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="LeaveList"
          icon={<FaBolt />}
          button={
            permissions.includes('leave.create') &&
            <Link to="/leave/create" className="btn btn-primary">Add Leave</Link>
          }
        />
        <div className="card-body">
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <h4>Leave Verification</h4>
            <LeaveApproval
              leaveApproverHandler={leaveApproverHandler}
              approverHandler={approverHandler}
              status={status}
              reject_reason={reject_reason}
            />
          </Modal>
          <LeaveFilter
            changeHandler={changeHandler}
            fetchLeave={fetchLeave}
            programData={programData}
            program_id={program_id}
            leave_date={leave_date}
            status={status}
          />
          {isLoader ? <Loader /> :
            <div className="row mt-3">
              {data && (
                <React.Fragment>
                  <Table
                    cols={tableConstants(permissions, showModel)}
                    data={data}
                    hover={true}
                  />
                  <Pagination
                    totalPage={lastPage}
                    currentPage={currentPage}
                    handlePageChange={(i) => fetchLeave(i)}
                  ></Pagination>
                </React.Fragment>
              )}
            </div>}
        </div>
      </div>
    </div>
  );
};
export default LeaveList;
