import React from 'react';
import ExcelBulkUpload from '../../ExcelBulkUpload/ExcelBulkUpload';

const WordOfTheDayBulkUploadForm = ({
  downloadExcel,
  excelFileReader,
  bulkUpload,
  loader
}) => {
  return (
    <ExcelBulkUpload
      downloadExcel={downloadExcel}
      excelFileReader={excelFileReader}
      bulkUploadFile={bulkUpload}
      loader={loader}
    />);
};

export default WordOfTheDayBulkUploadForm;
