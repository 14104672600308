import React from 'react';
import { Form } from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinus } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from 'react-router-dom';
import Select from '../../../components/Select/Select';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const SyllabusForm = ({ changeHandler, submitHandler, fileUploadHandler, id,
  classAddHandler, classRemoveHandler, programData, subjectData, program_ids, data, addRow, clickOnDelete }) => {

  return (
    <Form onSubmit={submitHandler}>
      <div className="col-md-12">
        <Form.Group>
          <Form.Label>Class</Form.Label>
          <Multiselect
            name={`program_ids`}
            options={programData}
            id={program_ids}
            onRemove={(e) => classRemoveHandler(e)}
            displayValue="name"
            showCheckbox={true}
            selectedValues={program_ids}
            onSelect={(e) => classAddHandler(e)}
          />
        </Form.Group>
      </div>
      {data && data.map((val, idx) => {
        return (
          <div className="row mt-2 syllbus" key={idx}>
            <div className={id ? "col-md-6" : "col-md-6"}>
              <Select
                label="Subject"
                name="subject_id"
                value={val.subject_id}
                onChangeHandler={(e) => changeHandler(e, idx)}
                options={subjectData}
                placeHolder="Select Subject"
              />
            </div>
            <div className={id ? "col-md-6" : "col-md-5"}>
              <Input
                label="Syllabus Docs File"
                type="file"
                name="syllabus_docs"
                onChangeHandler={(e) => fileUploadHandler(e, idx)}
              />
              {id && <Link to={val.syllabus_docs} target="_blank">View File</Link>}
            </div>
            {!id &&
              <div className="col-md-1">
                {
                  idx === 0 ? <button onClick={addRow} type="button"
                    className="btn btn-primary text-center mt-4">
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </button>
                    : <button className="btn btn-danger mt-4"
                      onClick={(e) => clickOnDelete(e, val)} >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                }
              </div>
            }
            <div className="col-md-12 mt-2">
              <Form.Group>
                <Form.Label>Syllabus Description</Form.Label>
              </Form.Group>
              <CKEditor
                editor={ClassicEditor}
                data={val.syllabus_desc}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  changeHandler({ target: { name: 'syllabus_desc', value: data } }, idx);
                }}
              />
            </div>
          </div>
        )
      })
      }
      <div className="row d-flex justify-content-end mt-3">
        <div className="col-md-1">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};
export default React.memo(SyllabusForm);
