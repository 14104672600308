import React, { useState, useEffect, useCallback } from 'react';
import Card from '../../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import SyllabusForm from './SyllabusForm';
import { getPrograms } from '../../../services/ProgramServices';
import { getSubjects } from '../../../services/SubjectServices';
import { getSyllabusById, createSyllabus, updateSyllabus }
  from '../../../services/AppManagement/SyllabusService';


const SyllabusManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [syllabus_docs, setSyllabusDocs] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [program_ids, setProgramIds] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [data, setData] = useState([{ subject_id: '', syllabus_desc: '' }]);
  const id = params.id;

  const getUniqueSyllabus = useCallback(async () => {
    try {
      const response = await getSyllabusById(id);
      if (response.status === 200) {
        const data = response.data.data;
        const obj = { subject_id: data.subject_id, syllabus_desc: data.syllabus_desc, syllabus_docs: data.syllabus_docs };
        setData([obj]);
        const programs = data.syllabus_programs.map((val) => val.program);
        setProgramIds(programs);
        setSyllabusDocs(data.syllabus_docs);
      }
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  const changeHandler = useCallback((event, idx) => {
    const { name, value } = event.target;
    setData((prevData) => {
      const list = [...prevData];
      list[idx][name] = value;
      return list;
    });
  }, []);

  const fetchSubjects = useCallback(async () => {
    try {
      const res = await getSubjects();
      const subjectData = res.data;
      setSubjectData(subjectData);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const fetchPrograms = useCallback(async () => {
    try {
      const response = await getPrograms();
      if (response.success) {
        setProgramData(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const classAddHandler = useCallback((e) => {
    setProgramIds([...e]);
  }, []);

  const classRemoveHandler = useCallback((e) => {
    setProgramIds([...e]);
  }, []);

  useEffect(() => {
    fetchSubjects();
    fetchPrograms();
    if (id) {
      getUniqueSyllabus();
    }
  }, [fetchSubjects, fetchPrograms, getUniqueSyllabus, id]);

  const fileUploadHandler = useCallback((e) => {
    const files = Array.from(e.target.files);
    setSyllabusDocs(files);
  }, []);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');
    const formData = new FormData();
    formData.append('session_id', session_id);
    program_ids.forEach((item, idx) => {
      formData.append(`program_ids[${idx}]`, item.id);
    });

    if (id) {
      formData.append('id', id);
      formData.append('syllabus_docs', syllabus_docs[0]);
      formData.append('data', JSON.stringify(data[0]));
    } else {
      syllabus_docs.forEach((doc, index) => {
        formData.append(`syllabus_docs[${index}]`, doc);
      });
      formData.append('data', JSON.stringify(data));
    }

    try {
      const response = id
        ? await updateSyllabus(id, formData)
        : await createSyllabus(formData);
      if (response.status === 200) {
        const msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        navigate('/syllabus/list');
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, program_ids, syllabus_docs, data, navigate]);

  const addNewRow = useCallback(() => {
    setData((prevState) => ([...prevState, { subject_id: '', syllabus_desc: '' }]));
  }, []);

  const clickOnDelete = useCallback((e, record) => {
    e.preventDefault();
    e.stopPropagation();
    setData((prevState) => (prevState.filter((r) => r !== record)));
  }, []);

  const back = useCallback(() => {
    navigate('/syllabus/list');
  }, [navigate]);

  return (
    <Card componentName={id ? 'Update Syllabus' : 'Add Syllabus'} back={back}>
      <SyllabusForm
        submitHandler={handleSubmit}
        changeHandler={changeHandler}
        fileUploadHandler={fileUploadHandler}
        classAddHandler={classAddHandler}
        classRemoveHandler={classRemoveHandler}
        addRow={addNewRow}
        clickOnDelete={clickOnDelete}
        id={id}
        data={data}
        programData={programData}
        program_ids={program_ids}
        syllabus_docs={syllabus_docs}
        subjectData={subjectData}
      />
    </Card>
  );
};

export default SyllabusManage;
