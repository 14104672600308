import React from 'react';
import { Form } from "react-bootstrap";

const PromoteFilter = ({ setSessionId, setProgramId, sessionData, programList }) => {
  return (
    <React.Fragment>
      <div className="col-md-5">
        <Form.Group>
          <Form.Label>Session<i className="text-danger">*</i></Form.Label>
          <select
            name='session_id'
            id="session_id"
            className='form-select form-control cursor-pointer'
            onChange={(event) => setSessionId(event.target.value)}
          >
            <option value="">Select Session</option>
            {sessionData && sessionData.length > 0 && (
              sessionData.map((session, index) =>
                <option value={session.id} key={index}>
                  {session.name}
                </option>)
            )}
          </select>
        </Form.Group>
      </div>
      <div className="col-md-5">
        <Form.Group>
          <Form.Label>Class<i className="text-danger">*</i></Form.Label>
          <select
            name='program_id'
            id="program_id"
            className='form-select form-control cursor-pointer'
            onChange={(event) => setProgramId(event.target.value)}
          >
            <option value="">Select Class</option>
            {programList && programList.length > 0 && (
              programList.map((program, index) =>
                <option value={program.id} key={index}>
                  {program.name}
                </option>)
            )}
          </select>
        </Form.Group>
      </div>
    </React.Fragment>
  );
};

export default PromoteFilter;
