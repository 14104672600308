import { ENDPOINTS } from "../endpoints";
import axios from "axios";

export const getCircularById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.CIRCULAR.GET}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getCirculars = async (session_id, page) => {
  try {
    const response = await axios.get(ENDPOINTS.CIRCULAR.GET, {
      params: {
        session_id: session_id,
        page: page
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
}

export const createCircular = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.CIRCULAR.CREATE, data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateCircular = async (id, data) => {
  try {
    const response = await axios.post(`${ENDPOINTS.CIRCULAR.UPDATE}/${id}`, data,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCircular = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.CIRCULAR.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
