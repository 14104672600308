import React, { useEffect, useState } from 'react';
import { fetchPrograms } from '../../redux/program/programActions';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { FaFolderOpen } from "react-icons/fa";
import Table from '../../components/Table/Table';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import { tableConstants } from './StudentTableConstant';
import { enqueueSnackbar } from 'notistack';
import {
  getStudents, getFilteredStudents, deleteStudent,
  updateStudentStatus, generateActivationKey
} from '../../services/StudentService';
import CardHeader from '../../components/Card/CardHeader';
import StudentFilter from './StudentFilter';
import * as XLSX from 'xlsx';

const StudentList = () => {
  const [name, setName] = useState('');
  const [id_number, setIdNumber] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [program_id, setProgramId] = useState('');
  const [loader, setLoader] = useState(false);
  const session_id = localStorage.getItem("session_id");
  const permissions = JSON.parse(localStorage.getItem("permissions"))
  const dispatch = useDispatch();
  const { programs } = useSelector((state) => state.program);
  const programList = programs.data;

  const searchHandler = async () => {
    try {
      setLoader(true);
      setStudentList([]);
      const response = await getFilteredStudents(session_id, name, id_number, program_id);
      if (response.status === 200) {
        const data = response.data;
        setStudentList(data.data);
        setLastPage(data.last_page);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  const getStudentList = async ({ selected }) => {
    let page = selected + 1;
    try {
      setLoader(true);
      const response = await getStudents(page, session_id);
      setLoader(false);
      if (response.status === 200) {
        const data = response.data;
        setStudentList(data.data);
        setLastPage(data.last_page);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getStudentList({ selected: 0 });
    dispatch(fetchPrograms({ selected: 0 }));
  }, []);

  const studentDelete = async (id) => {
    try {
      setLoader(true);
      const response = await deleteStudent(id);
      if (response.status === 200) {
        const data = response.data;
        enqueueSnackbar(data.message.success, { variant: "success" });
        getStudentList({ selected: 0 });
      }
      setLoader(false);
    } catch (error) {
      console.error('Error deleting student:', error);
      setLoader(false);
    }
  }

  const statusHandler = async (id, status) => {
    try {
      const session_id = localStorage.getItem("session_id");
      setLoader(true);
      let data = { id, suspended: status, session_id };
      const response = await updateStudentStatus(data)
      const data1 = response.data;
      if (response.status === 200) {
        enqueueSnackbar(data1.message.success, { variant: "success" });
        if (program_id !== '')
          searchHandler();
        else
          getStudentList({ selected: 0 });
      }
    } catch (error) {
      // Handle the error here
      console.error('Error updating student status:', error);
      // You might want to do something else here, like displaying an error message to the user
    }
    finally {
      setLoader(false);
    }
  };
  const resetHandler = async () => {
    setName('');
    setIdNumber('');
    setProgramId('');
    getStudentList({ selected: 0 });
  }

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(studentList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `student-list.xlsx`);
  };

  const genrateKeyHandler = async () => {
    if (program_id === '') {
      enqueueSnackbar("Please select class", { variant: "error" });
      return;
    }
    try {
      setLoader(true);
      const response = await getFilteredStudents(session_id, name, id_number, program_id);
      const studentIds = response.data.data.map(student => student.activation_key ? "" : student.id);
      const data = { student_ids:studentIds, session_id };
      const response1 = await generateActivationKey(data);
      console.log(response1,"response1");
      if (response1.status === 200) {
        const data = response1.data;
        enqueueSnackbar(data.message.success, { variant: "success" });
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoader(false);
    }
  }

  return (
    <div className='card'>
      {loader ? (
        <Loader />
      ) : (
        <div className="program-list">
          <CardHeader
            title="Students"
            icon={<FaFolderOpen />}
            button={<React.Fragment>
              <button className="btn btn-primary mx-2" onClick={downloadExcel}>
                Download Excel
              </button>
              {
                permissions.includes("student.create") &&
                <Link className="btn btn-primary" to={"/student/create"}>
                  Add Student
                </Link>
              }
            </React.Fragment>
            }
          />
          <div className="card-body">
            <StudentFilter
              programList={programList}
              name={name}
              id_number={id_number}
              program_id={program_id}
              setName={setName}
              setIdNumber={setIdNumber}
              setProgramId={setProgramId}
              searchHandler={searchHandler}
              resetHandler={resetHandler}
              genrateKeyHandler={genrateKeyHandler}
            />
            <div className='evaluating-table mt-2'>
              {studentList && studentList.length > 0 ?
                <Table
                  cols={tableConstants(permissions, statusHandler, studentDelete)}
                  data={studentList}
                  striped={true}
                  hoverable={true}
                /> : 'No data found'}
            </div>
          </div>
        </div>
      )}
      <div className="card-footer">
        {studentList && studentList.length ? (
          <Pagination
            totalPage={lastPage}
            handlePageChange={(i) => getStudentList(i)}
          ></Pagination>)
          : null}
      </div>
    </div>
  );
};
export default StudentList;
