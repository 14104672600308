import {
    FETCH_SESSIONS_FAILURE,
    FETCH_SESSIONS_REQUEST,
    FETCH_SESSIONS_SUCCESS
} from "./sessionTypes"

const initialState = {
    loading: false,
    sessions: [],
    error: ''
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SESSIONS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_SESSIONS_SUCCESS:
            return {
                loading: false,
                sessions: action.payload,
                error: '',
            }
        case FETCH_SESSIONS_FAILURE:
            return {
                loading: false,
                sessions: [],
                error: action.payload
            }
        default: return state;
    }
}
export default reducer;
