import React, { useState, useEffect } from 'react';
import Card from '../../../components/Card/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import NewsLetterForm from './NewsLetterForm';
import { getNewsLetterById, createNewsLetter, updateNewsLetter } from '../../../services/AppManagement/NewsLetterService';

const NewsLetterManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [issue, setIssue] = useState('');
  const [title, setTitle] = useState('');
  const [session, setSession] = useState('');
  const [newsletter_file, setNewsletterFile] = useState('');
  const id = params.id;

  useEffect(() => {
    if (id) {
      getUniqueNewsLetter();
    }
  }, []);

  const getUniqueNewsLetter = async () => {
    try {
      const response = await getNewsLetterById(id);
      if (response.status === 200) {
        const data = response.data.data;
        setTitle(data.title);
        setIssue(data.issue);
        setSession(data.session);
        setNewsletterFile(data.newsletter_file);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const changeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'title') {
      setTitle(value);
    }
    else if (name === 'issue') {
      setIssue(value);
    }
    else if (name === 'session') {
      setSession(value);
    }
  };

  const fileUploadHandler = (e) => {
    const file = e.target.files[0];
    setNewsletterFile(file);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const session_id = localStorage.getItem('session_id');
    const formData = new FormData();
    formData.append('title', title);
    formData.append('issue', issue);
    formData.append('session', session);
    formData.append('newsletter_file', newsletter_file);
    formData.append('session_id', session_id);

    try {
      const response = id
        ? await updateNewsLetter(id, formData)
        : await createNewsLetter(formData);
      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        navigate('/newsletter/list');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const back = () => {
    navigate('/newsletter/list');
  };

  return (
    <Card componentName={id ? 'Update NewsLetter' : 'Add NewsLetter'} back={back}>
      <NewsLetterForm
        submitHandler={handleSubmit}
        changeHandler={changeHandler}
        fileUploadHandler={fileUploadHandler}
        issue={issue}
        title={title}
        session={session}
        newsletter_file={newsletter_file}
        id={id}
      />
    </Card>
  );
};
export default NewsLetterManage;
