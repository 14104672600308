import {
    FETCH_EVALUATIONTYPES_FAILURE,
    FETCH_EVALUATIONTYPES_REQUEST,
    FETCH_EVALUATIONTYPES_SUCCESS
} from "./evaluationTypeTypes";
import axios from 'axios';

export const fetchEvaluationTypesRequest = () => {
    return {
        type: FETCH_EVALUATIONTYPES_REQUEST
    }
}
export const fetchEvaluationTypesSuccess = evaluationTypes => {
    return {
        type: FETCH_EVALUATIONTYPES_SUCCESS,
        payload: evaluationTypes
    }
}
export const fetchEvaluationTypesFailure = error => {
    return {
        type: FETCH_EVALUATIONTYPES_FAILURE,
        payload: error
    }
}
export const fetchEvaluationTypes = (page=1) => {
    return (dispatch) => {
        dispatch(fetchEvaluationTypesRequest)
        axios.get(`/evaluation_types?page=${page}`)
            .then(response => {
                const evaluationTypes = response.data;
                dispatch(fetchEvaluationTypesSuccess(evaluationTypes));
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch(fetchEvaluationTypesFailure(errorMsg))
            })
    }
}