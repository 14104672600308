import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchSessions } from '../../redux/session/sessionActions';
import Card from '../../components/Card/Card';
import SessionForm from './SessionForm';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack'
import { createSession, getSessionById, updateSession } from '../../services/SessionService';

const SessionCreate = ({ fetchSessions }) => {
  const [sessionData, setSessionData] = useState({
    id: null,
    name: '',
    start: '',
    end: '',
    disableSubmit: true,
  });
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getUniqueSession();
    }
  }, [params.id]);

  const getUniqueSession = async () => {
    try {
      const response = await getSessionById(params.id);
      if (response.status === 200) {
        const { id, name, start, end } = response.data.data;
        setSessionData({ ...sessionData, id, name, start, end });
      }
    } catch (error) {
      setSessionData({ ...sessionData, disableSubmit: true });
      return error;
    }
  };

  const handleChange = (event) => {
    setSessionData({
      ...sessionData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSessionData({ ...sessionData, disableSubmit: false });

    const { name, start, end, id } = sessionData;
    const organization_id = localStorage.getItem('organization_id');
    const user = JSON.parse(localStorage.getItem('user'));
    const user_id = user.id;
    const requestData = { name, start, end, organization_id, user_id };

    try {
      const response = id ? await updateSession(id, requestData) : await createSession(requestData);
      if (response.status === 200) {
        let msg = response.data.message.success;
        enqueueSnackbar(msg, { variant: 'success' });
        fetchSessions();
        navigate('/session/list');
      }
    } catch (error) {
      setSessionData({ ...sessionData, disableSubmit: true });
      return error;
    }
    finally {
      setSessionData({ ...sessionData, disableSubmit: true });
    }
  };

  const back = () => {
    navigate('/session/list');
  };

  return (
    <Card componentName={sessionData.id ? 'Update Session' : 'Add Session'} back={back}>
      <SessionForm
        changeHandler={handleChange}
        submitHandler={handleSubmit}
        data={sessionData} />
    </Card>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchSessions: () => dispatch(fetchSessions()),
});

export default connect(null, mapDispatchToProps)(SessionCreate);
