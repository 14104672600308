import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { FaFolderOpen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import Table from '../../../components/Table/Table';
import { tableConstants } from './SyncStudentsTableConstant';
import { Button } from 'react-bootstrap';

const SyncStudentList = () => {
  const [dataLakeStudents, setDataLakeStudents] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [loader, setLoader] = useState(false);
  const permissions = JSON.parse(localStorage.getItem('permissions'));

  const selectHandler = (e, student) => {
    if (e.target.checked) {
      setStudentList([...studentList, student]);
    }
    else {
      setStudentList(studentList.filter((id) => id !== student));
    }
  };

  const getSyncStudents = () => {
    setLoader(true);
    axios.get('/sync/students')
      .then((response) => {
        setLoader(false);
        const data = response.data;
        if (response.status === 200) {
          setDataLakeStudents(data.data);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        console.log(error);
      });
  };

  useEffect(() => {
    getSyncStudents();
  }, []);

  const enrollHandler = (e) => {
    e.preventDefault();
    axios.post('/sync/students/enroll', { data: studentList })
      .then((response) => {
        if (response.status === 200) {
          getSyncStudents();
          setStudentList([]);
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        console.log(error);
      });
  }

  return (
    <div className='card'>
      {loader ?
        <Loader />
        :
        <div className="program-list">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title-page page-title d-flex align-items-center">
                <div className="mx-2">
                  <FaFolderOpen />
                </div>
                <span>Students</span>
              </div>
              {permissions&&permissions.includes("student.create") ?
                <div className="add-btn">
                  <Link style={{ color: "#FFFFFF" }} to={"/student/create"}>
                    <button className="btn btn-primary">Add Student</button>
                  </Link>
                </div> : ''}
            </div>
          </div>
          <div className="card-body">
            <Table
              cols={tableConstants(permissions, selectHandler)}
              data={dataLakeStudents}
              hoverable={true}
              striped={true}
            />
            <div className="d-flex justify-content-end">
            <Button variant="primary" onClick={enrollHandler}>Enroll</Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default SyncStudentList;
