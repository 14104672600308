import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from '../../../components/Select/Select';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const LeaveForm = ({ submitHandler, changeHandler, fileUploadHandler, id, leave_docs,
  programData, program_id, studentList, student_id, from_date, to_date, apply_reason }) => {
  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-6">
          <Select
            label="Class"
            name="program_id"
            value={program_id}
            options={programData}
            onChangeHandler={changeHandler}
            placeHolder="Select Class"
          />
        </div>
        <div className="col-md-6">
          <Select
            label="Student"
            name="student_id"
            value={student_id}
            options={studentList}
            onChangeHandler={changeHandler}
            placeHolder="Select Student"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <Input
            label="From Date"
            name="from_date"
            value={from_date}
            type="date"
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Input
            label="To Date"
            name="to_date"
            value={to_date}
            type="date"
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>Reason</Form.Label>
            <textarea name="apply_reason" id="apply_reason"
              className="form-control cursor-pointer"
              value={apply_reason}
              placeholder='Apply Reason'
              onChange={changeHandler}
              required />
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Input
            label="Leave Document"
            name="leave_docs"
            type="file"
            onChangeHandler={fileUploadHandler}
          />
          {id && <Link to={leave_docs} target="_blank">View Document</Link>}
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Button
            variant="primary"
            type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default LeaveForm;
