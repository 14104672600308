import React from "react";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export const tableConstants = (permissions, showModel) => {
  let count = 1;
  let formatedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'RollNo',
      render: rowData => {
        return <span>{rowData.student_program ? rowData.student_program.roll_no : null}</span>;
      },
    },
    {
      title: 'IDNumber',
      render: rowData => {
        return <span>{rowData.student.id_number}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{rowData.student.first_name + " " + rowData.student.last_name}</span>;
      },
    },
    {
      title: 'class',
      render: rowData => {
        return <span>{rowData.program.name}</span>;
      },
    },
    {
      title: 'From_Date',
      render: rowData => {
        return <span>{rowData.from_date}</span>;
      },
    },
    {
      title: 'To_Date',
      render: rowData => {
        return <span>{rowData.to_date}</span>;
      },
    },
    {
      title: 'Applied_Date',
      render: rowData => {
        return <span>{rowData.created_at}</span>;
      },
    },
    {
      title: 'Reason_for_leave',
      render: rowData => {
        return <span>{rowData.apply_reason}</span>;
      },
    },
    {
      title: 'Remark',
      render: rowData => {
        return <span>{rowData.reject_reason}</span>;
      },
    },
    {
      title: 'Response_Date',
      render: rowData => {
        return <span>{rowData.updated_at}</span>;
      },
    },
    {
      title: 'Status',
      render: rowData => {
        return <span>
          {(rowData.status === 'pending') ?
            <span className="badge bg-warning">{rowData.status}</span> : null}
          {(rowData.status === 'approved') ?
            <span className="badge bg-success">{rowData.status}</span> : null}
          {(rowData.status === 'rejected') ?
            <span className="badge bg-danger">{rowData.status}</span> : null}
        </span>;
      },
    },
  ];

  if (permissions.includes('leave.update') || permissions.includes('leave.approval')) {
    formatedData.push(
      {
        title: 'Action',
        render: rowData => {
          return <span>
            {permissions.includes('leave.update') &&
              <Link to={`/leave/update/${rowData.id}`}>
                <FontAwesomeIcon
                  icon={faEdit}
                  color="#321fdb"
                  style={{
                    marginRight: "10px",
                    cursor: "pointer"
                  }}
                />
              </Link>
            }
            {permissions.includes('leave.approval') &&
              <FontAwesomeIcon
                icon={faEye}
                color="#321fdb"
                style={{
                  marginRight: "10px",
                  cursor: "pointer"
                }}
                onClick={() => showModel(rowData)}
              />
            }
          </span>;
        },
      },
    )
  }
  return formatedData;
};
