import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getSubjects = async () => {
  try {
    const response = await axios.get(ENDPOINTS.SUBJECT.GET);
    return response.data; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getSubjectById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.SUBJECT.GET_SUBJECT}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createSubject = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.SUBJECT.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateSubject= async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.SUBJECT.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteSubject = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.SUBJECT.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getClassWiseSubjects = async (class_id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.SUBJECT.GET_CLASS_WISE_SUBJECTS}/${class_id}`);
    return response;
  } catch (error) {
    throw error;
  }
}
