import React from "react";
import { Button } from "react-bootstrap";

const Card = (props) => {
  return (
    <div>
      <div className="">
        <div className="animated">
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <div className="main-title-page">{props.componentName}</div>
                <div className="">
                  <Button variant="secondary" className="mx-1" onClick={props.back}>
                    Back
                  </Button>
                  {props.freezed}
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-11">{props.componentName}</div>
                <div className="col-md-1">
                  <Button variant="secondary" onClick={props.back}>
                    Back
                  </Button>
                </div>
              </div> */}
            </div>
            <div className="card-body">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
