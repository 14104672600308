import React from 'react';
import { Form } from "react-bootstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './ckeditor-custom.css';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';

const WordOfTheDayForm = ({ changeHandler, handleEditorChange, submitHandler,
  quote, title, word_date, description }) => {

  return (
    <Form onSubmit={submitHandler}>
      <div className="row">
        <div className="col-md-12">
          <Input
            label="Title"
            type="text"
            name="title"
            value={title}
            placeholder={"Enter Title"}
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-12">
          <Input
            label="Quote"
            type="text"
            name="quote"
            value={quote}
            placeholder={"Enter Quote"}
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-12">
          <Input
            label="Word Date"
            type="date"
            name="word_date"
            value={word_date}
            placeholder={"Enter Word Date"}
            onChangeHandler={changeHandler}
            required={true}
          />
        </div>
        <div className="col-md-12 mt-2">
          <Form.Group className="mb-3"
            controlId="description">
            <Form.Label>Description</Form.Label>
            <CKEditor
              name="description"
              id={'description'}
              editor={ClassicEditor}
              config={{
                toolbar: [
                  'heading', '|',
                  'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
                  'undo', 'redo', '|',
                ]
              }}
              data={description}
              onChange={handleEditorChange}
            />
          </Form.Group>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="mt-3">
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default WordOfTheDayForm;
