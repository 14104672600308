import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';

const TraitForm = ({ onSubmit, onChange, onInputChange, onSwitchChange,
  name, programIds, programData, loader }) => {

  return (
    <Form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>
              Trait Name<i className="text-danger">*</i>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              id="name"
              onChange={onChange}
              placeholder="Enter Program name"
              value={name}
              required
            />
          </Form.Group>
        </div>
        <div className="row mx-1">
          <div className="col-md-12">
            <Form.Label>Available for classes</Form.Label>
            <Switch
              checked={programIds.length === programData.length}
              value="1"
              name="all"
              onChange={onSwitchChange}
              id="select-all"
              label="Select All"
            />
          </div>
          {programData && programData.map((program, idx) => (
            <div className="col-md-3" key={idx}>
              <Switch
                name="program_ids"
                id={program.id}
                checked={programIds.includes(program.id.toString())}
                value={program.id}
                onChange={onInputChange}
                label={program.name}
              />
            </div>
          ))}
          {loader ? (<Loader />) : null}
        </div>
        <div className="col-md-12 d-flex justify-content-end">
          <div className="mt-2">
            <Button variant="success" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default TraitForm;
