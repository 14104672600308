import React from 'react';
import { Form } from 'react-bootstrap';

const UserForm = ({ state, inputHandler, params }) => {
  return (
    <div className="row">
      <div className="col-md-4">
        <Form.Group>
          <Form.Label>
            Full name<i className="text-danger">*</i>
          </Form.Label>
          <Form.Control
            type="text"
            name="name"
            id="name"
            onChange={inputHandler}
            placeholder="Enter name"
            defaultValue={state.name}
            required
          />
        </Form.Group>
      </div>
      <div className="col-md-4">
        <Form.Group>
          <Form.Label>
            Email<i className="text-danger">*</i>
          </Form.Label>
          <Form.Control
            type="email"
            name="email"
            id="email"
            onChange={inputHandler}
            placeholder="Enter email"
            defaultValue={state.email}
            required
            autoComplete='email'
          />
        </Form.Group>
      </div>
      <div className="col-md-4">
        <Form.Group>
          <Form.Label>
            Phone<i className="text-danger">*</i>
          </Form.Label>
          <Form.Control
            type="number"
            name="phone"
            id="phone"
            onChange={inputHandler}
            placeholder="Enter phone"
            defaultValue={state.phone}
            required
          />
        </Form.Group>
      </div>
      <div className="col-md-4">
        <Form.Group>
          <Form.Label>
            Choose role<i className="text-danger">*</i>
          </Form.Label>
          <select
            onChange={(e) => inputHandler(e)}
            name="role_id"
            id="role_id"
            className="form-select form-control cursor-pointer"
            value={state.role_id}
            data-placeholder="Select Evaluation"
          >
            <option value="">Choose Role</option>
            {state.rolesData &&
              state.rolesData.map((role, idx) => (
                <option value={role.id} key={idx}>
                  {role.name}
                </option>
              ))}
          </select>
        </Form.Group>
      </div>
      <div className="col-md-4">
        {params.id ? null : (
          <Form.Group>
            <Form.Label>
              Password<i className="text-danger">*</i>
            </Form.Label>
            <Form.Control
              type="password"
              name="password"
              id="password"
              onChange={inputHandler}
              placeholder="Enter password"
              defaultValue={state.password}
              required
              autoComplete="current-password"
            />
          </Form.Group>
        )}
      </div>
    </div>
  );
};

export default UserForm;
