import {
  FETCH_PROGRAMS_FAILURE,
  FETCH_PROGRAMS_REQUEST,
  FETCH_PROGRAMS_SUCCESS
} from "./programTypes";
import axios from 'axios';

export const fetchProgramsRequest = () => {
  return {
    type: FETCH_PROGRAMS_REQUEST
  }
}
export const fetchProgramsSuccess = programs => {
  return {
    type: FETCH_PROGRAMS_SUCCESS,
    payload: programs
  }
}
export const fetchProgramsFailure = error => {
  return {
    type: FETCH_PROGRAMS_FAILURE,
    payload: error
  }
}
export const fetchPrograms = (page=1) => {
  // let page = selected + 1;
  return (dispatch) => {
    dispatch(fetchProgramsRequest)
    axios.get(`/programs?page=${page=1}`)
      .then(response => {
        const programs = response.data;
        dispatch(fetchProgramsSuccess(programs));
      })
      .catch(error => {
        const errorMsg = error.message;
        dispatch(fetchProgramsFailure(errorMsg))
      })
  }
}
