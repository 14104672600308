import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader/Loader';

const SessionForm = props => {
  return (
    <Form onSubmit={props.submitHandler}>
      <div>{!props.data.disableSubmit ? <Loader /> : null}</div>
      <div className="row">
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Name<i className="text-danger">*</i></Form.Label>
            <Form.Control type="text" name="name" id="name" onChange={props.changeHandler}
              placeholder="name" defaultValue={props.data.name} required />
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Start<i className="text-danger">*</i></Form.Label>
            <div className="input-group">
              <Form.Control name="start" id="start"
                className="form-control daterange"
                type="date" value={props.data.start}
                onChange={props.changeHandler} required />
            </div>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>End<i className="text-danger">*</i></Form.Label>
            <div className="input-group">
              <input name="end" id="end"
                className="form-control daterange"
                type="date"
                onChange={props.changeHandler}
                value={props.data.end}
                required />
            </div>
          </Form.Group>
        </div>
      </div>
      <div className="col-md-12 d-flex justify-content-end" style={{ marginLeft: 'inherit' }}>
        <div className=" mt-2">
          <Button variant="success"
            disabled={!props.data.disableSubmit}
            type="submit">Submit</Button>
        </div>
      </div>
    </Form>
  );
};

export default SessionForm;
