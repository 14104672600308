import React from 'react';
import { Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/Loader/Loader';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import Button from '../../components/Button/Button';

const ScoreSchemeForm = ({ handleSubmit, handleChangeOption, handleShow, clickOnDelete, setFormData,
  formData, addNewRow, loader, color_picker
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <Input
            label={'Name'}
            type={'text'}
            name={'name'}
            onChangeHandler={e => setFormData({ ...formData, name: e.target.value })}
            placeholder={'Name'}
            value={formData.name}
            required={true}
          />
        </div>
        <div className="col-md-6">
          <Select
            label="Type"
            name="type"
            placeHolder={"Select Evaluation Type"}
            value={formData.type}
            onChangeHandler={e => setFormData({ ...formData, type: e.target.value })}
            required={true}
            options={[
              { id: 'Letters', name: 'Letters' },
              { id: 'Percentage', name: 'Percentage' },
              { id: 'Text', name: 'Text' },
              { id: 'Score', name: 'Score' },
            ]}
          />
          {loader && (<Loader />)}
        </div>
        {formData.type !== 'Percentage' && formData.type !== 'Score' && (
          <div className="col-md-12">
            <hr />
            <div className="row">
              <div className="col-md-3">
                <Form.Label>Option Name</Form.Label>
              </div>
              <div className="col-md-4">
                <Form.Label>Color</Form.Label>
              </div>
              <div className="col-md-4">
                <Form.Label>Score</Form.Label>
              </div>
            </div>
            {formData.options.map((val, idx) => (
              <div className="row mt-2" key={idx} >
                <div className="col-md-3">
                  <Input
                    type={'text'}
                    name={'option_name'}
                    onChangeHandler={e => handleChangeOption(e, idx)}
                    placeholder={'Enter Name'}
                    value={val.option_name}
                    required={true}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    type={'text'}
                    name={'color'}
                    onChangeHandler={e => handleChangeOption(e, idx)}
                    placeholder={'Pick a color'}
                    value={val.color}
                    style={{ backgroundColor: val.color }}
                    required={true}
                  />
                </div>
                <div className="col-md-1">
                  <img
                    src={color_picker}
                    alt="colorPicker"
                    style={{ width: 30 }}
                    onClick={(e) => handleShow(e, idx)}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    type={'text'}
                    name={'score'}
                    onChangeHandler={e => handleChangeOption(e, idx)}
                    placeholder={'Enter Score'}
                    value={val.score}
                    required={true}
                  />
                </div>
                <div className="col-md-1">
                  {idx === 0 ? (
                    <button
                      onClick={addNewRow}
                      type="button"
                      className="btn btn-primary text-center"
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger"
                      onClick={() => clickOnDelete(val)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="row mt-2" style={{ float: 'right' }}>
        <div className="col-md-4">
          <Button
            variant="success"
            disabled={loader}
            type="submit">
            Submit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ScoreSchemeForm;
