import React, { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AttendenceForm from './AttendenceForm';
import {
  getAttendance, getAttendanceLabelList, getMappedPrograms,
  createAttendanceMarking, updateAttendanceMarking,
  bulkUploadAttendanceMarking
} from '../../../services/AppManagement/AttendanceService';
import { getPrograms } from '../../../services/ProgramServices';
import AttendanceBulkUpload from './AttendanceBulkUpload';
import * as XLSX from 'xlsx';
import AttendanceSample from './AttendanceSample';
import { getClassWiseStudentList } from '../../../services/StudentService';
import AttendanceSubmissionAlert from './AttendanceSubmissionAlert';
import Select from '../../../components/Select/Select';
import Button from '../../../components/Button/Button';

const AttendanceManage = () => {
  const [data, setData] = useState([]);
  const [program_id, setProgramId] = useState('');
  const [attendance_date, setAttendenceDate] = useState('');
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [attendenceLabelList, setAttendenceLabelList] = useState([]);
  const [hashMapLebelvsID, setHashMapLebelvsID] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [present, setPresent] = useState(0);
  const [absent, setAbsent] = useState(0);
  const [leave, setLeave] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(false);
  const session_id = localStorage.getItem('session_id');
  const [programData, setProgramData] = useState([]);
  const [isBulk, setIsBulk] = useState(false);
  const [file, setFile] = useState(null);
  const [programMap, setProgramMap] = useState({});
  const [programList, setProgramList] = useState([]);
  const [hashMapLabelShortName, setHashMapLabelShortName] = useState({});
  const [studentIdNumberVsIdHashMap, setStudentIdNumberVsIdHashMap] = useState({});
  const permission = JSON.parse(localStorage.getItem('permissions'));

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramId(value);
    }
    if (name === 'attendance_date') {
      setAttendenceDate(value);
    }
  }

  const attendanceMarkHandler = (e) => {
    const { value } = e.target;
    setIsLoader(true);
    const updatedData = data?.map(item => {
      if (item && item.att_label !== 3) {
        return {
          ...item,
          student_id: item.student.id,
          att_marked: hashMapLebelvsID[value],
          att_label: value,
          att_date: attendance_date,
          program_id: program_id,
          session_id: session_id,
          student: item.student
        };
      }
      return item;
    });

    setStudentAttendance(updatedData);
    setIsLoader(false);
  };

  const attendanceMarkHandlerSingle = (e, idx) => {
    setIsLoader(true);
    const { value } = e.target;
    const item = studentAttendance[idx];
    if (item.att_label == 3) {
      setIsLoader(false);
      return;
    }
    const newData = [...studentAttendance];

    let obj = {
      student_id: item.student.id,
      att_marked: hashMapLebelvsID[value],
      att_label: value,
      att_date: attendance_date,
      program_id: program_id,
      session_id: session_id,
      student: item.student
    };
    if (updateFlag) {
      obj.attendance_id = item.attendance_id;
    }
    //Create a new array to ensure immutability
    newData[idx] = obj;
    setStudentAttendance(newData);
    setIsLoader(false);
  };

  const fetchAttendanceLabelList = async () => {
    try {
      const response = await getAttendanceLabelList();
      if (response.status === 200) {
        let data = response.data.data;
        const labelList = data.map((item) => { return { id: item.id, name: item.label_name } });
        setAttendenceLabelList(labelList);
        let hashMapLebelvsID = {};
        let hashMapLabelShortName = {};
        data.forEach(element => {
          hashMapLebelvsID[element.id] = element.label_name;
          if (element.label_name === "Present") {
            hashMapLabelShortName['p'] = element.id;
          }
          else if (element.label_name === "Absent") {
            hashMapLabelShortName['a'] = element.id;
          }
          else if (element.label_name === "Leave") {
            hashMapLabelShortName['l'] = element.id;
          }
        });
        setHashMapLebelvsID(hashMapLebelvsID);
        setHashMapLabelShortName(hashMapLabelShortName);
      }
    } catch (error) {
      console.error("API Error:", error.response);
    }
  };

  const fetchAttendance = async () => {
    if (program_id === '') {
      enqueueSnackbar("Please select class", { variant: "error" });
      return;
    }
    try {
      setIsLoader(true);
      const response = await getAttendance(program_id, session_id, attendance_date);
      setIsLoader(false);
      if (response.status === 200) {
        let data = response.data.data;
        let updatedData = data && data.map(item => {
          let obj = {
            student_id: item.student.id,
            att_marked: item.att_marked,
            att_label: item.att_label_id,
            att_date: attendance_date,
            program_id: program_id,
            session_id: session_id,
            student: item.student
          };
          if (updateFlag) {
            obj.attendance_id = (item.label_name !== "Not Marked" && item.att_label_id !== 4) ? item.id : "";
          }
          return obj;
        });
        setData(updatedData);
        setStudentAttendance(updatedData);
      }
    }
    catch (error) {
      setIsLoader(false);
      console.error("API Error:", error);
    }
  };

  const fetchMappedPrograms = async (id) => {
    try {
      const response = await getMappedPrograms(id);
      if (response.status === 200) {
        let programs = response.data.data.attendances.map((item) => {
          return {
            id: item.program.id,
            name: item.program.name
          };
        });
        setProgramData(programs);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  };

  const fetchPrograms = async () => {
    try {
      const response = await getPrograms();
      if (response.success) {
        let programMap = {};
        response.data.forEach(item => {
          let name = item.name.toLowerCase().trim().split(' ').join('');
          programMap[name] = item.id;
        })
        setProgramList(response.data);
        setProgramMap(programMap);
      }
    } catch (error) {
      console.error("API Error:", error.response);
    }
  };

  useEffect(() => {
    let user_id = JSON.parse(localStorage.getItem('user')).id;
    const roles = JSON.parse(localStorage.getItem('roles'));
    const roleName = roles[0].name.toLowerCase().trim();
    let updateFlag = false;
    window.location.pathname.includes('update') ? updateFlag = true : updateFlag = false;
    setUpdateFlag(updateFlag);
    // Set the max attribute to today's date
    const dateField = document.getElementById('attendance_date');
    const today = new Date().toISOString().split('T')[0];
    if (dateField) {
      dateField.setAttribute('max', today);
      if (roleName !== 'super admin') {
        // dateField.setAttribute('min', today);
      }
      setAttendenceDate(today);
    }
    fetchAttendanceLabelList();
    fetchMappedPrograms(user_id);
    fetchPrograms();
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();
    const data = studentAttendance;
    let absent = data.filter(item => item.att_label == 2).length;
    let leave = data.filter(item => item.att_label == 3).length;
    let not_marked = data.filter(item => item.att_label == 4).length;
    let total = data.length - absent - leave;
    if (not_marked > 0) {
      enqueueSnackbar('Please mark all the attendance', { variant: 'error' });
      return;
    }
    setIsModalOpen(true);
    setPresent(total);
    setAbsent(absent);
    setLeave(leave);
  }

  const dataSubmit = async () => {
    const data = studentAttendance;
    setIsLoader(true);
    try {
      const response = updateFlag
        ? await updateAttendanceMarking({ data })
        : await createAttendanceMarking({ data });
      console.log('response create att', response);
      if (response.status === 200) {
        enqueueSnackbar(response.data.message.success, { variant: 'success' });
        navigate('/attendance/list');
      }
    }
    catch (error) {
      console.error("API Error:", error);
      return error;
    }
    finally {
      setIsLoader(false);
    }
  };

  const confirmHandler = () => {
    setIsModalOpen(false)
    dataSubmit();
  }

  const back = () => {
    navigate('/attendance/list');
  }

  const downloadExcel = (e) => {
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(AttendanceSample);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `attendance-bulk-upload.xlsx`);
  }

  const excelFileReader = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const result = {};
      workbook.SheetNames.forEach(sheet => {
        const json_data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        result[sheet] = json_data;
      });
      // Now 'result' contains JSON data for each sheet
      let final_data = result['Sheet1'];
      console.log('final_data', final_data);
      // Now 'result' contains JSON data for each sheet
      let result_data = [];
      final_data && final_data.map((item) => {
        let attendance_date, att_label;
        for (let key in item) {
          if (key !== 'id_number' && key !== 'name' && key !== 'class') {
            attendance_date = key;
            att_label = item[key].toLowerCase().trim();
            let attendance_label = att_label === 'p' ? 'Present' : att_label === 'a' ? 'Absent' : att_label === 'l' ? 'Leave' : att_label === 'na' ? 'Not Marked' : "";
            if (attendance_label) {
              if (studentIdNumberVsIdHashMap[item.id_number.trim()]) {
                let obj = {
                  student_id: studentIdNumberVsIdHashMap[item.id_number.trim()],
                  att_marked: attendance_label,
                  att_label: hashMapLabelShortName[att_label] ? hashMapLabelShortName[att_label] : 4,
                  att_date: attendance_date.trim().replace(/-/g, '/'),
                  program_id: program_id,
                  session_id: session_id,
                };
                result_data.push(obj);
              }
            }
          }
        }
      });
      console.log('result_data', result_data);
      setFile(result_data);
    };
    reader.readAsBinaryString(e.target.files[0]);
  };

  const attendanceBulkUpload = async () => {
    if (program_id === '') {
      enqueueSnackbar("Please select class", { variant: "error" });
      return;
    }
    if (file === null || file === undefined) {
      enqueueSnackbar("Please select file", { variant: "error" });
      return;
    }
    else {
      let data = file;
      try {
        setIsLoader(true);
        const response = await bulkUploadAttendanceMarking(data);
        if (response && response.status === 200) {
          setIsLoader(false);
          enqueueSnackbar(response.data.message.success, { variant: "success" });
        }
        else {
          setIsLoader(false);
          let errorMessage = response.response.data.message.errors.att_already_marked;
          setErrorMessage(errorMessage);
          if (response.data && response.data.message.errors) {
            let errors = response.data.message.errors;
            for (let key in errors) {
              enqueueSnackbar(errors[key], { variant: "error" });
            }
            enqueueSnackbar(response.data.message.errors.toString(), { variant: "error" });
          }
        }
      } catch (error) {
        console.error("API Error:", error);
        setIsLoader(false);
        return error;
      }
    }
  }

  const fetchStudentList = async (program_id) => {
    try {
      const response = await getClassWiseStudentList(session_id, program_id);
      if (response.status === 200) {
        let data = response.data.data;
        let studentIdNumberVsIdHashMap = {};
        data.forEach(item => {
          studentIdNumberVsIdHashMap[item.id_number] = item.id;
        });
        setStudentIdNumberVsIdHashMap(studentIdNumberVsIdHashMap);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  }

  const fetchStudent = (e) => {
    const { value } = e.target;
    setProgramId(value);
    fetchStudentList(value);
  }

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="main-title-page page-title d-flex align-items-center">
            <span>{updateFlag ? 'Update Attendance' : 'Mark Attendance'}</span>
          </div>
          <div className="add-btn">
            {permission.includes('attendance.marking.bulk.upload') && (
              <Button variant="primary" className='mx-2'
                onClick={() => setIsBulk(!isBulk)}>Bulk Upload</Button>
            )}
            <Button variant="secondary" onClick={back}>Back</Button>
          </div>
        </div>
      </div>
      <div className='card-body'>
        <AttendanceSubmissionAlert
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          studentAttendance={studentAttendance}
          present={present}
          absent={absent}
          leave={leave}
          confirmHandler={confirmHandler}
        />
        {isBulk ?
          <div>
            <div className="col-md-12 pb-mb-2">
              <Select
                name="program_id"
                placeHolder={'Select Class'}
                value={program_id}
                onChangeHandler={fetchStudent}
                options={programList}
              />
            </div>
            <AttendanceBulkUpload
              excelFileReader={excelFileReader}
              bulkUpload={attendanceBulkUpload}
              downloadExcel={downloadExcel}
              loader={isLoader}
              errorMessage={errorMessage}
            />
          </div>
          : <AttendenceForm
            submitHandler={submitHandler}
            data={data}
            studentAttendance={studentAttendance}
            setStudentAttendance={setStudentAttendance}
            attendance_date={attendance_date}
            setAttendenceDate={setAttendenceDate}
            errorMessage={errorMessage}
            program_id={program_id}
            changeHandler={changeHandler}
            attendanceMarkHandler={attendanceMarkHandler}
            attendanceMarkHandlerSingle={attendanceMarkHandlerSingle}
            isLoader={isLoader}
            fetchAttendanceLabelList={fetchAttendanceLabelList}
            fetchAttendance={fetchAttendance}
            hashMapLebelvsID={hashMapLebelvsID}
            programData={programData}
            attendenceLabelList={attendenceLabelList}
          />
        }
      </div>
    </div>
  );
};

export default AttendanceManage;
