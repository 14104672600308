import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { fetchSessions } from "../../redux/session/sessionActions";
import { FaFolder } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { termOptions } from "../../globalConstants";
import { enqueueSnackbar } from "notistack";
import { getClassWiseStudentList } from "../../services/StudentService";
import { publishResult, updatePublishResult, getStudentListPublishReportCardById }
  from "../../services/ReportCardService";
import PublishResultForm from "./PublishResultForm";

function PublishResultManage() {
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [term, setTerm] = useState("");
  const [start_date_time, setStartDateTime] = useState("");
  const [end_date_time, setEndDateTime] = useState("");
  const [class_id, setClassID] = useState("");
  const [student_idnumbers, setStudentIdNumbers] = useState([]);
  const session_id = JSON.parse(localStorage.getItem("session_id"));
  const { id } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);
  const sessionData = useSelector((state) => state.session.sessions.data);

  const getPublishStudentList = async (id) => {

    if (!id) return;
    try {
      setLoading(true);
      const response = await getStudentListPublishReportCardById(id, session_id);
      if (response.status === 200) {
        const publishData = response.data;
        setStudentIdNumbers(publishData.PublishStudentsResult.map(student => student.student_idnumber));
        getStudentList(publishData.class_id);
        setClassID(publishData.class_id);
        setTerm(publishData.term);
        setStartDateTime(publishData.PublishStudentsResult[0].start_date_time);
        setEndDateTime(publishData.PublishStudentsResult[0].end_date_time);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const getStudentList = async (program_id) => {
    setLoading(true);
    try {
      const response = await getClassWiseStudentList(session_id, program_id);
      if (response.status === 200) {
        const studentList = response.data.data;
        setStudentList(studentList);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPublishStudentList(id);
    dispatch(fetchPrograms());
    dispatch(fetchSessions());
  }, [fetchPrograms]);

  const changeHandler = async (e) => {
    const value = e.target.value;
    if (e.target.name === 'class_id') {
      setStudentIdNumbers([]);
      setClassID(value);
      getStudentList(value);
    }
    if (e.target.name === 'term') {
      setTerm(value);
    }
  }

  const handleInputChange = (event) => {
    const subjId = event.target.value;

    if (event.target.checked) {
      setStudentIdNumbers([...student_idnumbers, subjId]);
    } else {
      const updatedstudent_idnumbers = student_idnumbers.filter((id) => id !== subjId);
      setStudentIdNumbers(updatedstudent_idnumbers);
    }
  };

  const handleSwitchChange = (event) => {
    let updatedstudent_idnumbers;

    if (event.target.checked) {
      updatedstudent_idnumbers = studentList.map((student) => student.id_number.toString());
    } else {
      updatedstudent_idnumbers = [];
    }

    setStudentIdNumbers(updatedstudent_idnumbers);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const session_name = sessionData.find(session => session.id == session_id)?.name;
    const class_name = programData.find(program => program.id == class_id)?.name;

    const data = {
      class_id,
      class_name,
      session_id,
      session_name,
      term,
      student_idnumbers,
      start_date_time,
      end_date_time
    };
    try {
      if (id) {
        data.id = id;
        const response = await updatePublishResult(id, data);
        if (response.status === 200)
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
      } else {
        const response = await publishResult(data);
        if (response.status === 200) {
          enqueueSnackbar(response.data.message.success, { variant: 'success' });
        }
      }
      history('/publish/result/list');
    } catch (error) {
      console.error(error);
    }

  };


  return (
    <div className="program-list">
      <div className="animated">
        <div className="card">
          <div className="card-header">
            <div className="d-flex justify-content-between align-items-center">
              <div className="main-title-page page-title d-flex align-items-center">
                <div className="mx-2">
                  <FaFolder />
                </div>
                <span>Publish Report Card</span>
              </div>
            </div>
          </div>
          <PublishResultForm
            changeHandler={changeHandler}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            term={term}
            class_id={class_id}
            start_date_time={start_date_time}
            end_date_time={end_date_time}
            programData={programData}
            sessionData={sessionData}
            termOptions={termOptions}
            studentList={studentList}
            handleInputChange={handleInputChange}
            student_idnumbers={student_idnumbers}
            handleSwitchChange={handleSwitchChange}
            submitHandler={submitHandler}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
export default PublishResultManage;
