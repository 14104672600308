import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader/Loader';
import Switch from '../../components/Switch/Switch';
import Input from '../../components/Input/Input';

const EvaluationTypeForm = ({ handleChange, onInputChange, onSwitchChange, handleSubmit,
  name, isLoader, scoreSchemeList, score_scheme_ids }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12">
          <Input
            label="Evaluation Name"
            type="text"
            name="name"
            onChangeHandler={handleChange}
            placeholder="Enter Evaluation name"
            value={name}
            required={true}
          />
        </div>
          <div className="col-md-12 mt-3">
            <Form.Label>Select ScoreSchemes</Form.Label>
            <Switch
              label='Select All'
              value={'1'}
              checked={score_scheme_ids.length === scoreSchemeList.length}
              name='all'
              id='select-all'
              onChange={onSwitchChange}
            />
            <div className="row">
              <div className="">
                {scoreSchemeList &&
                  scoreSchemeList.map((scoreScheme, idx) => (
                    <div
                      className="form-check form-check-inline col-md-4"
                      key={idx}
                    >
                      <Switch
                        label={scoreScheme.name}
                        id={scoreScheme.id}
                        name="score_scheme_ids"
                        value={scoreScheme.id}
                        checked={score_scheme_ids.includes(scoreScheme.id.toString())}
                        onChange={onInputChange}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4 d-flex justify-content-end">
            <Button variant="success"
              disabled={isLoader}
              type="submit">
              Submit
            </Button>
          </div>
          <div>
            {isLoader && (
              <Loader />
            )}
          </div>
        </div>
    </Form>
  );
};

export default EvaluationTypeForm;
