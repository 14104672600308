import React, { useEffect, useState } from "react";
import DashboardGraph from "./DashboardGraph";
import { getStudentCount, getTeacherCount, getUserCount, getClassWiseStudentCount } from "../../services/DashboardService";
import DashboardCustomCard from "./DashboardCustomCard";

const Dashboard = () => {
  const [student_count, setStudentCount] = useState(0);
  const [teacher_count, setTeacherCount] = useState(0);
  const [user_count, setUserCount] = useState(0);
  const [class_wise_student_count, setClassWiseStudentCount] = useState([]);
  const session_id = localStorage.getItem("session_id");

  const fetchStudentCount = async () => {
    try {
      const response = await getStudentCount(session_id);
      if (response.status === 200) {
        const { student_count } = response.data.data;
        setStudentCount(student_count);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchTeacherCount = async () => {
    try {
      const response = await getTeacherCount();
      if (response.status === 200) {
        const { teacher_count } = response.data.data;
        setTeacherCount(teacher_count);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUserCount = async () => {
    try {
      const response = await getUserCount();
      if (response.status === 200) {
        const { user_count } = response.data.data;
        setUserCount(user_count);
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const fetchClassWiseStudentCount = async () => {
    try {
      const response = await getClassWiseStudentCount(session_id);
      if (response.status === 200) {
        const { data } = response.data;
        setClassWiseStudentCount(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchStudentCount();
    fetchTeacherCount();
    fetchUserCount();
    fetchClassWiseStudentCount();
  }, []);

  return (
    <div>
      <div className="row mb-4">
        <DashboardCustomCard
          title="User"
          cardColor="bg_secondary_gradient"
          iconColor="clr1"
          icon={"fa-solid fa-users"}
          count={user_count} />
        <DashboardCustomCard
          title="Teacher"
          cardColor="bg_primary_gradient"
          icon="fa-solid fa-user-tie"
          iconColor="clr2"
          count={teacher_count} />
        <DashboardCustomCard
          title="Student"
          cardColor="bg_success_gradient"
          iconColor="clr3"
          icon="fa-solid fa-user-graduate"
          count={student_count} />
      </div>
      <div className="card_graph card p-1">
        <DashboardGraph
          data={class_wise_student_count}
        />
      </div>
    </div>
  );
};
export default Dashboard;
