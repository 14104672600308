import {
  FETCH_TRAIT_FAILURE,
  FETCH_TRAIT_REQUEST,
  FETCH_TRAIT_SUCCESS
} from "./traitTypes";
import axios from 'axios';

export const fetchTraitRequest = () => {
  return {
    type: FETCH_TRAIT_REQUEST
  }
}
export const fetchTraitSuccess = trait => {
  return {
    type: FETCH_TRAIT_SUCCESS,
    payload: trait
  }
}
export const fetchTraitFailure = error => {
  return {
    type: FETCH_TRAIT_FAILURE,
    payload: error
  }
}
// Define your async action using async/await
export const fetchTraits = (page = 1) => {
  return async (dispatch) => {
    dispatch(fetchTraitRequest());
    try {
      const response = await axios.get(`/subject/traits?page=${page}`);
      const trait = response.data;
      dispatch(fetchTraitSuccess(trait));
    } catch (error) {
      const errorMsg = error.message;
      dispatch(fetchTraitFailure(errorMsg));
    }
  }
}
