import React from 'react';
import { Form, Button } from 'react-bootstrap';
import Switch from '../../components/Switch/Switch';
import Loader from '../../components/Loader/Loader';

const SubjectForm = ({ onSubmit, onChange, onInputChange, onSwitchChange,
  name, programData, programIds, loader }) => {
  return (
    <Form onSubmit={onSubmit}>
      {loader && <div className="loader"><Loader /></div>}
      <div className="row">
        <div className="col-md-12">
          <Form.Group>
            <Form.Label htmlFor="name">
              Name <i className="text-danger">*</i>
            </Form.Label>
            <Form.Control
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={onChange}
              placeholder="Enter subject name"
              required
            />
          </Form.Group>
        </div>

        <div className="col-md-12">
          <Form.Label>Available for classes</Form.Label>
          <Switch
            label='Select All'
            value={'1'}
            checked={programIds.length === programData.length}
            name='all'
            id='select-all'
            onChange={onSwitchChange}
          />
          <div className="row">
            <div className="col-md-12">
              {programData &&
                programData.map((program, idx) => (
                  <div
                    className="form-check form-check-inline col-md-2"
                    key={idx}
                  >
                    <Switch
                      label={program.name}
                      id={program.id}
                      name="programIds"
                      value={program.id}
                      checked={programIds.includes(program.id.toString())}
                      onChange={onInputChange}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="col-md-12 justify-content-end d-flex" style={{ marginLeft: "inherit" }}>
          <div className="mt-2">
            <Button
              variant="success"
              disabled={loader}
              type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default SubjectForm;
