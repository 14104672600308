import React from 'react';
import './Button.css';

const Button = ({
  type = 'button',
  onClick,
  disabled = false,
  className = '',
  children,
  variant = 'primary', // 'primary', 'secondary', 'danger', etc.
  size = 'medium', // 'small', 'medium', 'large'
}) => {
  const classNames = `btn btn-${variant} btn-${size} ${className}`;

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames}
    >
      {children}
    </button>
  );
};

export default Button;
