import React from "react";

// Helper function to generate headers based on date range
const generateDateHeaders = (attendanceData) => {
  const dates = new Set();
  attendanceData.forEach(student => {
    if (student.attendance) {
      Object.keys(student.attendance).forEach(date => dates.add(date));
    }
  });
  return Array.from(dates).sort(); // Sort dates for consistent column order
};

export const tableConstants = (attendanceData) => {
  let count = 1;
  const dateHeaders = generateDateHeaders(attendanceData);

  let formattedData = [
    {
      title: 'SNo',
      render: () => {
        return <span>{count++}</span>;
      },
    },
    {
      title: 'RollNo',
      render: rowData => {
        return <span>{rowData.student.roll_no}</span>;
      },
    },
    {
      title: 'IDNumber',
      render: rowData => {
        return <span>{rowData.student.id_number}</span>;
      },
    },
    {
      title: 'Name',
      render: rowData => {
        return <span>{rowData.student.first_name + " " + rowData.student.last_name}</span>;
      },
    },
    // Dynamically generate attendance columns for each date
    ...dateHeaders.map(date => ({
      title: date,
      render: rowData => {
        const attendance = rowData.attendance ? rowData.attendance[date] : null;
        let badgeClass = "";

        if (attendance) {
          switch (attendance.att_label_id) {
            case 1: badgeClass = "bg-success"; break;
            case 2: badgeClass = "bg-danger"; break;
            case 3: badgeClass = "bg-warning"; break;
            case 4: badgeClass = "bg-info"; break;
            case 5: badgeClass = "bg-secondary"; break;
            case 6: badgeClass = "bg-primary"; break;
            case 7: badgeClass = "bg-secondary"; break;
            case 8: badgeClass = "bg-secondary"; break;
            case 9: badgeClass = "bg-secondary"; break;
            default: badgeClass = "bg-light";
          }
        }

        return (
          <span className={`badge ${badgeClass}`}>
            {attendance && attendance.attendance_label ? attendance.attendance_label.label_name : 'N/A'}
          </span>
        );
      },
    })),
  ];

  return formattedData;
};
